import React, { useState } from "react";
import { Drawer, Flex } from "antd";
import IconsAll from "../../Components/IconsAll";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CreateNewFields from "../../Components/Create-new-fields/CreateNewFields";

const DrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: white;
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    .ant-collapse {
      display: grid;
      grid-template-columns: repete(4, 1fr);
      position: relative;

      .ant-collapse-item {
        .ant-collapse-content {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: auto;
          transform: translateY(100%);
        }
      }
    }
  }
`;

const Webhooks = () => {
  const { t } = useTranslation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <Flex className="w-full">
      <div className="px-4 xl:px-16 w-full">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle={t("webhooks")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.WebhooksIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart
            isFilterShow={true}
            addButton={true}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />
          <DrawerStyle
            width={870}
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            closeIcon={<IconsAll.CloseIconCustom />}
          >
            <CreateNewFields />
          </DrawerStyle>
        </div>

        <section className="mt-9"></section>
      </div>
    </Flex>
  );
};

export default Webhooks;
