import { Col, Form, Input, Tooltip } from "antd";
import React from "react";
import CustomInput from "./Components/CustomInput";
import CustomAutocomplete from "./Components/CustomAutocomplete";
import CustomTextArea from "./Components/CustomTextArea";
import CustomTags from "./Components/CustomTags";
import CustomToggle from "./Components/CustomToggle";
import CustomDatetime from "./Components/CustomDatetime";
import CustomDropdown from "./Components/CustomDropdown";
import CustomCheckbox from "./Components/CustomCheckbox";
import CustomDropdownMultiple from "./Components/CustomDropdownMultiple";
import CustomRadio from "./Components/CustomRadio";
import CustomReactQuill from "./Components/CustomReactQuill";
import CustomRelationalField from "./Components/CustomRelationalField";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { getTranslatedLabel } from "../../helpers/utils";
import ValidationError from "./ValidationError";

const FormGenerator = ({
  fieldName = "",
  fieldType = "",
  item = {},
  formData,
  updateFormData,
  width = 24,
  validationErrors,
}) => {
  switch (fieldType) {
    case "input":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <CustomInput
              item={item}
              formData={formData}
              updateFormData={updateFormData}
              isValidationError={validationErrors[item?.path]}
            />
          </Form.Item>
        </Col>
      );
    case "autocomplete":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <CustomAutocomplete
              item={item}
              formData={formData}
              updateFormData={updateFormData}
              isValidationError={validationErrors[item?.path]}
            />
          </Form.Item>
        </Col>
      );
    case "textarea":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <CustomTextArea
              item={item}
              formData={formData}
              updateFormData={updateFormData}
              isValidationError={validationErrors[item?.path]}
            />
          </Form.Item>
        </Col>
      );
    case "wysiwyg":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <CustomReactQuill
              item={item}
              formData={formData}
              updateFormData={updateFormData}
              isValidationError={validationErrors[item?.path]}
            />
          </Form.Item>
        </Col>
      );
    case "tags":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <CustomTags
              item={item}
              formData={formData}
              updateFormData={updateFormData}
              isValidationError={validationErrors[item?.path]}
            />
          </Form.Item>
        </Col>
      );
    case "toggle":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <CustomToggle
              item={item}
              formData={formData}
              updateFormData={updateFormData}
              isValidationError={validationErrors[item?.path]}
            />
          </Form.Item>
        </Col>
      );
    case "datetime":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <CustomDatetime
              item={item}
              formData={formData}
              updateFormData={updateFormData}
              isValidationError={validationErrors[item?.path]}
            />
          </Form.Item>
        </Col>
      );
    case "dropdown":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <CustomDropdown
              item={item}
              formData={formData}
              updateFormData={updateFormData}
              isValidationError={validationErrors[item?.path]}
            />
          </Form.Item>
        </Col>
      );
    case "icon-disabled":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <Input
              placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
              id={item._id}
              className={`min-h-14 rounded-xl placeholder:font-normal px-6 ${
                item?.meta?.validations?.validation_msg ||
                validationErrors[item?.path]
                  ? "border-[red]"
                  : ""
              }`}
            />
          </Form.Item>
        </Col>
      );
    case "checkboxes":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <CustomCheckbox
              item={item}
              formData={formData}
              updateFormData={updateFormData}
              isValidationError={validationErrors[item?.path]}
            />
          </Form.Item>
        </Col>
      );
    case "dropdown_multiple":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <CustomDropdownMultiple
              item={item}
              formData={formData}
              updateFormData={updateFormData}
              isValidationError={validationErrors[item?.path]}
            />
          </Form.Item>
        </Col>
      );
    case "radio":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <CustomRadio
              item={item}
              formData={formData}
              updateFormData={updateFormData}
              isValidationError={validationErrors[item?.path]}
            />
          </Form.Item>
        </Col>
      );
    case "file":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <div className="text-xs text-[red]">
                  {(validationErrors[item?.path] &&
                    (item?.meta?.validations?.validation_msg ||
                      validationErrors[item?.path]?.error_msg)) ||
                    ""}
                  {validationErrors[item?.path]?.context_error_msg}
                  <Tooltip title={"TEST"}>
                    <IoIosInformationCircleOutline />
                  </Tooltip>
                </div>
              )
            }
          >
            <Input
              placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
              id={item._id}
              className={`min-h-14 rounded-xl placeholder:font-normal px-6 ${
                item?.meta?.validations?.validation_msg ||
                validationErrors[item?.path]
                  ? "border-[red]"
                  : ""
              }`}
              disabled={!!item?.meta?.readonly}
            />
          </Form.Item>
        </Col>
      );
    case "image":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <Input
              placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
              id={item._id}
              className={`min-h-14 rounded-xl placeholder:font-normal px-6 ${
                item?.meta?.validations?.validation_msg ||
                validationErrors[item?.path]
                  ? "border-[red]"
                  : ""
              }`}
              disabled={!!item?.meta?.readonly}
            />
          </Form.Item>
        </Col>
      );
    case "files":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <Input
              placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
              id={item._id}
              className={`min-h-14 rounded-xl placeholder:font-normal px-6 ${
                item?.meta?.validations?.validation_msg ||
                validationErrors[item?.path]
                  ? "border-[red]"
                  : ""
              }`}
              disabled={!!item?.meta?.readonly}
            />
          </Form.Item>
        </Col>
      );
    case "list-m2m":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <CustomRelationalField
              item={item}
              formData={formData}
              updateFormData={updateFormData}
              isValidationError={validationErrors[item?.path]}
            />
          </Form.Item>
        </Col>
      );
    case "list-o2m":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <CustomRelationalField
              item={item}
              formData={formData}
              updateFormData={updateFormData}
              isValidationError={validationErrors[item?.path]}
            />
          </Form.Item>
        </Col>
      );
    case "list-m2o":
      return (
        <Col key={item._id} md={width}>
          <Form.Item
            label={fieldName}
            className="font-bold"
            name={item.meta.field}
            htmlFor={item._id}
            rules={[
              {
                required: !!item.meta?.required,
              },
            ]}
            help={
              validationErrors[item?.path] && (
                <ValidationError
                  error={{
                    ...validationErrors[item?.path],
                    custom_msg: item?.meta?.validations?.validation_msg,
                  }}
                />
              )
            }
          >
            <CustomRelationalField
              item={item}
              formData={formData}
              updateFormData={updateFormData}
              isValidationError={validationErrors[item?.path]}
            />
          </Form.Item>
        </Col>
      );
  }
};

export default FormGenerator;
