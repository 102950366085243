import React, { useEffect, useId, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Checkbox,
  Divider,
  Button,
  Flex,
  InputNumber,
  notification,
  TreeSelect,
} from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { themes } from "../../../config";
import { BsInfoCircle } from "react-icons/bs";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../helpers/axios";
import Apis from "../../../helpers/Apis";
import { store } from "../../../Redux/store";
import { convertToTreeData } from "../../../helpers/utils";

const FormStyling = styled(Form)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.colorGrayrBg};
  border-top: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  border-bottom: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  padding: 34px;
`;

const NotHasField = styled(Flex)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.NotHasFieldClr};
  border-left: 6px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.brandColor};
  padding-inline: 15px;
  border-radius: 6px;
  min-height: 56px;
`;

// const DrawerStyle = styled(Drawer)`
//   .ant-drawer-header {
//     padding: 0;
//     border: unset !important;
//     .ant-drawer-header-title {
//       .ant-drawer-close {
//         width: 56px;
//         height: 56px;
//         position: absolute;
//         left: -65px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         top: 30px;
//         background: white;
//         border-radius: 100vh;
//         box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
//         @media screen and (max-width: 991px) {
//           left: calc(50% - 28px);
//           bottom: 1rem;
//           top: auto;
//         }
//       }
//     }
//   }
//   .ant-drawer-body {
//     padding: 0;
//     .ant-tabs-nav {
//       padding-top: 40px;
//       width: min(100%, 200px);
//       min-height: 100vh;
//       background-color: #f5f6f7;
//       .ant-tabs-nav-wrap {
//         &:before,
//         &:after,
//         .ant-tabs-ink-bar {
//           display: none;
//         }
//         .ant-tabs-nav-list {
//           padding-inline: 10px;
//           .ant-tabs-tab {
//             border-radius: 8px;
//             margin-top: 5px;
//             > div {
//               color: #000 !important;
//               font-size: 16px;
//               font-style: normal;
//             }
//             &.ant-tabs-tab-active {
//               background-color: #ebeced;
//             }
//           }
//         }
//       }
//     }

//     .ant-tabs-content-holder {
//       padding-top: 40px;
//       .ant-tabs-tabpane {
//         padding-right: 24px;
//       }
//     }
//   }
// `;

const InvertedCheckBox = styled(Checkbox)`
  border-radius: 12px;
  border: 1px solid
    ${() =>
      themes[useSelector((state) => state.theme.name)].components.Input
        .colorBorder};
  background: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Input
      .colorBgContainer};
  padding: 1rem;
  width: 100%;
  font-weight: 400;
`;

export const hasNewFieldFunc = () => {
  return (
    <NotHasField className="items-center gap-3">
      <BsInfoCircle size={20} />
      <p className="font-normal">
        You can configure the display template after creating this field.
      </p>
    </NotHasField>
  );
};
export const hasFilter = () => {
  return (
    <NotHasField className="items-center gap-3">
      <BsInfoCircle size={20} />
      <p className="font-normal">
        The collection field option is misconfigured
      </p>
    </NotHasField>
  );
};

const ManyToMany = ({
  activeTab,
  addField,
  setIsDrawerOpen,
  dataSource,
  setActiveTab,
}) => {
  const { t } = useTranslation();
  const id = useId();
  const hasNewField = false;
  const [form] = Form.useForm();
  const params = useParams();
  const [collectionsList, setCollectionList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    getCollectionsList();
  }, []);

  const getCollectionsList = async () => {
    try {
      const resp1 = await axiosInstance.get(Apis.GET_ALL_SCHEMAS({}), {
        headers: {
          "x-tenant-id": store.getState().user.userId,
        },
      });
      if (resp1.status === 200) setCollectionList(resp1.data.payload.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSubmit = async () => {
    let isError;
    try {
      const values = await form.validateFields();
      isError = dataSource?.fields?.some((f) => f?.field === values.name);
      if (isError) {
        notification.error({ message: "Please enter unique key" });
        return;
      }

      let field = {
        schema_id: params.field,
        field: values.name,
        type: values.type,
        path: selectedGroup?.value
          ? selectedGroup?.value + "." + values?.name
          : values?.name,
        field_type: selectedGroup ? "Object" : "Array",
        ref: values?.foreign_key_table,
        meta: {
          field: values.name,
          interface: activeTab,
          width: "full",
          sort: dataSource?.fields?.length + 1,
          nullable: true,
          options: {
            related_collections: values.related_collections,
            per_page: values.per_page,
            allow_duplicates: values.allow_duplicates,
            item_link: values.item_link,
            layout: values.layout,
            creating_items: values.creating_items,
            selecting_items: values.selecting_items,
            junction_fields_location: values.junction_fields_location,
          },
          hidden: false,
          required: !!values.required,
        },
        schema_definition: {
          name: values.name,
          type: values.type,
          // default: values.default,
          foreign_key_table: values.foreign_key_table,
          foreign_key_table_id: collectionsList?.find(
            (c) => c?.collection_id === values.foreign_key_table,
          )?._id,
        },
      };
      addField(field);
      await form.resetFields();
      setActiveTab(null);
    } catch (error) {
      console.log("error", error);
      notification.error({ message: "Something went wrong" });
      isError = true;
    } finally {
      !isError && setIsDrawerOpen(false);
    }
  };

  return (
    <FormStyling
      layout="vertical"
      className="mt-12"
      form={form}
      initialValues={{
        type: "Alias",
        creating_items: true,
        selecting_items: true,
        junction_fields_location: "Bottom",
      }}
    >
      <Row gutter={28}>
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Key")}
            className="font-bold"
            htmlFor={`${id}-key`}
            name={`name`}
            rules={[
              {
                required: true,
                max: 50,
              },
            ]}
          >
            <Input
              placeholder={t("A unique common name")}
              onChange={(e) => {
                form.setFieldValue(
                  "name",
                  e.target.value
                    .replace(/[^a-zA-Z0-9_ ]/g, "")
                    .replace(/ /g, "_")
                    ?.toLowerCase(),
                );
              }}
              id={`${id}-key`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Group")}
            className="font-bold"
            htmlFor={`${id}-group`}
          >
            <TreeSelect
              id={`${id}-group`}
              showSearch
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="Select Group"
              allowClear
              treeDefaultExpandAll
              onSelect={(_, e) => setSelectedGroup(e)}
              treeData={convertToTreeData(
                dataSource?.fields?.filter((f) => {
                  return ["Object"]?.includes(f?.type);
                }),
                "tree-select",
              )}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("type")}
            htmlFor={`${id}-type`}
            className="font-bold"
            rules={[{ required: true }]}
            name={`type`}
          >
            <Select
              size="large"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-type`}
              disabled={true}
              options={[
                {
                  value: "Alias",
                  label: "Alias",
                },
              ]}
            />
          </Form.Item>
        </Col>

        {/* <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Default Value")}
            htmlFor={`${id}-null`}
            className="font-bold"
            name={`default`}
          >
            <Input
              placeholder={t("NULL")}
              id={`${id}-null`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
            />
          </Form.Item>
        </Col> */}

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Layout")}
            htmlFor={`${id}-layout`}
            className="font-bold"
            name={`layout`}
          >
            <Select
              showSearch
              size="large"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-layout`}
              options={[
                {
                  value: "List",
                  label: "List",
                },
                {
                  value: "Table",
                  label: "Table",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Required")}
            htmlFor={`${id}-requireValue`}
            className="font-bold"
            name={`required`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-requireValue`}>
              Require value to be set on creation
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={24}>
          <Form.Item
            label={t("Related Collection")}
            className="font-bold"
            htmlFor={`${id}-related-collection`}
            rules={[
              {
                required: true,
                message: "Please Enter Collection!",
              },
            ]}
            name={`foreign_key_table`}
          >
            <Select
              showSearch
              size="large"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-related-collection`}
              options={collectionsList
                ?.filter((c) => c?._id !== params.field)
                ?.map((c) => {
                  return { label: c?.collection_id, value: c?.collection_id };
                })}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24}>
          <Divider className="mt-3 mb-6" />
        </Col>

        <Col className="gutter-row mb-4" xs={24}>
          {hasNewField ? "Yes" : hasNewFieldFunc()}
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Creating Items")}
            htmlFor={`${id}-create-item`}
            className="font-bold"
            name={`creating_items`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-create-item`} checked>
              Enable Create Button
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Selecting Items ")}
            htmlFor={`${id}-select-value`}
            className="font-bold"
            name={`selecting_items`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-select-value`} checked>
              Enable Select Button
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Per Page")}
            htmlFor={`${id}-per-page`}
            className="font-bold"
            name={`per_page`}
          >
            <InputNumber
              placeholder={"Items Shown"}
              id={`${id}-per-page`}
              min={1}
              max={10}
              className="min-h-14 flex items-center rounded-xl placeholder:font-normal px-3 w-full leading-10 overflow-hidden"
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Junction Fields Location")}
            htmlFor={`${id}-field-location`}
            className="font-bold"
            name={`junction_fields_location`}
          >
            <Select
              showSearch
              size="large"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-field-location`}
              options={[
                {
                  value: "Bottom",
                  label: "Bottom",
                },
                {
                  value: "Top",
                  label: "Top",
                },
                {
                  value: "Right",
                  label: "Right",
                },
                {
                  value: "Left",
                  label: "Left",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Allow Duplicates")}
            htmlFor={`${id}-allow-duplicates`}
            className="font-bold"
            name={`allow_duplicates`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-allow-duplicates`}>
              Enabled
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24}>
          <Form.Item label={t("Filter")} className="font-bold">
            {hasNewField ? "Yes" : hasFilter()}
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Item link")}
            htmlFor={`${id}-item-link`}
            className="font-bold"
            name={`item_link`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-item-link`}>
              Show a item to the item
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24}>
          <Button
            onClick={handleSubmit}
            className="rounded-3xl bg-btn-dark text-white px-5 py-3 mt-4 font-medium h-auto w-full"
          >
            Save
          </Button>
          <Button type="link" className="mt-4 font-medium h-auto w-full">
            Continue in Advanced Field Creation Mode
          </Button>
        </Col>
      </Row>
    </FormStyling>
  );
};

export default ManyToMany;
