import React, { useContext, useEffect, useState } from "react";
import { Drawer, Flex, Table } from "antd";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import IconsAll from "../../Components/IconsAll";
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import AppInfo from "../../Components/AppInfo";
import { SidebarContext } from "../../Context/submenuContext";
import Backdrop from "../../Layouts/Backdrop";
import { CustomEmpty, tabletBreakpoint } from "../../helpers/utils";
import useScreenWidth from "../../Common/Hooks/ScreenWidth";
import { useTranslation } from "react-i18next";
import { themes } from "../../config";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import Apis from "../../helpers/Apis";
import axiosInstance from "../../helpers/axios";
import AddRole from "./AddRole";

const DrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: white;
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    padding: 0;
    .ant-tabs-nav {
      padding-top: 40px;
      width: min(100%, 200px);
      min-height: 100vh;
      background-color: #f5f6f7;
      .ant-tabs-nav-wrap {
        &:before,
        &:after,
        .ant-tabs-ink-bar {
          display: none;
        }
        .ant-tabs-nav-list {
          padding-inline: 10px;
          .ant-tabs-tab {
            border-radius: 8px;
            margin-top: 5px;
            > div {
              color: #000 !important;
              font-size: 16px;
              font-style: normal;
            }
            &.ant-tabs-tab-active {
              background-color: #ebeced;
            }
          }
        }
      }
    }

    .ant-tabs-content-holder {
      padding-top: 40px;
      .ant-tabs-tabpane {
        padding-right: 24px;
      }
    }
  }
`;

const TableStripe = styled(Table)`
  .ant-table{
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Table.tableBg};
}
.ant-table {
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Table.tableBg};
  table{
    background-color:transparent;
    .ant-table-row{
      .ant-table-cell{
        &:first-child{
          border-radius: 8px 0 0 8px;
          border-left: 1px solid ${() =>
            themes[useSelector((state) => state.theme.name)].components.Table
              .borderColor} !important;
        }
        &:last-child{
          border-radius: 0 8px 8px 0;
          border-right: 1px solid ${() =>
            themes[useSelector((state) => state.theme.name)].components.Table
              .borderColor} !important;
        }
      }
    }
  }
  .gray-clr {
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].token.grayClr};
  }
  
  .ant-table-cell,
  .ant-table-cell.ant-table-cell-fix-left{
    background-color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Table
        .sortedBg} !important;
      }
  }
  .ant-table-cell {
    border-bottom: 1px solid
        ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .borderColor} !important;
      border-top: 1px solid
        ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .borderColor} !important;
    &.ant-table-column-has-sorters {
      &:hover {
        background-color: ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .sortedBg} !important;
      }
    }
    &.ant-table-column-sort {
      background-color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Table
          .sortedBg};
    }
  }

  .imgLocationClr {
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Table
        .imgLocationClr};
    margin-left: 10px;
  }

}
`;

const Toegangscontrole = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const columns = [
    {
      title: "Role",
      dataIndex: "name",
      key: "name",
      render: (record, rowData) => (
        <Link
          to={`/instellingen/toegangscontrole/permissisons/${rowData?._id}`}
          onClick={() => {
            dispatch(
              setActiveTab({
                page: "instellingen",
                tab: t("access_control"),
                redirectionLink: "/instellingen/toegangscontrole",
              }),
            );
          }}
        >
          {record}
        </Link>
      ),
    },
    {
      title: "Tenant",
      dataIndex: "tenant",
      key: "tenant",
      render: (record) => {
        return <>{record?.name}</>;
      },
    },
    // {
    //   title: "",
    //   dataIndex: "department",
    //   key: "department",
    // },
  ];

  const submenu = useContext(SidebarContext);
  const screenWidth = useScreenWidth();
  const isTabletOrSmaller = screenWidth <= tabletBreakpoint;
  const isSubMenuActive = submenu?.activatedItem === "toegangscontrole";
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const resp = await axiosInstance.get(Apis.GET_ALL_ROLES());
      if (resp.status === 200) setRoles(resp.data.payload.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Flex className="w-full main-content-wrap">
      {isSubMenuActive && isTabletOrSmaller && (
        <Backdrop onClick={() => submenu.toggleActivation()} />
      )}
      <div className="px-4 xl:px-16 w-full left-part">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle={t("access_control")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.ToegangsControleIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart
            addButton={true}
            setIsDrawerOpen={setIsDrawerOpen}
          />
          <DrawerStyle
            width={870}
            open={isDrawerOpen}
            onClose={() => {
              setIsDrawerOpen(false);
            }}
            closeIcon={<IconsAll.CloseIconCustom />}
          >
            <AddRole setIsDrawerOpen={setIsDrawerOpen} init={init} />
          </DrawerStyle>
        </div>

        <section className="mt-9">
          <TableStripe
            columns={columns}
            dataSource={roles}
            pagination={false}
            rowKey={(record) => record?._id}
            scroll={{
              x: 300,
            }}
            locale={{ emptyText: <CustomEmpty /> }}
          />
        </section>
      </div>
      <AppInfo />
    </Flex>
  );
};

export default Toegangscontrole;
