// import { retrieveLoginPrompt } from "@/utils/utils";
import axios from "axios";
import { retrieveLoginPrompt } from "./utils";

let baseUrl = process.env.REACT_APP_SERVER_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

if (localStorage.getItem("token")) {
  axiosInstance.defaults.headers.common = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
}

axiosInstance.interceptors.request.use(
  (config) => {
    try {
      new URL(baseUrl + config.url);
    } catch (e) {
      config.url = baseUrl + config.url;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest._queued = true;
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorage.getItem("refreshToken");

      return new Promise((resolve, reject) => {
        fetch(baseUrl + "api/v1/auth/refresh-token", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refreshToken: refreshToken,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(
                "Network response was not ok " + response.statusText,
              );
            }
            return response.json();
          })
          .then((data) => {
            const responseData = data?.payload?.data;
            localStorage.setItem("token", responseData?.access_token);
            localStorage.setItem("refreshToken", responseData?.refresh_token);
            axiosInstance.defaults.headers.common["Authorization"] =
              "Bearer " + responseData?.access_token;
            originalRequest.headers["Authorization"] =
              "Bearer " + responseData?.access_token;
            processQueue(null, responseData?.access_token);
            resolve(axiosInstance(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            localStorage.clear();
            retrieveLoginPrompt().then((response) => {
              window.open(response?.data, "_self");
            });
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return error.response;
  },
);

// let isInterceptorAdded = false;

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response?.status === 401) {
//       if (!isInterceptorAdded) {
//         isInterceptorAdded = true;
//         retrieveLoginPrompt().then((response) => {
//           // console.log(response);
//           window.open(response?.data, "_self");
//         });
//         // retrieveLoginPrompt().then((data) => window.open(data, "_self"));
//       }
//     }
//     return Promise.reject(error);
//   },
// );

export default axiosInstance;
