import React, { useState } from "react";
import withRouter from "../Common/withRouter";
import { ConfigProvider, Layout } from "antd";
import SidebarLayout from "./SidebarLayout";
import { ThemeProvider, styled } from "styled-components";
import { useTheme } from "../Common/ThemeContext";
import { SidebarContext } from "../Context/submenuContext";
import { useSelector } from "react-redux";

import { themes } from "../config";

const { Content } = Layout;

// Define the StyleLayout styled component outside the functional component
const StyleLayout = styled(Layout)`
  margin-left: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Menu
      .verticalSidebarWidth}px;
  position: relative;
  // padding: calc(${() =>
    themes[useSelector((state) => state.theme.name)].token
      .controlHeight}px * 2) 24px 0;
  padding: 0 24px 0;

  .ant-breadcrumb {
    ol {
      justify-content: end;
    }
  }

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

const LayoutComponents = ({ children }) => {
  const { theme } = useTheme();
  const currentTheme = useSelector((state) => state.theme);
  const [activatedItem, setActivatedItem] = useState(() => {
    const removedSlash = location.pathname.replace("/", "");
    const currentPath = removedSlash.split("/")?.[0];
    return currentPath || "dashboard";
  });

  const toggleActivation = (key) => {
    setActivatedItem(key);
  };

  return (
    <ThemeProvider theme={currentTheme}>
      <ConfigProvider theme={currentTheme}>
        <SidebarLayout theme={theme} />
        <Layout style={{ minHeight: "100vh" }}>
          <StyleLayout
            id="antLayoutContent"
            style={{
              marginLeft: "64px",
              padding: 0,
              background:
                theme === "."
                  ? themes[useSelector((state) => state.theme.name)].token
                      .themeLayoutBg
                  : themes[useSelector((state) => state.theme.name)].token
                      .themeLayoutBg,
            }}
          >
            <SidebarContext.Provider
              value={{ activatedItem, toggleActivation }}
            >
              <Content>{children}</Content>
            </SidebarContext.Provider>
          </StyleLayout>
        </Layout>
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default withRouter(LayoutComponents);
