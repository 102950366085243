import React from "react";
import { Navigate } from "react-router-dom";
import axiosInstance from "../helpers/axios";
import { retrieveLoginPrompt } from "../helpers/utils";
import { useDispatch } from "react-redux";
import { setUser } from "../Redux/slices/userDetails.reducer";
// import { Navigate } from "react-router-dom";
// import { useProfile } from "../Common/Hooks/UserHooks";

const AuthProtected = (props) => {
  //   const { userProfile, loading, token } = useProfile();
  const params = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();

  const queryToken = params.get("at");
  if (queryToken) {
    localStorage.setItem("token", queryToken);
    axiosInstance.defaults.headers.common = {
      Authorization: "Bearer " + queryToken,
    };
  }
  const refreshToken = params.get("rt");
  if (refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);
  }
  const tenantId = params.get("tenantId");
  if (tenantId) {
    // localStorage.setItem("tenantId", tenantId);
    dispatch(setUser({ userId: tenantId }));
  }

  if (!localStorage.getItem("token")) {
    retrieveLoginPrompt().then((response) => {
      window.open(response?.data, "_self");
    });
    return;
  }

  if (window.location.pathname === "/") {
    return <Navigate to="/dashboard" />;
  }

  return <>{props.children}</>;
};

export default AuthProtected;
