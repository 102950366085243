import React, { useEffect, useState } from "react";
import { Checkbox, Col, Modal, Radio, Row, Table } from "antd";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import IconsAll from "../../Components/IconsAll";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import axiosInstance from "../../helpers/axios";
import Apis from "../../helpers/Apis";
import { store } from "../../Redux/store";
import withRouter from "../../Common/withRouter";
import { BsCheckLg } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import styled from "styled-components";
import { themes } from "../../config";
import useDebouncedEffect from "../../helpers/useDebounceEffect";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { CustomEmpty } from "../../helpers/utils";
import SelectTableColumns from "../../Components/SelectTableColumns";
import { flatten } from "flat";
dayjs.extend(utc);

const TableStriped = styled(Table)`
.ant-table {
background-color: ${() =>
  themes[useSelector((state) => state.theme.name)].components.Table.tableBg};
&.table{
  background-color:transparent;
}
.gray-clr {
  color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.grayClr};
}

thead .ant-table-cell,
.ant-table-cell.ant-table-cell-fix-left{
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Table
      .sortedBg} !important;
    }
}
.ant-table-cell {
  border-bottom: 1px solid
      ${() =>
        themes[useSelector((state) => state.theme.name)].components.Table
          .borderColor} !important;
  &.ant-table-column-has-sorters {
    &:hover {
      background-color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Table
          .sortedBg} !important;
    }
  }
  &.ant-table-column-sort {
    background-color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Table
        .sortedBg};
  }
}

.imgLocationClr {
  color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Table
      .imgLocationClr};
  margin-left: 10px;
}

}
  thead>tr:first-child >*:last-child {
    padding-right: 60px;
  }
    
`;

const ContentTable = ({
  router,
  item,
  tableType,
  tableId,
  setShowDrawer,
  setRelationalData,
  selectedRows,
  setSelectedRows,
}) => {
  const { t } = useTranslation();
  const path = location.pathname.split("/");
  const activeTab = useSelector((state) => state.activeTab[path[1]]);
  const dispatch = useDispatch();
  const [contentData, setContentData] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [collectionData, setCollectionData] = useState(null);
  const [presets, setPresets] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectOpen, setSelectOpen] = useState(false);
  const [columnWidths, setColumnWidths] = useState({});
  const currentLng = useSelector((state) => state?.user?.userProfile?.language);

  useEffect(() => {
    setSelectedColumns(presets?.layout_query?.tabular?.fields);
  }, [presets]);

  useDebouncedEffect(
    () => {
      init(!!path[2]);
    },
    [searchTerm, path[2]],
    1000,
  );

  useEffect(() => {
    setSearchTerm("");
  }, [activeTab]);

  const handleResizeStart = (key) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const startX = e.clientX;
    const startWidth = columnWidths[key] || 150;

    const handleMouseMove = (moveEvent) => {
      setColumnWidths((prev) => ({
        ...prev,
        [key]: Math.max(startWidth + (moveEvent.clientX - startX), 50),
      }));
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleDeleteClick = async () => {
    setOpen(false);
    try {
      if (path[2]) {
        const resp = await axiosInstance.put(
          Apis.DELETE_CONTENT(path[2]),
          {
            deleteContent: selectedRows,
          },
          {
            headers: {
              "x-tenant-id": store.getState().user.userId,
            },
          },
        );
        if (resp.status === 200) {
          setSelectedRows([]);
          init(true);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const init = async (getCollectionData = false) => {
    if (path[2] || tableId) {
      try {
        if (getCollectionData) {
          const resp1 = await axiosInstance.get(
            Apis.GET_A_SCHEMA(tableId || path[2]),
            {
              headers: {
                "x-tenant-id": store.getState().user.userId,
              },
            },
          );
          if (resp1.status === 200) setCollectionData(resp1.data.payload.data);
          const resp3 = await axiosInstance.get(Apis.GET_PRESET(path[2]), {
            headers: {
              "x-tenant-id": store.getState().user.userId,
            },
          });
          if (resp3.status === 200) {
            setPresets(
              resp3.data.payload.data ? resp3.data.payload.data[0] : [],
            );
          }
        }

        const resp2 = await axiosInstance.post(
          Apis.GET_CONTENT(tableId || path[2], searchTerm),
          {
            columns: columns?.map((c) => c?.key),
          },
          {
            headers: {
              "x-tenant-id": store.getState().user.userId,
            },
          },
        );
        if (resp2.status === 200) {
          setContentData(resp2.data.payload.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const generateColumns = () => {
    if (!collectionData?.fields?.length) return [];

    let cols = collectionData.fields.map((f) => {
      return {
        title: (
          <div
            className="resizable-header"
            style={{ width: columnWidths[f.path] }}
          >
            {f?.meta?.translations?.find((t) => t?.language === currentLng)
              ?.translation || f.field}
            <div
              role="draggable"
              className="resize-handle"
              onMouseDown={handleResizeStart(f.path)}
              /* onDoubleClick={() => {
                  setColumnWidths((prev) => ({
                    ...prev,
                    [f.path]: "auto",
                  }));
                }} */
            ></div>
          </div>
        ),
        dataIndex: f.path,
        key: f.path,
        width: columnWidths[f.path],
        /* onHeaderCell: (column) => ({
            width: column.width,
            onResize: handleResize(index),
          }), */
        render: (record, rowData) => {
          let content;
          if (
            f?.meta?.interface === "dropdown_multiple" ||
            f?.meta?.interface === "dropdown"
          ) {
            const choices = f?.meta?.options?.choices || [];
            content = Array.isArray(record)
              ? record
                  .map((item) => choices?.find((c) => c?.value === item)?.label)
                  .join(",")
              : choices?.find((c) => c?.value === record)?.label;
          } else if (typeof record === "boolean") {
            content = record ? (
              <BsCheckLg size={24} />
            ) : (
              <AiOutlineClose size={24} />
            );
          } else if (f?.meta?.interface === "datetime") {
            content = record ? dayjs.utc(record).format() : "";
          } else if (f?.meta?.interface === "list-m2o") {
            console.log("path", rowData[f.path + "._id"]);
            content = rowData[f.path + "._id"];
          } else if (
            f?.meta?.interface === "list-o2m" ||
            f?.meta?.interface === "list-m2m"
          ) {
            content = record?.map((item) => item?._id).join(",");
          } else {
            content = <div dangerouslySetInnerHTML={{ __html: record }} />;
          }

          return (
            <div
              onClick={() => {
                if (tableType === "content") {
                  router.navigate(
                    `${activeTab?.redirectionLink}/edit/${rowData?._id}`,
                  );
                } else {
                  handleRowSelect(
                    !selectedRows?.includes(rowData?._id),
                    rowData,
                  );
                }
              }}
            >
              {content}
            </div>
          );
        },
      };
    });

    if (tableType === "content") {
      cols = cols.filter((col) =>
        presets?.layout_query?.tabular?.fields?.includes(col.key),
      );
    }

    cols.unshift({
      title: (
        <div
          className="resizable-header"
          style={{ width: columnWidths["select"] }}
        >
          Select
          <div
            className="resize-handle"
            onMouseDown={handleResizeStart("select")}
            /* onDoubleClick={() => {
              setColumnWidths((prev) => ({
                ...prev,
                select: "auto",
              }));
            }} */
          ></div>
        </div>
      ),
      dataIndex: "select",
      key: "select",
      width: columnWidths["select"],
      render: (text, record) =>
        item?.meta?.interface === "list-m2o" ? (
          <Radio
            onChange={(e) => handleRowSelect(e.target.checked, record)}
            checked={selectedRows?.includes(record?._id)}
          />
        ) : (
          <Checkbox
            onChange={(e) => handleRowSelect(e.target.checked, record)}
            checked={selectedRows?.includes(record?._id)}
          />
        ),
    });
    return cols;
  };
  const columns = generateColumns(collectionData?.fields);

  const handleRowSelect = (checked, record) => {
    if (item?.meta?.interface === "list-m2o") {
      setSelectedRows([record._id]);
    } else {
      setSelectedRows((prevSelectedRows) => {
        if (checked) {
          return [...prevSelectedRows, record._id];
        } else {
          return prevSelectedRows.filter((id) => id !== record._id);
        }
      });
    }
  };

  const handleDataAdd = async () => {
    setRelationalData(selectedRows);
    setSelectedRows([]);
    setShowDrawer(false);
  };

  const handleColumnsAdd = async () => {
    try {
      const resp = await axiosInstance.put(
        Apis.UPDATE_PRESET(path[2]),
        {
          fields: selectedColumns,
          collection_id: collectionData?.collection_id,
        },
        {
          headers: {
            "x-tenant-id": store.getState().user.userId,
          },
        },
      );
      if (resp.status === 200) {
        setSelectedColumns([]);
        init(true);
      }
    } catch (error) {
      console.log("error", error);
    }
    setSelectOpen(false);
  };

  return (
    <Row className="main-content-wrap">
      <Col className="px-8 xl:px-16 left-part" span={24}>
        {tableType === "content" ? (
          <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11 | drawer-header-style">
            <LeftSideTitlePart
              pageTitle={collectionData?.collection_id || ""}
              pageSubTitle={t("content")}
              pageIcon={true}
              pageInnerIcon={<IconsAll.PaginasIcon />}
              backButton={false}
            />
            <RightSIdeTitlePart
              isFilterShow={true}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              addButton={true}
              addDisabled={!path[2]}
              deleteButton
              deleteDisabled={!selectedRows?.length}
              handleDeleteClick={() => setOpen(true)}
              handleAddClick={() => {
                router.navigate(`/content/${path[2]}/add`);
                dispatch(
                  setActiveTab({
                    page: "content",
                    tab: path[2],
                    redirectionLink: `/content/${path[2]}/add`,
                  }),
                );
              }}
            />
          </div>
        ) : (
          <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11 | drawer-header-style">
            <LeftSideTitlePart
              pageTitle={collectionData?.collection_id || ""}
              pageSubTitle={t("content")}
              pageIcon={true}
              pageInnerIcon={<IconsAll.PaginasIcon />}
              backButton={false}
            />
            <RightSIdeTitlePart
              isFilterShow={true}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              showButton={true}
              showDisabled={!selectedRows?.length}
              handleShowClick={handleDataAdd}
            />
          </div>
        )}
        <section className="mt-9">
          <div className="relative">
            <TableStriped
              columns={columns}
              dataSource={contentData?.map((c) => flatten(c, { safe: true }))}
              rowKey={(record) => record?._id}
              locale={{ emptyText: <CustomEmpty /> }}
            />
            {tableType === "content" && (
              <SelectTableColumns
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                columnOptions={collectionData?.fields}
                handleColumnsAdd={handleColumnsAdd}
                selectOpen={selectOpen}
                setSelectOpen={setSelectOpen}
              />
            )}
          </div>
        </section>
      </Col>
      <Modal
        open={open}
        title="Delete Content"
        okText="Delete Content"
        onOk={handleDeleteClick}
        onCancel={() => setOpen(false)}
        className="modal-delete"
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <p className="my-6">
          Are you sure you want to delete selected content permanently?
        </p>
      </Modal>
    </Row>
  );
};

export default withRouter(ContentTable);
