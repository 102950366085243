import React from "react";
import FormGenerator from "./FormGenerator";
import { capitalizeAndFormat } from "../../helpers/utils";
import { useSelector } from "react-redux";

const DynamicForm = ({
  form = [],
  setFormData,
  formData,
  validationErrors,
}) => {
  const currentLng = useSelector((state) => state?.user?.userProfile?.language);
  const updateFormData = ({ key, value }) => {
    setFormData((prev) => {
      return { ...prev, [key]: value };
    });
  };

  return (
    <>
      {form.map((item, i) => {
        return (
          <FormGenerator
            key={i}
            fieldName={
              item?.meta?.translations?.find((t) => t?.language === currentLng)
                ?.translation || capitalizeAndFormat(item?.field)
            }
            fieldType={item.meta.interface}
            item={item}
            setFormData={setFormData}
            formData={formData}
            updateFormData={updateFormData}
            width={item.meta.width === "half" ? 12 : 24}
            validationErrors={validationErrors}
          />
        );
      })}
    </>
  );
};

export default DynamicForm;
