import React, { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../../../Common/Breadcrumb";
import { Card, Divider, Dropdown, Space, Table, Tooltip } from "antd";
import Apis from "../../../helpers/Apis";
import axios from "axios";
import {
  useBeforeUnload,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  simpleSchemaToItemSchema,
  toDotNotation,
  updateItemSchemaData,
} from "../../../helpers/utils";
import styles from "../index.module.css";
import { BsPlusLg, BsSortDown, BsSortDownAlt } from "react-icons/bs";
import { MdOutlineHorizontalRule } from "react-icons/md";
import { useUnmount } from "ahooks";
import withRouter from "../../../Common/withRouter";

const CollectionsList = (props) => {
  const { router } = props;
  const { code, page = 1 } = useParams();
  document.title = `Collection | ${code.toLocaleUpperCase().replaceAll("_", " ")} | ${process.env.REACT_APP_PAGE_TITLE}`;
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ schema: [], ref_fields: [] });
  const [collections, setCollections] = useState([]);
  const [columns, setColumns] = useState([]);
  const [pagination, setPagination] = useState({});
  const [listingFields, setListingFields] = useState([]);
  const [listingIsUpdated, setListingIsUpdated] = useState(false);

  const getColumnSearchProps = ({ key, listingFields }) => ({
    filterDropdown: () => (
      <div className={styles.filer_dropdown}>
        <Space direction="vertical">
          <span
            onClick={() => {
              getCollections({ sortBy: key, descending: false });
            }}
            className={styles.align_center}
          >
            <BsSortDownAlt size={22} /> Sort Ascending
          </span>
          <span
            onClick={() => {
              getCollections({ sortBy: key, descending: true });
            }}
            className={styles.align_center}
          >
            <BsSortDown size={22} /> Sort Descending
          </span>
        </Space>
        <Divider className={styles.divider} />
        <span
          onClick={() => handleFieldHide(key, listingFields)}
          className={styles.align_center}
        >
          <MdOutlineHorizontalRule size={22} /> Hide Field
        </span>
      </div>
    ),
  });

  const getCollections = async ({
    modelCode = code,
    page = 1,
    limit = searchParams.get("limit") || 10,
    sortBy = searchParams.get("sortBy") || "createdAt",
    descending = searchParams.get("descending") || true,
  }) => {
    setLoading(true);
    const body = {
      page,
      limit,
      sortBy,
      descending,
    };
    setSearchParams(`limit=${limit}&sortBy=${sortBy}&descending=${descending}`);
    try {
      let response = await axios.post(
        Apis.GET_COLLECTION_RECORD_BY_CODE({ code: modelCode }),
        body,
      );
      response = response.data.payload;
      setCollections(response.data);
      setPagination(response.meta);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getProjectionSchema = async (modelCode) => {
    try {
      let response = await axios.get(
        Apis.GET_PROJECTION_SCHEMA_BY_CODE({ schema: modelCode }),
      );
      response = response.data.payload.data;

      setData({
        schema: toDotNotation({ ...response.schema }),
        ref_fields: response.ref_fields,
      });

      const listing = toDotNotation(response?.listing_projection);

      const getFieldSchema = simpleSchemaToItemSchema(
        response?.schema,
        "",
        ".",
        response?.listing_projection,
      );

      const tempColumns = [];
      for (const key in listing) {
        if (Object.hasOwnProperty.call(listing, key)) {
          const splitTitle = key.split(".");
          const titleName = splitTitle[splitTitle?.length - 1];

          tempColumns.push({
            title: titleName,
            dataIndex: splitTitle,
            width: "10%",
            key: key,
            ...getColumnSearchProps({ key, listingFields: getFieldSchema }),
          });
        }
      }

      setListingFields(getFieldSchema);
      setColumns(tempColumns);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePagination = (currentPage, pageSize) => {
    if (currentPage != page) {
      router.navigate(`/collection/${code}/${currentPage}${location.search}`);
    } else {
      getCollections({ modelCode: code, page, limit: pageSize });
    }
  };

  const updateListingProjectionSchema = async ({ code, columns }) => {
    const formData = new FormData();
    columns.map((c) => {
      const joinKey = c.dataIndex.join(".");
      formData.append(`listing_projection.${joinKey}`, data.schema[joinKey]);
      return c;
    });

    try {
      await axios.patch(
        Apis.UPDATE_LISTING_PROJECTION_SCHEMA({ code }),
        formData,
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleFieldAdd = (fieldData) => {
    const array = updateItemSchemaData(listingFields, fieldData.key, {
      disabled: true,
    });
    const splitTitle = fieldData.key.split(".");
    const titleName = splitTitle[splitTitle?.length - 1];
    const tempColumns = [...columns];
    tempColumns.push({
      title: titleName,
      dataIndex: splitTitle,
      width: "10%",
      key: fieldData.key,
      ...getColumnSearchProps({ key: fieldData.key, listingFields }),
    });
    setColumns(tempColumns);
    setListingFields(array);

    const isRefField = data?.ref_fields?.find((rf) =>
      fieldData?.key?.includes(rf?.path),
    );
    if (isRefField) {
      updateListingProjectionSchema({ code: code, columns: tempColumns }).then(
        () => {
          getCollections({});
        },
      );
    } else {
      setListingIsUpdated(true);
    }
  };

  const handleFieldHide = (key, listingFields) => {
    setColumns((prevColumns) => {
      const updatedColumns = prevColumns.filter((column) => column.key !== key);
      return updatedColumns;
    });
    const array = updateItemSchemaData(listingFields, key, { disabled: false });
    setListingFields(array);
    setListingIsUpdated(true);
  };

  useEffect(() => {
    getCollections({ modelCode: code, page });
  }, [page]);

  useEffect(() => {
    getProjectionSchema(code);
  }, [code]);

  useBeforeUnload(
    useCallback(() => {
      listingIsUpdated &&
        updateListingProjectionSchema({ code: code, columns: columns });
    }, [code, columns, listingIsUpdated]),
  );

  useUnmount(() => {
    listingIsUpdated &&
      updateListingProjectionSchema({ code: code, columns: columns });
  });

  return (
    <>
      <Breadcrumb
        mainTitle="NOX"
        pageTitle={code.toLocaleUpperCase().replaceAll("_", " ")}
        items={[
          {
            route: "/collections",
            displayName: "Collections",
          },
          {
            route: "#",
            displayName: "Collection Record",
          },
        ]}
      />
      <Card>
        <div className={styles.filters_box}>
          <div className={styles.add_projection_field_box}>
            <Dropdown
              menu={{
                selectable: true,
                items: listingFields,
                onSelect: (data) => handleFieldAdd(data),
                className: styles.dropdown_menu,
                selectedKeys: [],
              }}
              trigger={["click"]}
            >
              <Tooltip title="Add Field">
                <span onClick={(e) => e.preventDefault()}>
                  <BsPlusLg size={22} />
                </span>
              </Tooltip>
            </Dropdown>
          </div>
        </div>
        <Table
          className={styles.data_listing_table}
          loading={loading}
          columns={columns}
          dataSource={collections}
          pagination={{
            defaultCurrent: 1,
            current: pagination.current_page,
            onChange: handlePagination,
            total: pagination.total_found,
            className: styles.custom_pagination,
          }}
        />
      </Card>
    </>
  );
};

export default withRouter(CollectionsList);
