import React, { useContext } from "react";
import {
  Flex,
  Card,
  Form,
  Row,
  Col,
  Select,
  Input,
  Upload,
  Button,
  Divider,
  Checkbox,
} from "antd";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import styled from "styled-components";
import personImg from "../../Assets/images/users/avatar-4.jpg";
import profileLink from "../../Assets/images/Icon/@-icon.svg";
import srcLocation from "../../Assets/images/Icon/Location.svg";
import { BsCloudUpload } from "react-icons/bs";
import { HiOutlineUserCircle, HiOutlineShieldCheck } from "react-icons/hi2";
import { IoAddCircleOutline } from "react-icons/io5";
import LightThemeCustomization from "../../Components/LightThemeCustomization";
import StandardLightTheme from "../../Components/StandardLightTheme";
import StandardDarkTheme from "../../Components/StandardDarkTheme";
import IconsAll from "../../Components/IconsAll";
import Title from "antd/es/typography/Title";
import AppInfo from "../../Components/AppInfo";
import Backdrop from "../../Layouts/Backdrop";
import { SidebarContext } from "../../Context/submenuContext";
import { tabletBreakpoint } from "../../helpers/utils";
import useScreenWidth from "../../Common/Hooks/ScreenWidth";
import { useTranslation } from "react-i18next";
import { themes } from "../../config";
import { useSelector } from "react-redux";

const suffix = (
  <IoAddCircleOutline
    style={{
      fontSize: 16,
      color: "#0C2340",
    }}
  />
);

const PersonInfoCard = styled(Card)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.PersonInfoCard
      .cardBg};
  border: transparent;
  border-radius: 12px;
  padding: 29px 32px;
  margin: 34px 0 32px;

  .ant-card-body {
    padding: 0;
    display: flex;
    align-items: center;
    gap: 24px;
    flex-flow: wrap;

    &:after,
    &:before {
      display: none;
    }

    .person-img {
      max-width: 124px;
      aspect-ratio: 1;
      border-radius: 100vh;
      overflow: hidden;
      outline: 1.5px solid var(--White, #fff);
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.08);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .person-info {
      .designation {
        color: ${() =>
          themes[useSelector((state) => state.theme.name)].token.grayClr};
        font-family: ${() =>
          themes[useSelector((state) => state.theme.name)].token.fontFamily};
      }
    }

    .role {
      font-size: 14px;
      font-weight: 300;
      width: fit-content;
      background-color: rgba(0, 133, 202, 0.12);
      color: #0085ca;
      padding: 7px 12px;
      border-radius: 8px;
      margin-top: 12px;
    }
  }
`;

const props = {
  action: "//jsonplaceholder.typicode.com/posts/",
  listType: "picture",
  previewFile(file) {
    console.log("Your upload file:", file);
    // Your process logic. Here we just mock to the same file
    return fetch("https://next.json-generator.com/api/json/get/4ytyBoLK8", {
      method: "POST",
      body: file,
    })
      .then((res) => res.json())
      .then(({ thumbnail }) => thumbnail);
  },
};

const SuperAdmin = () => {
  const { t } = useTranslation();

  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }

  const submenu = useContext(SidebarContext);
  const screenWidth = useScreenWidth();
  const isTabletOrSmaller = screenWidth <= tabletBreakpoint;
  const isSubMenuActive = submenu?.activatedItem === "user-details";

  return (
    <Flex className="w-full">
      {isSubMenuActive && isTabletOrSmaller && (
        <Backdrop onClick={() => submenu.toggleActivation()} />
      )}
      <div className="px-4 xl:px-16 w-full">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle="Ietje Niels"
            pageSubTitle={t("users")}
            pageIcon={false}
            backButton={true}
          />
          <RightSIdeTitlePart
            isFilterShow={false}
            deleteButton={true}
            archiveButton={true}
            disabledSubmitButton={true}
          />
        </div>

        <section className="container max-w-4xl mt-9">
          <PersonInfoCard>
            <div className="person-img flex-shrink-0">
              <img src={personImg} alt="" />
            </div>
            <div className="person-info">
              <Title level={2} className="!font-extrabold !text-xl">
                Ietje Niels
                <span className="font-light text-base designation">
                  , Founder
                </span>
              </Title>
              <a
                href="mailto:ietjeniels@nowonline.nl"
                className="flex items-center mt-1 text-slate-600 leading-7"
              >
                <img
                  src={profileLink}
                  alt="go to profile"
                  className="inline-block mr-2"
                />
                ietjeniels@nowonline.nl
              </a>
              <span className="flex items-center mt-1 text-slate-600 leading-7">
                <img
                  src={srcLocation}
                  alt="go to profile"
                  className="inline-block mr-2"
                />
                Doetinchem
              </span>
              <p className="role">Super Admin</p>
            </div>
          </PersonInfoCard>
          <Form layout="vertical">
            <Row gutter={28}>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item label={t("first_name")} className="font-bold">
                  <Input
                    type="text"
                    value="Ietje"
                    className="min-h-14 rounded-xl placeholder:font-normal px-6"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item label={t("last_name")} className="font-bold">
                  <Input
                    type="text"
                    value="Niels"
                    className="min-h-14 rounded-xl placeholder:font-normal px-6"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item label={t("email")} className="font-bold">
                  <Input
                    type="email"
                    value="ietjeniels@nowonline.nl"
                    className="min-h-14 rounded-xl placeholder:font-normal px-6"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item label={t("password")} className="font-bold">
                  <Input
                    type="password"
                    value="Waarde veilig gehasht"
                    className="min-h-14 rounded-xl placeholder:font-normal px-6"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label={t("users") + " " + t("image").toLowerCase()}
                  className="font-bold"
                >
                  <Upload {...props} className="w-full">
                    <Button
                      icon={<BsCloudUpload />}
                      className="dark:bg-inputField"
                    >
                      Click to Upload
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item label={t("residence")} className="font-bold">
                  <Input
                    type="text"
                    value="Ietje"
                    className="min-h-14 rounded-xl placeholder:font-normal px-6"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item label={t("title")} className="font-bold">
                  <Input
                    type="text"
                    value="Niels"
                    className="min-h-14 rounded-xl placeholder:font-normal px-6"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label={t("description")}
                  name="TextArea"
                  className="font-bold"
                  rules={[{ message: "Textarea (4 regels)" }]}
                >
                  <Input.TextArea className="rounded-xl" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item label={t("tags")} className="font-bold">
                  <Select
                    mode="multiple"
                    placeholder="jack"
                    style={{ flex: 1 }}
                    options={[
                      { value: "jack", label: "Jack" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "yiminghe" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item label={t("tags")} className="font-bold">
                  <Title
                    level={3}
                    className="flex items-center gap-3 !text-2xl !font-extrabold"
                  >
                    <HiOutlineUserCircle className="stroke-primary" />{" "}
                    {t("users")} {t("preferences").toLowerCase()}
                  </Title>
                  <Divider className="border-1 w-full my-2" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item label={t("language")} className="font-bold">
                  <Select
                    mode="tags"
                    placeholder={t("standard") + " " + t("environment")}
                    options={options}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item
                  label={
                    t("two") + "-" + t("factor") + " " + t("authentication")
                  }
                  className="font-bold"
                >
                  <Checkbox defaultChecked={false} disabled value="Pear">
                    Disabled
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label={t("email_notifications")}
                  className="font-bold"
                >
                  <Checkbox
                    defaultChecked={false}
                    value="Pear"
                    className="align-middle"
                  />
                  <span className="ms-3 align-middle font-normal">
                    {t("send_notifications")}
                  </span>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item label={t("tags")} className="font-bold">
                  <Title
                    level={3}
                    className="flex items-center gap-3 !text-2xl !font-extrabold"
                  >
                    <IconsAll.ThemeIcon />
                    {t("theme") + " " + t("setting").toLowerCase()}
                  </Title>
                  <Divider className="border-1 w-full my-2" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label={t("standard") + " " + t("appearance").toLowerCase()}
                  className="font-bold"
                >
                  <Select
                    placeholder="jack"
                    style={{ flex: 1 }}
                    options={[
                      { value: "jack", label: "Jack" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "yiminghe" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <StandardLightTheme />
              </Col>
              <Col className="gutter-row" span={24}>
                <span className="flex items-center gap-1 text-base font-extrabold">
                  {t("light") + " " + t("theme") + " " + t("customization")}
                </span>
              </Col>
              <Col className="gutter-row" span={24}>
                <LightThemeCustomization />
              </Col>
              <Col className="gutter-row mt-5" span={24}>
                <StandardDarkTheme />
              </Col>
              <Col className="gutter-row" span={24}>
                <span className="flex items-center gap-1 text-base font-extrabold">
                  {t("dark") +
                    " " +
                    t("theme").toLowerCase() +
                    " " +
                    t("customization")}
                </span>
              </Col>
              <Col className="gutter-row" span={24}>
                <LightThemeCustomization />
              </Col>

              <Col className="gutter-row mt-6" span={24}>
                <Form.Item label="" className="font-bold text-red-500">
                  <Title
                    level={3}
                    className="flex items-center gap-3 !text-2xl !font-extrabold"
                  >
                    <HiOutlineShieldCheck className="stroke-primary" />
                    {t("admin") + " " + t("options")}
                  </Title>
                  <Divider className="border-1 w-full my-2" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item label={t("status")} className="font-bold">
                  <Select
                    placeholder="Actief"
                    style={{ flex: 1 }}
                    options={[
                      { value: "Actief", label: "Actief" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "yiminghe" },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item label={t("role")} className="font-bold">
                  <Select
                    placeholder="Super Admin"
                    style={{ flex: 1 }}
                    options={[
                      { value: "Super Admin", label: "Super Admin" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "yiminghe" },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={24}>
                <Form.Item label={t("role")} className="font-bold">
                  <Input
                    placeholder={t("token_msg")}
                    suffix={suffix}
                    style={{ flex: 1, height: 54 }}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item label={t("provider")} className="font-bold">
                  <Select
                    placeholder="Default"
                    style={{ flex: 1 }}
                    options={[
                      { value: "Default", label: "Default" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "yiminghe" },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item
                  label={t("external") + " " + t("identification")}
                  className="font-bold"
                >
                  <Input
                    placeholder={
                      t("placeholder") + " " + t("for") + " " + t("input")
                    }
                    style={{ flex: 1, height: 54 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </section>
      </div>
      <AppInfo />
    </Flex>
  );
};

export default SuperAdmin;
