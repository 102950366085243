import React from "react";

import { Button, Card, Typography } from "antd";
import styled from "styled-components";
const { Title, Text } = Typography;

import { IoArrowBackOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { themes } from "../../config";
import { useSelector } from "react-redux";

const HeaderLeftSideStyle = styled(Card)`
  border: unset;
  background: unset;

  .ant-card-body {
    padding: 0;
    display: flex;
    align-items: center;
    gap: 16px;

    &:before {
      display: none;
    }

    .title-icon {
      width: 56px;
      aspect-ratio: 1;
      background-color: rgba(225, 227, 230, 0.24);
      color: #0085ca;
      border-radius: 100vh;
    }

    .header-title {
      margin: 0;
      font-size: 24px;
      font-weight: 800;
      line-height: 30px;
    }
    .header-sub-title {
      font-size: 14px;
      font-weight: 300;
      color: #88909a;
    }
  }
`;

const HeaderButton = styled(Button)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &.back-button {
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.backButtonBg};
    border: 1px solid
      ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.backButtonBorder};
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.backButtonClr};
  }
`;

const LeftSideTitlePart = ({
  pageTitle,
  pageSubTitle,
  pageIcon,
  backButton,
  backLink,
  pageInnerIcon,
}) => {
  return (
    <HeaderLeftSideStyle>
      {backButton && (
        <Link to={backLink}>
          <HeaderButton
            shape="circle"
            icon={<IoArrowBackOutline size={24} />}
            className="btn back-button"
          ></HeaderButton>
        </Link>
      )}
      {pageIcon && (
        <div className="title-icon | flex items-center justify-center">
          {pageInnerIcon}
        </div>
      )}
      <Typography>
        <Text type="primary" className="header-sub-title">
          {pageSubTitle}
        </Text>
        <Title className="header-title">{pageTitle}</Title>
      </Typography>
    </HeaderLeftSideStyle>
  );
};

export default LeftSideTitlePart;
