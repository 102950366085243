import React from "react";

const Backdrop = ({ onClick }) => {
  const backdrop = {
    height: "100vh",
    width: "100vw",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "fixed",
    top: 0,
    left: 0,
    backdropFilter: "blur(20px) saturate(180%)",
    zIndex: 9,
  };

  return <div id="backDrop" style={backdrop} onClick={onClick}></div>;
};

export default Backdrop;
