import { Button, Divider, Input, theme } from "antd";
import styled, { css } from "styled-components";
import SimpleBar from "simplebar-react";
import Sider from "antd/es/layout/Sider.js";
import { themes } from "../config";
import { useSelector } from "react-redux";

const StyledCollapsedButton = styled(Button)`
  height: 30px;
  width: 30px;
  padding: 2px;
  position: absolute;
  background: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Menu.itemBg};
  border: 1px solid
    ${() =>
      themes[useSelector((state) => state.theme.name)].components.Menu
        .headerBgColor};
  border-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.colorBorder};

  &:is(:not(:disabled):not(.ant-btn-disabled)):is(:hover, :active) {
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Menu
        .itemBgHover};
  }

  /* RTL Styles */
  ${() =>
    themes[useSelector((state) => state.theme.name)].direction === "rtl" &&
    css`
      left: -14px;
    `}
  /* LTR Styles */
      ${() =>
    themes[useSelector((state) => state.theme.name)].direction !== "rtl" &&
    css`
        right: -30px;~
      `}
`;

const StyleSimpleBar = styled(SimpleBar)`
  height: calc(100vh - 250px);
  padding-bottom: 20px;
`;

const StyleBrandLogo = styled.div`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Menu.itemBg};
  display: flex;
  align-items: center;
  height: calc(
    ${() =>
        themes[useSelector((state) => state.theme.name)].token
          .controlHeight}px * 2
  );
  padding-inline: 14px;
  line-height: 81px;
  justify-content: center;
`;

const StyleSider = styled(Sider)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  border-right: 1px solid
    ${() =>
      themes[useSelector((state) => state.theme.name)].components.Menu
        .menuBorderColor};
  background: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Menu.itemBg};

  .company-logo {
    height: calc(${theme.companyLogoHeight}px * 4);
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].token.headerLogoBg};
    .brand-sm-logo {
      display: none;
    }
  }

  .ant-menu-item {
    width: 100%;
    margin-inline: 0px;
    border-radius: 0px;
    transition: unset !important;
    height: 64px;
    display: flex;
    align-items: center;
    padding-left: 22px !important;
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Menu
        .sidebarNavTextClr};
    svg {
      flex-shrink: 0;
      stroke: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Menu
          .sidebarNavTextClr};
      width: 20px;
      height: 100%;
      &.whith-fill-clr {
        fill: ${() =>
          themes[useSelector((state) => state.theme.name)].components.Menu
            .itemBg};
      }
    }
    .ant-menu-title-content {
      transition: unset !important;
      text-overflow: unset !important;
    }
    &.ant-menu-item-selected {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Menu
          .headerBgColor};
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Menu
          .itemClr};
      svg {
        stroke: ${() =>
          themes[useSelector((state) => state.theme.name)].components.Menu
            .itemClr};
        &.whith-fill-clr {
          fill: #fff;
        }
      }
    }
    &:hover {
      background-color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Menu
          .headerBgColor} !important;
      svg {
        stroke: ${() =>
          themes[useSelector((state) => state.theme.name)].components.Menu
            .itemClr};
        &.whith-fill-clr {
          fill: #fff;
        }
      }
    }
  }

  .ant-menu-sub {
    .ant-menu-item {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .ant-menu-inline {
    border-inline-end: none !important;
  }

  &.ant-layout-sider-collapsed {
    .company-logo {
      .brand-dark-logo {
        display: none;
      }

      .brand-sm-logo {
        display: inline-block;
      }
    }

    .ant-menu-item-group-title {
      text-align: center;
    }
  }

  .ant-menu-inline-collapsed {
    width: 100%;
    border-inline-end: 0 !important;
  }
`;

const SidebarNotification = styled(Button)`
  color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Menu.itemColor};
  &.active {
    span {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        right: -3px;
        top: -3px;
        width: 12px;
        height: 12px;
        aspect-ratio: 1;
        border-radius: 100vh;
        background-color: #69caa7;
        border: 2px solid
          ${() =>
            themes[useSelector((state) => state.theme.name)].components.Menu
              .itemBg};
      }
    }
  }
`;

const SidebarDivider = styled(Divider)`
  border-color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.SideBarDivider
      .borderColor};
  opacity: ${() =>
    themes[useSelector((state) => state.theme.name)].components.SideBarDivider
      .opacity};
  width: 32px;
  min-width: 32px;
  margin: 12px 0;
`;

const SideBarUserProfile = styled(Button)`
  width: ${() =>
    themes[useSelector((state) => state.theme.name)].components
      .SideBarUserProfile.size};
  height: ${() =>
    themes[useSelector((state) => state.theme.name)].components
      .SideBarUserProfile.size};
  border-radius: 100vh;
  overflow: hidden;
  border-radius: 24px;
  border: 1px solid var(--notification-icon-clr);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.32);
  padding: 0;
  margin: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .ant-avatar {
    background-color: transparent;
  }
`;

const UpdateInputStyle = styled(Input)`
  input {
    min-height: 56px;
    border-radius: 12px 0 0 12px !important;
  }
  .ant-input-group-addon {
    padding: 0;
    background: transparent;
    border: 0;
    .ant-dropdown-trigger,
    .delete-button {
      width: 60px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid
        ${() =>
          themes[useSelector((state) => state.theme.name)].components.Input
            .colorBorder};
      border-left: 0;
      border-radius: 0 12px 12px 0;

      &:hover {
        border-color: ${() =>
          themes[useSelector((state) => state.theme.name)].components.Input
            .colorBorder};
      }
    }
  }
`;

export {
  StyledCollapsedButton,
  StyleSimpleBar,
  StyleBrandLogo,
  StyleSider,
  SidebarDivider,
  SideBarUserProfile,
  SidebarNotification,
  UpdateInputStyle,
};
