import { createSlice } from "@reduxjs/toolkit";
import { themes } from "../../config";

const initialState = themes.standard;

const themeSlice = createSlice({
  name: "themes",
  initialState,
  reducers: {
    setTheme: (state, action) => themes[action.payload],
  },
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;
