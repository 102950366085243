import React from "react";
import { Empty } from "antd";
import Apis from "./Apis";
import axiosInstance from "./axios";
import { useTranslation } from "react-i18next";

export const objectId = (value) => {
  return value.match(/^[0-9a-fA-F]{24}$/);
};

export const toDotNotation = (obj, prefix = "") => {
  let result = {};
  for (let key in obj) {
    let value = obj[key];
    let newKey = prefix ? prefix + "." + key : key;

    if (typeof value === "object" && value !== null) {
      Object.assign(result, toDotNotation(value, newKey));
    } else {
      result[newKey] = value;
    }
  }
  return result;
};

export const sortByKeys = (obj) => {
  const sortedKeys = Object.keys(obj).sort();

  const sortedObject = {};
  for (const key of sortedKeys) {
    sortedObject[key] = obj[key];
  }

  return sortedObject;
};

// export function simpleSchemaToItemSchema(simpleSchema, parentKey = "", joiner = ".") {
//   let itemSchema = [];
//   for (let key in simpleSchema) {
//     if (typeof simpleSchema[key] === "object") {
//       let children = simpleSchemaToItemSchema(simpleSchema[key], `${parentKey}${key}${joiner}`);
//       itemSchema.push({
//         key: `${parentKey}${key}`,
//         label: key,
//         children: children,
//       });
//     } else {
//       itemSchema.push({
//         key: `${parentKey}${key}`,
//         label: key,
//       });
//     }
//   }

//   return itemSchema;
// }

export function simpleSchemaToItemSchema(
  simpleSchema,
  parentKey = "",
  joiner = ".",
  listedSchema,
) {
  let itemSchema = [];
  for (let key in simpleSchema) {
    if (typeof simpleSchema[key] === "object") {
      let children = simpleSchemaToItemSchema(
        simpleSchema[key],
        `${parentKey}${key}${joiner}`,
        joiner,
        listedSchema ? listedSchema[key] : null,
      );
      itemSchema.push({
        key: `${parentKey}${key}`,
        label: key,
        children: children,
        disabled: false,
      });
    } else {
      itemSchema.push({
        key: `${parentKey}${key}`,
        label: key,
        disabled:
          listedSchema && JSON.stringify(listedSchema[key]) ? true : false,
      });
    }
  }

  return itemSchema;
}

export function updateItemSchemaData(itemSchema, key, updateData) {
  const newArray = itemSchema.map((res) => {
    if (res?.children) {
      updateItemSchemaData(res?.children, key, updateData);
    } else if (res.key === key) {
      Object.keys(updateData).map((ud) => {
        res[ud] = updateData[ud];
        return ud;
      });
      return res;
    }
    return res;
  });

  return newArray;
}

export function simpleSchemaToTreeSchema(
  simpleSchema,
  parentKey = "",
  joiner = ".",
  listedSchema,
) {
  let itemSchema = [];
  for (let key in simpleSchema) {
    if (typeof simpleSchema[key] === "object") {
      let children = simpleSchemaToTreeSchema(
        simpleSchema[key],
        `${parentKey}${key}${joiner}`,
        joiner,
        listedSchema ? listedSchema[key] : null,
      );
      itemSchema.push({
        key: `${parentKey}${key}`,
        title: key,
        children: children,
        isLeaf: false,
      });
    } else {
      itemSchema.push({
        key: `${parentKey}${key}`,
        title: key,
        isLeaf: true,
      });
    }
  }

  return itemSchema;
}

export function findIncludeValueFieldsInSchema(obj, include = "", path = []) {
  let result = [];
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      result = result.concat(
        findIncludeValueFieldsInSchema(obj[key], include, path.concat(key)),
      );
    } else if (typeof obj[key] === "string" && obj[key].includes(include)) {
      result.push({ path: path.concat(key).join("."), value: obj[key] });
    }
  }
  return result;
}

export async function retrieveLoginPrompt() {
  try {
    let response = await axiosInstance.get(Apis.LOGIN());
    return response;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export const tabletBreakpoint = 1279;

export function getCleanedFields(newField, prev) {
  const cleanedField = Object.fromEntries(
    Object.entries(newField)
      .map(([key, value]) =>
        key === "meta"
          ? [
              key,
              Object.fromEntries(
                Object.entries(value).filter(([, v]) => v !== undefined),
              ),
            ]
          : [key, value],
      )
      .filter(([, value]) => value !== undefined),
  );

  return {
    ...prev,
    fields: prev?.fields?.length
      ? [...prev.fields, cleanedField]
      : [cleanedField],
  };
}

export function capitalizeAndFormat(str) {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const CustomEmpty = () => (
  <Empty
    description={
      <span style={{ color: "rgb(128 128 128 / 45%)" }}>No Data Available</span>
    }
  />
);

export const getTranslatedLabel = (label) => {
  const { t } = useTranslation();
  return t(label?.replace("$t:", ""));
};

export const convertToTreeData = (fields, type) => {
  const root = [];
  const fieldMap = new Map();

  fields?.forEach((field) => {
    const pathParts = field?.path?.split(".") || [field?.path];
    let parentPathKey = "";

    pathParts.forEach((part, ind) => {
      const pathKey = pathParts.slice(0, ind + 1).join(".");
      let existingNode = fieldMap.get(pathKey);
      if (!existingNode) {
        existingNode =
          type === "tree-select"
            ? {
                label: part,
                value: field?.path,
                field_type: field?.field_type,
                children: [],
              }
            : {
                ...field,
                title: part,
                children: [],
                ...(type === "tree-columns" && { key: field?.path }),
                ...(type === "tree-columns" && {
                  disabled: ["Object"]?.includes(field?.type),
                }),
              };

        if (ind === 0) {
          root.push(existingNode);
        } else {
          const parentField = fieldMap.get(parentPathKey);
          if (parentField) {
            parentField.children.push(existingNode);
          }
        }
        fieldMap.set(pathKey, existingNode);
      }
      parentPathKey = pathKey;
    });
  });
  return root;
};
