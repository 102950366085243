import { Col, Row, Breadcrumb as BreadcrumbItem, theme } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ mainTitle, pageTitle, items = [] }) => {
  const { token } = theme.useToken();

  return (
    <React.Fragment>
      <Row align="middle" style={{ margin: "18px 0", color: token.colorText }}>
        <Col xs={24} md={12}>
          <h5 className="ant-font-size-16 ant-mb-0">{pageTitle}</h5>
        </Col>
        <Col xs={24} md={12}>
          <BreadcrumbItem
            items={[
              { title: mainTitle },
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...items?.map((bc) => {
                return {
                  title: (
                    <Link
                      key={bc.route}
                      to={bc.route}
                      style={{ color: token.colorText }}
                    >
                      {bc.displayName}
                    </Link>
                  ),
                };
              }),
            ]}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Breadcrumb;
