import React from "react";

const ValidationError = ({ error }) => {
  return (
    <div className="text-xs mt-1 text-[red]">
      {error?.custom_msg || error?.error_msg || ""}
      {/* {error?.context_error_msg && (
        <Tooltip title={error?.context_error_msg}>
          <IoIosInformationCircleOutline />
        </Tooltip>
      )} */}
    </div>
  );
};

export default ValidationError;
