import React, { useEffect, useId } from "react";
import RightSIdeTitlePart from "../../HeaderParts/RightSIdeTitlePart";
import LeftSideTitlePart from "../../HeaderParts/LeftSideTitlePart";
import { IoLanguage } from "react-icons/io5";
import {
  Col,
  ColorPicker,
  Form,
  Input,
  notification,
  Row,
  Select,
  Typography,
} from "antd";
import styled from "styled-components";
import { themes } from "../../../config";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import IconsAll from "../../IconsAll";
import TextArea from "antd/es/input/TextArea";
import useLanguage from "../../../helpers/useLanguage";

const TItleIconStyle = styled(Typography)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormStyling = styled(Form)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.colorGrayrBg};
  border-top: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  border-bottom: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  padding: 34px;
`;

const CreateChoices = ({ setChoices, setOpen, choiceType, activeChoice }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const id = useId();
  useEffect(() => {
    if (activeChoice) {
      setFormData();
    }
  }, [activeChoice]);

  const setFormData = async () => {
    await form.setFieldsValue(activeChoice);
  };
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setChoices((prev) => {
        if (
          prev?.some(
            (item, i) =>
              item?.value === values?.value && i !== activeChoice?.index,
          )
        ) {
          notification.error({ message: "Duplicate values are not allowed" });
          return prev;
        }

        if (activeChoice) {
          return prev.map((item) => {
            return item?.label === activeChoice?.label ? values : item;
          });
        }
        return [...prev, values];
      });
      setOpen(false);
      await form.resetFields();
    } catch (error) {
      notification.error({ message: "Something went wrong" });
    }
  };

  const generateChoiceForm = () => {
    switch (choiceType) {
      case "dropdown":
        return (
          <>
            <div className="flex flex-wrap md:items-center md:justify-between p-[24px]">
              <LeftSideTitlePart
                pageTitle={
                  <TItleIconStyle>
                    <IconsAll.ContentIcon strokeColor="#0085CA" />
                  </TItleIconStyle>
                }
              />
              <RightSIdeTitlePart showButton handleShowClick={handleSubmit} />
            </div>
            <FormStyling
              layout="vertical"
              className=""
              form={form}
              initialValues={{
                icon: "Icon 1",
                color: "#1677ff",
              }}
            >
              <Row gutter={28}>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("Text")}
                    className="font-bold"
                    htmlFor={`${id}-template`}
                    name={`label`}
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder={t("Enter a Text")}
                      id={`${id}-template`}
                      className="min-h-14 rounded-xl font-normal px-6"
                      suffix={<IoLanguage size={20} color="gray" />}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("Value")}
                    className="font-bold"
                    htmlFor={`${id}-template`}
                    name={`value`}
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder={t("Enter a Value")}
                      id={`${id}-template`}
                      className="min-h-14 rounded-xl font-normal px-6"
                      suffix={<IoLanguage size={20} color="gray" />}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("Icon")}
                    htmlFor={`${id}-icon`}
                    className="font-bold"
                    name={`icon`}
                  >
                    <Select
                      size="large"
                      placeholder="Icon 1"
                      style={{ flex: 1, height: "56px" }}
                      id={`${id}-icon`}
                      options={[
                        {
                          value: "Icon 1",
                          label: "Icon 1",
                        },
                        {
                          value: "Icon 2",
                          label: "Icon 2",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("Color")}
                    htmlFor={`${id}-color`}
                    className="font-bold"
                    name={`color`}
                  >
                    <ColorPicker
                      id={`${id}-color`}
                      showText
                      className="w-full justify-start px-3 py-7"
                      format="hex"
                      onChange={(_, hex) => form.setFieldsValue({ color: hex })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </FormStyling>
          </>
        );
      case "checkboxes":
        return (
          <>
            <div className="flex flex-wrap md:items-center md:justify-between p-[24px]">
              <LeftSideTitlePart
                pageTitle={
                  <TItleIconStyle>
                    <IconsAll.ContentIcon strokeColor="#0085CA" />
                  </TItleIconStyle>
                }
              />
              <RightSIdeTitlePart showButton handleShowClick={handleSubmit} />
            </div>
            <FormStyling layout="vertical" className="" form={form}>
              <Row gutter={28}>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("Text")}
                    className="font-bold"
                    htmlFor={`${id}-template`}
                    name={`label`}
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder={t("Enter a Text")}
                      id={`${id}-template`}
                      className="min-h-14 rounded-xl font-normal px-6"
                      suffix={<IoLanguage size={20} color="gray" />}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("Value")}
                    className="font-bold"
                    htmlFor={`${id}-template`}
                    name={`value`}
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder={t("Enter a Value")}
                      id={`${id}-template`}
                      className="min-h-14 rounded-xl font-normal px-6"
                      suffix={<IoLanguage size={20} color="gray" />}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </FormStyling>
          </>
        );
      case "radio":
        return (
          <>
            <div className="flex flex-wrap md:items-center md:justify-between p-[24px]">
              <LeftSideTitlePart
                pageTitle={
                  <TItleIconStyle>
                    <IconsAll.ContentIcon strokeColor="#0085CA" />
                  </TItleIconStyle>
                }
              />
              <RightSIdeTitlePart showButton handleShowClick={handleSubmit} />
            </div>
            <FormStyling layout="vertical" className="" form={form}>
              <Row gutter={28}>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("Text")}
                    className="font-bold"
                    htmlFor={`${id}-template`}
                    name={`label`}
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder={t("Enter a Text")}
                      id={`${id}-template`}
                      className="min-h-14 rounded-xl font-normal px-6"
                      suffix={<IoLanguage size={20} color="gray" />}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("Value")}
                    className="font-bold"
                    htmlFor={`${id}-template`}
                    name={`value`}
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder={t("Enter a Value")}
                      id={`${id}-template`}
                      className="min-h-14 rounded-xl font-normal px-6"
                      suffix={<IoLanguage size={20} color="gray" />}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </FormStyling>
          </>
        );
      case "translation":
        return (
          <>
            <div className="flex flex-wrap md:items-center md:justify-between p-[24px]">
              <LeftSideTitlePart
                pageTitle={
                  <TItleIconStyle>
                    <IconsAll.ContentIcon strokeColor="#0085CA" />
                  </TItleIconStyle>
                }
              />
              <RightSIdeTitlePart showButton handleShowClick={handleSubmit} />
            </div>
            <FormStyling layout="vertical" className="" form={form}>
              <Row gutter={28}>
                <Col className="gutter-row" xs={24}>
                  <Form.Item
                    label={t("Language")}
                    className="font-bold"
                    htmlFor={`${id}-language`}
                    name={`value`}
                    rules={[{ required: true }]}
                  >
                    <Select
                      id={`${id}-language`}
                      options={useLanguage()}
                      showSearch
                      filterOption={(input, option) =>
                        option?.label
                          ?.toLowerCase()
                          ?.includes(input?.toLowerCase())
                      }
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24}>
                  <Form.Item
                    label={t("Translation")}
                    className="font-bold"
                    htmlFor={`${id}-translation`}
                    name={`label`}
                    rules={[{ required: true }]}
                  >
                    <TextArea
                      placeholder={t("Enter a translation")}
                      id={`${id}-translation`}
                      className="min-h-14 rounded-xl font-normal px-6"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </FormStyling>
          </>
        );
      default:
        return <></>;
    }
  };
  return generateChoiceForm();
};

export default CreateChoices;
