import React from "react";
import { getTranslatedLabel } from "../../../helpers/utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CustomReactQuill = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  return (
    <ReactQuill
      placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
      theme="snow"
      value={formData?.[item.path] || item?.schema_definition?.default}
      onChange={(value) => updateFormData({ key: item.path, value: value })}
      className={`rounded-xl ${isValidationError ? "border-[red]" : ""}`}
      readOnly={!!item?.meta?.readonly}
      // modules={{
      //   toolbar: item?.meta?.options?.toolbar || [
      //     [{ header: [1, 2, false] }],
      //     ["bold", "italic", "underline", "strike", "blockquote"],
      //     [
      //       { list: "ordered" },
      //       { list: "bullet" },
      //       { indent: "-1" },
      //       { indent: "+1" },
      //     ],
      //     ["link", "image"],
      //     ["clean"],
      //   ],
      // }}
    />
  );
};

export default CustomReactQuill;
