import { Tabs } from "antd";
import styled from "styled-components";

const DrawerSidebarTabs = styled(Tabs)`
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  .ant-tabs-nav {
    min-width: 200px;
    padding-left: 10px;
    padding-right: 10px;
    @media screen and (max-width: 768px) {
      min-width: 100%;
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          border-radius: 8px;
          margin-top: 5px;
          > div {
            color: #000 !important;
            font-size: 16px;
            font-style: normal;
          }
          &.ant-tabs-tab-active {
            background-color: #ebeced;
          }
        }
      }
    }
  }
`;

export { DrawerSidebarTabs };
