import React from "react";
import { Flex, Card, Menu, Image } from "antd";
import styled from "styled-components";
import signalIcon from "../Assets/images/Signals/good.svg";
import { Link } from "react-router-dom";
import NoxVersion from "../Assets/images/Icon/Side_bar_icon/nox-version.svg";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../Redux/slices/activeTab.reducer";
import { themes } from "../config";
import { store } from "../Redux/store";

// Card Style
export const SubMenuHeaderStyle = styled(Card)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Menu
      .navBrandBg};
  width: 100%;
  min-height: calc(
    ${() =>
        themes[useSelector((state) => state.theme.name)].companyLogoHeight}px *
      4
  );
  border: 0;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-card-body {
    padding: 0;
    .company-info-main {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-inline: 15px;
    }
    .company-info {
      > * {
        margin-bottom: 0;
      }
      .company-info-title {
        font-size: 14px;
        font-weight: 700;
        font-family: ${() =>
          themes[useSelector((state) => state.theme.name)].token.fontFamily};
        color: ${() =>
          themes[useSelector((state) => state.theme.name)].token.colorText};
        line-height: 1;
      }
      p {
        font-size: 14px;
        font-weight: 300;
        color: ${() =>
          themes[useSelector((state) => state.theme.name)].token.grayClr};
      }
    }
  }
`;

export const SubMenuListStyle = styled(Menu)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Menu
      .headerBgColor};
  padding-left: 5px;
  border-inline-end: 1px solid transparent !important;
  height: calc(100% - 100px);
  overflow-y: auto;
  .ant-menu-item {
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    .ant-menu-title-content {
      text-overflow: unset !important;
      a {
        color: ${() =>
          themes[useSelector((state) => state.theme.name)].components.Menu
            .itemClr};
        font-size: 16px;
      }
    }
    &.ant-menu-item-selected {
      background-color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Menu
          .itemSelectedBg};
      border: 1px solid rgba(255, 255, 255, 0.48);
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Menu
          .itemBg};
    }
  }
  .ant-menu-item-divider {
    margin-block: 1rem 1.25rem;
    border-color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Divider
        .borderColor};
  }
`;

export const SidebarContainer = styled(Flex)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Menu
      .headerBgColor};
`;

function getItem(label, key, icon, children, theme, type) {
  return {
    key,
    icon,
    children,
    label,
    theme,
    type,
  };
}
const SubMenu = ({ items, isSubMenuLogo, isSubMenuVersion, currentPage }) => {
  const activeTab = useSelector((state) => state.activeTab[currentPage]);
  const dispatch = useDispatch();

  function getItems(items) {
    let parsedItem = [];
    items?.forEach((item) => {
      parsedItem.push(
        getItem(
          <Link to={item?.link}>{item?.name}</Link>,
          item?.name,
          item?.icon,
        ),
      );
      if (item?.divider === true) {
        parsedItem.push({
          type: "divider",
        });
      }
    });
    return parsedItem;
  }

  const handleMenuClick = (key) => {
    dispatch(
      setActiveTab({
        page: currentPage,
        tab: key,
        redirectionLink: items.find((i) => i.name === key).link,
      }),
    );
  };

  const user = store.getState()?.user?.userProfile;

  return (
    <SidebarContainer vertical>
      <div className="w-[260px] fixed top-0 bottom-0 z-50 lg:min-h-screen">
        {isSubMenuLogo ? (
          <SubMenuHeaderStyle>
            <Flex gap={12} className="company-info-main">
              <img src={signalIcon} size={24} alt="nox" />
              <div className="company-info">
                <h3 className="company-info-title">{user?.tenant?.name}</h3>
                {/* <p>Website: nowonline.nl</p> */}
              </div>
            </Flex>
          </SubMenuHeaderStyle>
        ) : null}
        <SubMenuListStyle
          items={getItems(items)}
          onClick={({ key }) => handleMenuClick(key)}
          selectedKeys={[activeTab?.currentTab]}
        />
        {isSubMenuVersion ? (
          <Flex className="px-6 my-3">
            <Image preview={false} src={NoxVersion} alt="" width={21} />
            <span className="align-bottom mt-[-7px] text-gray-400 ms-2">
              v1.1.0
            </span>
          </Flex>
        ) : null}
      </div>
    </SidebarContainer>
  );
};

export default SubMenu;
