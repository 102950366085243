import React, { useEffect, useId, useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Flex,
  Dropdown,
  Space,
  notification,
  Tooltip,
  Drawer,
} from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { themes } from "../../../config";
import { BsInfoCircle } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import LeftSideTitlePart from "../../HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../HeaderParts/RightSIdeTitlePart";
import IconsAll from "../../IconsAll";
import { DownOutlined } from "@ant-design/icons";
import axiosInstance from "../../../helpers/axios";
import Apis from "../../../helpers/Apis";
import { store } from "../../../Redux/store";
import { UpdateInputStyle } from "../../../Common/SidebarStyle";
import { IoIosInformationCircleOutline } from "react-icons/io";
import AddVertalingen from "../../../Pages/Instellingen/AddVertalingen";

const FormStyling = styled(Form)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.colorGrayrBg};
  border-top: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  border-bottom: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  padding: 34px;
`;

const NotHasField = styled(Flex)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.NotHasFieldClr};
  border-left: 6px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.brandColor};
  padding-inline: 15px;
  border-radius: 6px;
  min-height: 56px;
`;

const DrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: white;
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    padding: 0;
    .ant-tabs-nav-wrap {
      padding-top: 1rem;
    }
    .ant-collapse {
      display: grid;
      grid-template-columns: repete(4, 1fr);
      position: relative;

      .ant-collapse-item {
        .ant-collapse-content {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: auto;
          transform: translateY(100%);
        }
      }
    }
    .ant-tabs-left {
      min-height: 100dvh;
      min-height: 100vh;
      .ant-tabs-content-holder {
        padding: 24px;
        .ant-tabs-tabpane {
          padding-left: 0 !important;
        }
      }
    }
  }
`;

export const hasNewFieldFunc = () => {
  return (
    <NotHasField className="items-center gap-3 bg-grey-200">
      <BsInfoCircle size={20} />
      <p className="font-normal">No Items</p>
    </NotHasField>
  );
};

const UpdateInterface = ({
  activeItem,
  setIsDrawerOpen,
  dataSource,
  setActiveItem,
  init,
}) => {
  const { t } = useTranslation();
  const id = useId();
  const [form] = Form.useForm();
  const [translationsList, setTranslationsList] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isTranslationDrawerOpen, setIsTranslationDrawerOpen] = useState(false);

  const getTranslations = async () => {
    try {
      const resp = await axiosInstance.get(Apis.GET_TRANSLATIONS("", true), {
        headers: {
          "x-tenant-id": store.getState().user.userId,
        },
      });
      if (resp.status === 200) setTranslationsList(resp.data.payload.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setFormData();
  }, [activeItem?._id]);

  useEffect(() => {
    getTranslations();
  }, []);

  const setFormData = async () => {
    activeItem?.meta?.options?.placeholder?.includes("$t:") &&
      setSelectedValue(
        activeItem?.meta?.options?.placeholder?.replace("$t:", ""),
      );
    form.setFieldsValue({
      placeholder: activeItem?.meta?.options?.placeholder?.includes("$t:")
        ? activeItem?.meta?.options?.placeholder?.replace("$t:", "")
        : activeItem?.meta?.options?.placeholder,
    });
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const finalValues = {
        ...activeItem,
        meta: {
          ...activeItem?.meta,
          options: {
            ...activeItem?.meta?.options,
            placeholder:
              (values?.placeholder && selectedValue
                ? "$t:" + values?.placeholder
                : values?.placeholder) || "",
          },
        },
      };
      const { _id, ...payload } = finalValues;
      delete payload?.__v;

      const resp = await axiosInstance.put(Apis.UPDATE_A_FIELD(_id), payload, {
        headers: {
          "x-tenant-id": store.getState().user.userId,
        },
      });
      if (resp.status === 200) {
        setIsDrawerOpen(false);
        setActiveItem(null);
        init();
        notification.success({ message: "Field updated successfully" });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // const InputCollapse = () => {
  //   return (
  //     <Row gutter={16}>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Minimum Value")}
  //           htmlFor={`${id}-minimum`}
  //           className="font-bold"
  //           name={`minimum`}
  //         >
  //           <InputNumber
  //             placeholder={0}
  //             id={`${id}-minimum`}
  //             min={1}
  //             max={10}
  //             className="min-h-14 flex items-center rounded-lg placeholder:font-normal px-3 w-full leading-10 overflow-hidden"
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Maximum Value")}
  //           htmlFor={`${id}-maximum`}
  //           className="font-bold"
  //           name={`maximum`}
  //         >
  //           <InputNumber
  //             placeholder={0}
  //             id={`${id}-maximum`}
  //             min={1}
  //             max={10}
  //             className="min-h-14 flex items-center rounded-lg placeholder:font-normal px-3 w-full leading-10 overflow-hidden"
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Step Interval")}
  //           htmlFor={`${id}-interval`}
  //           className="font-bold"
  //           name={`interval`}
  //         >
  //           <InputNumber
  //             placeholder={1}
  //             id={`${id}-interval`}
  //             min={1}
  //             max={10}
  //             className="min-h-14 flex items-center rounded-lg placeholder:font-normal px-3 w-full leading-10 overflow-hidden"
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Placeholder")}
  //           className="font-bold"
  //           htmlFor={`${id}-placeholder`}
  //           name={`placeholder`}
  //         >
  //           <Input
  //             placeholder={t("Enter a placeholder")}
  //             id={`${id}-placeholder`}
  //             className="min-h-14 rounded-xl font-normal px-6"
  //             suffix={<IoLanguage size={20} color="gray" />}
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Icon Left")}
  //           htmlFor={`${id}-left`}
  //           name={`left`}
  //           className="font-bold"
  //         >
  //           <Select
  //             size="large"
  //             className="font-normal"
  //             placeholder="Search for icon"
  //             style={{ flex: 1, height: "56px" }}
  //             id={`${id}-left`}
  //             options={[
  //               { value: "String", label: "String" },
  //               { value: "ObjectId", label: "ObjectId" },
  //               { value: "Boolean", label: "Boolean" },
  //               { value: "Number", label: "Number" },
  //             ]}
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Icon Right")}
  //           htmlFor={`${id}-right`}
  //           name={`right`}
  //           className="font-bold"
  //         >
  //           <Select
  //             size="large"
  //             className="font-normal"
  //             placeholder="Search for icon"
  //             style={{ flex: 1, height: "56px" }}
  //             id={`${id}-right`}
  //             options={[
  //               { value: "String", label: "String" },
  //               { value: "ObjectId", label: "ObjectId" },
  //               { value: "Boolean", label: "Boolean" },
  //               { value: "Number", label: "Number" },
  //             ]}
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Font")}
  //           htmlFor={`${id}-font`}
  //           name={`font`}
  //           className="font-bold"
  //         >
  //           <Select
  //             size="large"
  //             className="font-normal"
  //             style={{ flex: 1, height: "56px" }}
  //             id={`${id}-font`}
  //             options={[
  //               { value: "Sans Serif", label: "Sans Serif" },
  //               { value: "Monospace", label: "Monospace" },
  //               { value: "Serif", label: "Serif" },
  //             ]}
  //           />
  //         </Form.Item>
  //       </Col>
  //     </Row>
  //   );
  // };

  // const DropdownCollapse = () => {
  //   return (
  //     <Row gutter={16}>
  //       <Col md={24}>
  //         <Form.Item
  //           label={t("Choices")}
  //           htmlFor={`${id}-new-field`}
  //           className="font-bold"
  //         >
  //           {hasNewField ? "Yes" : hasNewFieldFunc()}
  //           <Button
  //             className="rounded-3xl  border border-solid border-grey-full bg-grey-full text-black px-5 py-3 mt-4 font-medium h-auto"
  //             onClick={showDrawer}
  //           >
  //             Create New
  //           </Button>
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Allow Other")}
  //           htmlFor={`${id}-allow-other`}
  //           className="font-bold"
  //           name={`allow-other`}
  //           valuePropName=""
  //         >
  //           <InvertedCheckBox id={`${id}-allow-other`}>
  //             Allow Other Values
  //           </InvertedCheckBox>
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Allow None")}
  //           htmlFor={`${id}-allow-none`}
  //           className="font-bold"
  //           name={`allow-none`}
  //           valuePropName=""
  //         >
  //           <InvertedCheckBox id={`${id}-allow-none`}>
  //             Allow No Selection
  //           </InvertedCheckBox>
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Icon")}
  //           htmlFor={`${id}-left`}
  //           name={`left`}
  //           className="font-bold"
  //         >
  //           <Select
  //             size="large"
  //             className="font-normal"
  //             placeholder="Search for icon"
  //             style={{ flex: 1, height: "56px" }}
  //             id={`${id}-left`}
  //             options={[
  //               { value: "String", label: "String" },
  //               { value: "ObjectId", label: "ObjectId" },
  //               { value: "Boolean", label: "Boolean" },
  //               { value: "Number", label: "Number" },
  //             ]}
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Placeholder")}
  //           className="font-bold"
  //           htmlFor={`${id}-placeholder`}
  //           name={`placeholder`}
  //         >
  //           <Input
  //             placeholder={t("Enter a placeholder")}
  //             id={`${id}-placeholder`}
  //             className="min-h-14 rounded-xl font-normal px-6"
  //             suffix={<IoLanguage size={20} color="gray" />}
  //           />
  //         </Form.Item>
  //       </Col>
  //     </Row>
  //   );
  // };

  // const RadioCollapse = () => {
  //   return (
  //     <Row gutter={16}>
  //       <Col md={24}>
  //         <Form.Item
  //           label={t("Choices")}
  //           htmlFor={`${id}-new-field`}
  //           className="font-bold"
  //         >
  //           {hasNewField ? "Yes" : hasNewFieldFunc()}
  //           <Button
  //             className="rounded-3xl  border border-solid border-grey-full bg-grey-full text-black px-5 py-3 mt-4 font-medium h-auto"
  //             onClick={showDrawer}
  //           >
  //             Create New
  //           </Button>
  //         </Form.Item>
  //       </Col>
  //     </Row>
  //   );
  // };

  // const SliderCollapse = () => {
  //   return (
  //     <Row gutter={16}>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Minimum Value")}
  //           htmlFor={`${id}-minimum`}
  //           className="font-bold"
  //           name={`minimum`}
  //         >
  //           <InputNumber
  //             placeholder={0}
  //             id={`${id}-minimum`}
  //             min={1}
  //             max={10}
  //             className="min-h-14 flex items-center rounded-lg placeholder:font-normal px-3 w-full leading-10 overflow-hidden"
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Maximum Value")}
  //           htmlFor={`${id}-maximum`}
  //           className="font-bold"
  //           name={`maximum`}
  //         >
  //           <InputNumber
  //             placeholder={0}
  //             id={`${id}-maximum`}
  //             min={1}
  //             max={10}
  //             className="min-h-14 flex items-center rounded-lg placeholder:font-normal px-3 w-full leading-10 overflow-hidden"
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Step Interval")}
  //           htmlFor={`${id}-interval`}
  //           className="font-bold"
  //           name={`interval`}
  //         >
  //           <InputNumber
  //             placeholder={1}
  //             id={`${id}-interval`}
  //             min={1}
  //             max={10}
  //             className="min-h-14 flex items-center rounded-lg placeholder:font-normal px-3 w-full leading-10 overflow-hidden"
  //           />
  //         </Form.Item>
  //       </Col>
  //       <Col md={12}>
  //         <Form.Item
  //           label={t("Always show value")}
  //           htmlFor={`${id}-show-value`}
  //           className="font-bold"
  //           name={`show-value`}
  //           valuePropName=""
  //         >
  //           <InvertedCheckBox id={`${id}-show-value`}>Enabled</InvertedCheckBox>
  //         </Form.Item>
  //       </Col>
  //     </Row>
  //   );
  // };

  return (
    <Flex wrap="wrap" align="center" justify="space-between">
      <LeftSideTitlePart
        pageTitle={
          activeItem?.meta?.interface.charAt(0).toUpperCase() +
          activeItem?.meta?.interface.slice(1) +
          " " +
          activeItem?.type +
          " (" +
          dataSource?.collection_id +
          ")"
        }
        pageIcon={true}
        pageInnerIcon={<IconsAll.ContentIcon strokeColor={"#3586c7"} />}
      />
      <RightSIdeTitlePart showButton={true} handleShowClick={handleSubmit} />
      <FormStyling
        form={form}
        layout="vertical"
        initialValues={{
          type: "String",
          icon_left: "Icon 1",
          icon_right: "Icon 1",
        }}
        className="mt-4"
        style={{ flexBasis: "100%" }}
      >
        <Row gutter={28}>
          <Col className="gutter-row" xs={24} md={24}>
            <Form.Item
              label={t("Placeholder")}
              htmlFor={`${id}-placeholder`}
              className="font-bold"
              name={`placeholder`}
            >
              <UpdateInputStyle
                onChange={(e) => {
                  !selectedValue &&
                    form.setFieldValue("placeholder", e.target.value || "");
                }}
                disabled={selectedValue}
                addonAfter={
                  selectedValue ? (
                    <Button
                      onClick={() => {
                        form.setFieldValue("placeholder", "");
                        setSelectedValue(null);
                      }}
                      className="delete-button"
                    >
                      <IconsAll.TrashIcon />
                    </Button>
                  ) : (
                    <Dropdown
                      trigger={"click"}
                      menu={{
                        items: [
                          ...(translationsList?.map((tr) => {
                            return {
                              key: tr?._id,
                              label: (
                                <div
                                  onClick={() => {
                                    form.setFieldValue("placeholder", tr?.key);
                                    setSelectedValue(tr?.key);
                                  }}
                                >
                                  {tr?.key}
                                  <Tooltip
                                    title={tr?.translations?.map((tr, i) => {
                                      return (
                                        <div key={i}>
                                          {tr?.language}: {tr?.value}
                                        </div>
                                      );
                                    })}
                                  >
                                    <IoIosInformationCircleOutline />
                                  </Tooltip>
                                </div>
                              ),
                            };
                          }) || []),
                          {
                            key: "add-button",
                            label: (
                              <div
                                onClick={() => setIsTranslationDrawerOpen(true)}
                              >
                                New Custom Translation
                              </div>
                            ),
                          },
                        ],
                      }}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <DownOutlined />
                        </Space>
                      </a>
                    </Dropdown>
                  )
                }
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Collapse
        className="edit-field-collapse mb-4"
        collapsible="header"
        defaultActiveKey={["1"]}
        items={[
          {
            key: "1",
            showArrow: false,
            label: (
              <div className="flex flex-wrap flex-row gap-3 items-center">
                <div className="w-8 h-8 flex items-center justify-center rounded-full bg-white">
                  <FiType />
                </div>
                <div className="flex-grow">
                  <div>Input</div>
                  <small>Manually enter a value</small>
                </div>
              </div>
            ),
            children: <InputCollapse />,
          },
        ]}
      />
      <Collapse
        className="edit-field-collapse mb-4"
        collapsible="header"
        defaultActiveKey={["1"]}
        items={[
          {
            key: "2",
            showArrow: false,
            label: (
              <div className="flex flex-wrap flex-row gap-3 items-center">
                <div className="w-8 h-8 flex items-center justify-center rounded-full bg-white">
                  <IoIosArrowDropdown />
                </div>
                <div className="flex-grow">
                  <div>Dropdown</div>
                  <small>Select a value from a dropdown</small>
                </div>
              </div>
            ),
            children: <DropdownCollapse />,
          },
        ]}
      />
      <Collapse
        className="edit-field-collapse mb-4"
        collapsible="header"
        defaultActiveKey={["1"]}
        items={[
          {
            key: "3",
            showArrow: false,
            label: (
              <div className="flex flex-wrap flex-row gap-3 items-center">
                <div className="w-8 h-8 flex items-center justify-center rounded-full bg-white">
                  <IoMdRadioButtonOn />
                </div>
                <div className="flex-grow">
                  <div>Radio Buttons</div>
                  <small>Select one of multiple choices</small>
                </div>
              </div>
            ),
            children: <RadioCollapse />,
          },
        ]}
      />
      <Collapse
        className="edit-field-collapse mb-4"
        collapsible="header"
        defaultActiveKey={["1"]}
        items={[
          {
            key: "4",
            showArrow: false,
            label: (
              <div className="flex flex-wrap flex-row gap-3 items-center">
                <div className="w-8 h-8 flex items-center justify-center rounded-full bg-white">
                  <RxSlider />
                </div>
                <div className="flex-grow">
                  <div>Slider</div>
                  <small>Select a number using a slider</small>
                </div>
              </div>
            ),
            children: <SliderCollapse />,
          },
        ]}
      /> */}
      </FormStyling>
      <DrawerStyle
        width={870}
        open={isTranslationDrawerOpen}
        onClose={() => {
          setIsTranslationDrawerOpen(false);
          // setActiveItem(null);
          // init();
        }}
        closeIcon={<IconsAll.CloseIconCustom />}
        destroyOnClose
      >
        <AddVertalingen
          setIsTranslationDrawerOpen={setIsTranslationDrawerOpen}
          type={"drawer"}
          getTranslations={getTranslations}
        />
      </DrawerStyle>
    </Flex>
  );
};

export default UpdateInterface;
