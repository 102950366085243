import { DatePicker } from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import utc from "dayjs/plugin/utc";
import { getTranslatedLabel } from "../../../helpers/utils";
dayjs.extend(utc);

const CustomDatetime = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData({
        key: item.path,
        value: item?.schema_definition?.default
          ? dayjs(item?.schema_definition?.default).toISOString()
          : null,
      });
    }
  }, [formData]);

  const onOk = (value) => {
    updateFormData({
      key: item.path,
      value: value ? value.toISOString() : null,
    });
  };
  return (
    <DatePicker
      size={"large"}
      style={{ height: "56px" }}
      placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
      showTime
      onOk={onOk}
      defaultValue={
        item?.schema_definition?.default
          ? dayjs(item?.schema_definition?.default)
          : null
      }
      onChange={onOk}
      value={dayjs(formData?.[item.path])}
      className={`font-normal ${isValidationError ? "border-[red]" : ""}`}
      disabled={!!item?.meta?.readonly}
    />
  );
};

export default CustomDatetime;
