import { Col, Drawer, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import TenantListTable from "./TenantListTable";
import styled from "styled-components";
import Tenant from "./Tenant";
import IconsAll from "../../Components/IconsAll";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import Apis from "../../helpers/Apis";
import axiosInstance from "../../helpers/axios";
import { useTranslation } from "react-i18next";
import AppInfo from "../../Components/AppInfo";

const DrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: white;
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    padding: 0;
    .ant-tabs-nav {
      padding-top: 40px;
      width: min(100%, 200px);
      min-height: 100vh;
      background-color: #f5f6f7;
      .ant-tabs-nav-wrap {
        &:before,
        &:after,
        .ant-tabs-ink-bar {
          display: none;
        }
        .ant-tabs-nav-list {
          padding-inline: 10px;
          .ant-tabs-tab {
            border-radius: 8px;
            margin-top: 5px;
            > div {
              color: #000 !important;
              font-size: 16px;
              font-style: normal;
            }
            &.ant-tabs-tab-active {
              background-color: #ebeced;
            }
          }
        }
      }
    }

    .ant-tabs-content-holder {
      padding-top: 40px;
      .ant-tabs-tabpane {
        padding-right: 24px;
      }
    }
  }
`;

const TenantList = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [activeTenant, setActiveTenant] = useState(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const resp = await axiosInstance.get(Apis.GET_ALL_TENANTS());
    if (resp.status === 200) setTenants(resp?.data?.payload?.data || []);
  };

  return (
    <Row className="main-content-wrap">
      <Col className="px-8 xl:px-16 left-part">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle={t("users")}
            pageIcon={false}
            backButton={false}
          />
          <RightSIdeTitlePart
            addButton={true}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />
          <DrawerStyle
            width={870}
            open={isDrawerOpen}
            onClose={() => {
              setIsDrawerOpen(false), setActiveTenant(null), form.resetFields();
            }}
            closeIcon={<IconsAll.CloseIconCustom />}
          >
            <Tenant
              form={form}
              setIsDrawerOpen={setIsDrawerOpen}
              setActiveTenant={setActiveTenant}
              init={init}
              activeTenant={activeTenant}
            />
          </DrawerStyle>
        </div>
        <section className="mt-9">
          <TenantListTable
            tenants={tenants}
            setIsDrawerOpen={setIsDrawerOpen}
            setActiveTenant={setActiveTenant}
          />
        </section>
      </Col>
      <Col className="right-part">
        <AppInfo />
      </Col>
    </Row>
    /* <Flex className="w-full">

      <div className="px-4 xl:px-16 w-full">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle={t("users")}
            pageIcon={false}
            backButton={false}
          />
          <RightSIdeTitlePart
            addButton={true}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />
          <DrawerStyle
            width={870}
            open={isDrawerOpen}
            onClose={() => {
              setIsDrawerOpen(false), setActiveTenant(null), form.resetFields();
            }}
            closeIcon={<IconsAll.CloseIconCustom />}
          >
            <Tenant
              form={form}
              setIsDrawerOpen={setIsDrawerOpen}
              setActiveTenant={setActiveTenant}
              init={init}
              activeTenant={activeTenant}
            />
          </DrawerStyle>
        </div>
        <section className="mt-9">
          <TenantListTable
            tenants={tenants}
            setIsDrawerOpen={setIsDrawerOpen}
            setActiveTenant={setActiveTenant}
          />
        </section>
      </div>
      <AppInfo />
    </Flex> */
  );
};

export default TenantList;
