import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Common/Breadcrumb";
import { Card, Table } from "antd";
import Apis from "../../helpers/Apis";
import axios from "axios";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { TbTableExport } from "react-icons/tb";
import styles from "./index.module.css";

const CollectionsList = () => {
  document.title = "Collections" + process.env.REACT_APP_PAGE_TITLE;
  const [collections, setCollections] = useState([]);

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      width: "10%",
    },
    {
      title: "Model Name",
      dataIndex: "name",
      width: "25%",
    },
    {
      title: "Model Id",
      dataIndex: "id",
      width: "25%",
    },
    {
      title: "Operation",
      dataIndex: "operation",
      align: "center",
      render: (_, record) => {
        return (
          <span className={styles.operation_span}>
            <Link to={`/collection/${record.id}`}>
              <EyeOutlined size={18} />
            </Link>
            <Link to={`/collection/${record.id}/mapping`}>
              <TbTableExport size={18} />
            </Link>
          </span>
        );
      },
    },
  ];

  const getCollections = async () => {
    try {
      const response = await axios.get(Apis.GET_ALL_COLLECTION_NAMES());
      const data = response.data.payload?.data.map((c, index) => {
        c.key = index + 1;
        return c;
      });
      setCollections(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCollections();

    return () => {};
  }, []);

  return (
    <>
      <Breadcrumb
        mainTitle="NOX"
        pageTitle="Collections"
        items={[
          {
            route: "#",
            displayName: "Collections",
          },
        ]}
      />
      <Card>
        <Table columns={columns} dataSource={collections} />
      </Card>
    </>
  );
};

export default CollectionsList;
