import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, userId: action.payload.userId };
    },
    setUserProfile: (state, action) => {
      return { ...state, userProfile: action.payload };
    },
  },
});

export const { setUser, setUserProfile } = user.actions;
export default user.reducer;
