import React, { useEffect } from "react";
import { Select } from "antd";
import { getTranslatedLabel } from "../../../helpers/utils";

const CustomDropdown = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData({
        key: item.path,
        value: item.schema_definition?.default || "",
      });
    }
  }, [formData]);
  return (
    <Select
      showSearch
      placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      fieldNames={{
        label: "label",
        value: "value",
      }}
      onSelect={(value) => {
        updateFormData({ key: item.path, value: value || "" });
      }}
      value={formData?.[item?.path]}
      options={item?.meta?.options?.choices}
      disabled={!!item?.meta?.readonly}
      className={`${isValidationError ? "border-[red]" : ""}`}
    />
  );
};

export default CustomDropdown;
