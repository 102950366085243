import React, { useEffect, useState } from "react";
import { Flex, Input, Tag, Tooltip } from "antd";

const CustomTags = ({ item, updateFormData, formData, isValidationError }) => {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  //   const [editInputIndex, setEditInputIndex] = useState(-1);
  //   const [editInputValue, setEditInputValue] = useState("");
  //   const editInputRef = useRef(null);

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    updateFormData({ key: item.path, value: newTags });
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
      updateFormData({ key: item.path, value: [...tags, inputValue] });
    }
    setInputValue("");
  };

  //   const handleEditInputChange = (e) => {
  //     setEditInputValue(e.target.value);
  //   };

  //   const handleEditInputConfirm = () => {
  //     const newTags = [...tags];
  //     newTags[editInputIndex] = editInputValue;
  //     setTags(newTags);
  //     setEditInputIndex(-1);
  //     setEditInputValue("");
  //   };

  //   useEffect(() => {
  //     editInputRef.current?.focus();
  //   }, [editInputValue]);

  useEffect(() => {
    setTags((prev) => {
      return Array.from(
        new Set([
          ...prev,
          // ...(item?.meta?.options?.presets || []),
          ...(item?.schema_definition?.default || []),
          ...(formData?.[item.path] || []),
        ]),
      );
    });
  }, [item?.meta?.options?.presets]);

  useEffect(() => {
    updateFormData({ key: item.path, value: tags });
  }, [tags]);

  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData({
        key: item.path,
        value: item?.schema_definition?.default,
      });
    }
  }, [formData]);
  return (
    <div>
      <Input
        type="text"
        className={`min-h-14 rounded-xl placeholder:font-normal px-3 mb-2 ${isValidationError ? "border-[red]" : ""}`}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputConfirm}
        onPressEnter={handleInputConfirm}
        disabled={!!item?.meta?.readonly}
      />
      <Flex gap="4px 0" wrap>
        {tags?.map((tag, index) => {
          //   if (editInputIndex === index) {
          //     return (
          //       <Input
          //         ref={editInputRef}
          //         key={tag}
          //         value={editInputValue}
          //         onChange={handleEditInputChange}
          //         onBlur={handleEditInputConfirm}
          //         onPressEnter={handleEditInputConfirm}
          //       />
          //     );
          //   }
          const isLongTag = tag.length > 20;
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            <Tag
              key={tag}
              closable={index !== -1}
              onClose={() => handleClose(tag)}
              className="font-normal"
            >
              <span
              // onDoubleClick={(e) => {
              //   setEditInputIndex(index);
              //   setEditInputValue(tag);
              //   e.preventDefault();
              // }}
              >
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </span>
            </Tag>
          );
        })}
      </Flex>
    </div>
  );
};

export default CustomTags;
