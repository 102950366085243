import React, { useEffect, useId, useState } from "react";
import {
  Form,
  Row,
  Col,
  Checkbox,
  Flex,
  Input,
  Button,
  Drawer,
  notification,
} from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { themes } from "../../../config";
import { BsInfoCircle } from "react-icons/bs";
import LeftSideTitlePart from "../../HeaderParts/LeftSideTitlePart";
import IconsAll from "../../IconsAll";
import RightSIdeTitlePart from "../../HeaderParts/RightSIdeTitlePart";
import axiosInstance from "../../../helpers/axios";
import Apis from "../../../helpers/Apis";
import { store } from "../../../Redux/store";
import { IoLanguage } from "react-icons/io5";
import { DndContext } from "@dnd-kit/core";
import { TableSort } from "../../../Pages/Instellingen/ChangeDataModalCollection";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { MenuOutlined } from "@ant-design/icons";
import CreateChoices from "../formFieldsComponents/CreateChoices";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { AiOutlineClose } from "react-icons/ai";

const FormStyling = styled(Form)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.colorGrayrBg};
  border-top: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  border-bottom: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  padding: 34px;
`;

const InvertedCheckBox = styled(Checkbox)`
  border-radius: 12px;
  border: 1px solid
    ${() =>
      themes[useSelector((state) => state.theme.name)].components.Input
        .colorBorder};
  background: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Input
      .colorBgContainer};
  padding: 1rem;
  width: 100%;
  font-weight: 400;
`;

const DrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: white;
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    padding: 0;
    .ant-tabs-nav {
      padding-top: 40px;
      width: min(100%, 200px);
      min-height: 100vh;
      background-color: #f5f6f7;
      .ant-tabs-nav-wrap {
        &:before,
        &:after,
        .ant-tabs-ink-bar {
          display: none;
        }
        .ant-tabs-nav-list {
          padding-inline: 10px;
          .ant-tabs-tab {
            border-radius: 8px;
            margin-top: 5px;
            > div {
              color: #000 !important;
              font-size: 16px;
              font-style: normal;
            }
            &.ant-tabs-tab-active {
              background-color: #ebeced;
            }
          }
        }
      }
    }

    .ant-tabs-content-holder {
      padding-top: 40px;
      .ant-tabs-tabpane {
        padding-right: 24px;
      }
    }
  }
`;

const NotHasField = styled(Flex)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.NotHasFieldClr};
  border-left: 6px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.brandColor};
  padding-inline: 15px;
  border-radius: 6px;
  min-height: 56px;
`;

export const hasNewFieldFunc = () => {
  return (
    <NotHasField className="items-center gap-3">
      <BsInfoCircle size={20} />
      <p className="font-normal">No Items</p>
    </NotHasField>
  );
};

const TableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS?.Transform?.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return React.cloneElement(child, { key: child.key || index });
      })}
    </tr>
  );
};

const UpdateField = ({
  activeItem,
  setIsDrawerOpen,
  dataSource,
  setActiveItem,
  init,
}) => {
  const { t } = useTranslation();
  const id = useId();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [translationChoices, setTranslationChoices] = useState([]);
  const [activeChoice, setActiveChoice] = useState(null);

  useEffect(() => {
    setFormData();
  }, [activeItem?._id]);

  const setFormData = async () => {
    await form.setFieldsValue({
      required: !!activeItem?.meta?.required,
      readonly: !!activeItem?.meta?.readonly,
      hidden: !!activeItem?.meta?.hidden,
      note: activeItem?.meta?.note,
    });
    if (
      activeItem?.meta?.translations &&
      activeItem?.meta?.translations?.length
    )
      setTranslationChoices(
        activeItem?.meta?.translations?.map((t) => {
          return {
            label: t?.translation,
            value: t?.language,
          };
        }),
      );
  };

  useEffect(() => {
    setTranslationChoices([]);
  }, [activeItem?._id]);

  const onDragEnd = ({ active, over }) => {
    if (active?.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i?.key === active?.id);
        const overIndex = previous?.findIndex((i) => i?.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const finalValues = {
        ...activeItem,
        meta: {
          ...activeItem?.meta,
          required: values.required,
          readonly: values.readonly,
          hidden: values.hidden,
          note: values?.note,
          translations: translationChoices?.map((t) => {
            return { translation: t?.label, language: t?.value };
          }),
        },
      };
      const { _id, ...payload } = finalValues;
      delete payload?.__v;

      const resp = await axiosInstance.put(Apis.UPDATE_A_FIELD(_id), payload, {
        headers: {
          "x-tenant-id": store.getState().user.userId,
        },
      });
      if (resp.status === 200) {
        setIsDrawerOpen(false);
        setActiveItem(null);
        init();
        notification.success({ message: "Field updated successfully" });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const columns = [
    {
      key: "sort",
      width: 50,
    },
    {
      dataIndex: "label",
      align: "left",
      width: "auto",
      render: (record, rowData, index) => {
        return (
          <div
            onClick={() => {
              setActiveChoice({ ...rowData, index });
              setOpen(true);
            }}
          >
            {record}
          </div>
        );
      },
    },
    {
      dataIndex: "value",
      align: "left",
      width: "auto",
      render: (record, rowData, index) => {
        return (
          <div
            onClick={() => {
              setActiveChoice({ ...rowData, index });
              setOpen(true);
            }}
          >
            {record}
          </div>
        );
      },
    },
    {
      dataIndex: "value",
      key: "delete",
      width: 50,
      render: (record) => {
        return (
          <div
            onClick={() =>
              setTranslationChoices((prev) => {
                return prev?.filter((t) => t?.value !== record);
              })
            }
          >
            <AiOutlineClose size={24} />
          </div>
        );
      },
    },
  ];

  return (
    <Flex wrap="wrap" align="center" justify="space-between">
      <LeftSideTitlePart
        pageTitle={
          activeItem?.meta?.interface.charAt(0).toUpperCase() +
          activeItem?.meta?.interface.slice(1) +
          " " +
          activeItem?.type +
          " (" +
          dataSource?.collection_id +
          ")"
        }
        pageIcon={true}
        pageInnerIcon={<IconsAll.ContentIcon strokeColor={"#3586c7"} />}
      />
      <RightSIdeTitlePart showButton={true} handleShowClick={handleSubmit} />
      <FormStyling
        form={form}
        layout="vertical"
        initialValues={{
          type: "String",
          icon_left: "Icon 1",
          icon_right: "Icon 1",
        }}
        className="mt-4"
      >
        <Row gutter={28}>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              label={t("Readonly")}
              htmlFor={`${id}-Readonly`}
              className="font-bold"
              name={`readonly`}
              valuePropName="checked"
            >
              <InvertedCheckBox id={`${id}-Readonly`}>
                Disable editing value
              </InvertedCheckBox>
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              label={t("Required")}
              htmlFor={`${id}-required`}
              className="font-bold"
              name={`required`}
              valuePropName={"checked"}
            >
              <InvertedCheckBox id={`${id}-required`}>
                Require value to be set on creation
              </InvertedCheckBox>
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              label={t("Hidden")}
              htmlFor={`${id}-hidden`}
              className="font-bold"
              name={`hidden`}
              valuePropName={"checked"}
            >
              <InvertedCheckBox id={`${id}-hidden`}>
                Hidden on Detail
              </InvertedCheckBox>
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={24}>
            <Form.Item
              label={t("Note")}
              className="font-bold"
              htmlFor={`${id}-template`}
              name={`note`}
            >
              <Input
                placeholder={t("Add a helpful note for users")}
                id={`${id}-template`}
                className="min-h-14 rounded-xl font-normal px-6"
                suffix={<IoLanguage size={20} color="gray" />}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={24}>
            <Form.Item
              label={t("Field Name Translations")}
              htmlFor={`${id}-new-field`}
              className="font-bold"
            >
              {!translationChoices?.length && hasNewFieldFunc()}
              <Button
                className="rounded-3xl  border border-solid border-grey-full bg-grey-full text-black px-5 py-3 mt-4 font-medium h-auto"
                onClick={() => setOpen(true)}
              >
                Create New
              </Button>
            </Form.Item>
            {translationChoices?.length > 0 && (
              <DndContext
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={onDragEnd}
              >
                <SortableContext
                  items={translationChoices?.map((t) => t?.label)}
                  strategy={verticalListSortingStrategy}
                >
                  <TableSort
                    style={{ width: "100%" }}
                    pagination={false}
                    components={{
                      body: {
                        row: TableRow,
                      },
                    }}
                    rowKey={(record) =>
                      record?.label + record?.value + new Date().toISOString()
                    }
                    columns={columns}
                    dataSource={translationChoices}
                    scroll={{
                      x: 300,
                    }}
                  />
                </SortableContext>
              </DndContext>
            )}
          </Col>
        </Row>
        <DrawerStyle
          width={870}
          // title="Basic Drawer"
          onClose={() => {
            setOpen(false);
            setActiveChoice(null);
          }}
          destroyOnClose
          open={open}
        >
          <CreateChoices
            setChoices={setTranslationChoices}
            setOpen={setOpen}
            choiceType={"translation"}
            activeChoice={activeChoice}
          />
        </DrawerStyle>
      </FormStyling>
    </Flex>
  );
};

export default UpdateField;
