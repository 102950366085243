import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Flex, notification, Spin } from "antd";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import { SidebarContext } from "../../Context/submenuContext";
import { tabletBreakpoint } from "../../helpers/utils";
import Backdrop from "../../Layouts/Backdrop";
import useScreenWidth from "../../Common/Hooks/ScreenWidth";
import withRouter from "../../Common/withRouter";
import styled from "styled-components";
import { themes } from "../../config";
import { useSelector, useDispatch } from "react-redux";
import { BsInfoCircle } from "react-icons/bs";
// import GoogleMapReact from "google-map-react";
import DynamicForm from "../../Components/DynamicForm/DynamicForm";
import axiosInstance from "../../helpers/axios";
import Apis from "../../helpers/Apis";
import { store } from "../../Redux/store";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { JoiValidateData } from "../../helpers/CreateJoiSchema";
import { flatten, unflatten } from "flat";

const FormStyling = styled(Form)`
  background-color: transparent;
  border-top: 0;
  border-bottom: 0;
`;

const Loader = styled(Flex)`
  height: 100vh;
  width: 100vw;
  position: fixed;
  inset: 0;
  display: grid;
`;

const NotHasField = styled(Flex)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.NotHasFieldClr};
  border-left: 6px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.brandColor};
  padding-inline: 15px;
  border-radius: 6px;
  min-height: 56px;
`;

export const hasNewFieldFunc = () => {
  return (
    <NotHasField className="items-center gap-3">
      <BsInfoCircle size={20} />
      <p className="font-normal">No items</p>
    </NotHasField>
  );
};

const AddContent = ({ router }) => {
  const submenu = useContext(SidebarContext);
  const screenWidth = useScreenWidth();
  const isTabletOrSmaller = screenWidth <= tabletBreakpoint;
  const isSubMenuActive = submenu?.activatedItem === "add-user";
  const path = location.pathname.split("/");
  const params = useParams();
  const [collectionData, setCollectionData] = useState(null);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const init = async () => {
    try {
      const resp = await axiosInstance.get(Apis.GET_A_SCHEMA(params.table), {
        headers: {
          "x-tenant-id": store.getState().user.userId,
        },
      });
      if (resp.status === 200) setCollectionData(resp.data.payload.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    init();
    if (path[3] === "edit") {
      getAndSetFormData();
    }
  }, []);

  const getAndSetFormData = async () => {
    setLoader(true);
    try {
      const resp = await axiosInstance.get(
        Apis.GET_SINGLE_CONTENT(params.table, params.id),
        {
          headers: {
            "x-tenant-id": store.getState().user.userId,
          },
        },
      );
      if (resp.status === 200) {
        await setFormData(flatten(resp.data.payload.data, { safe: true }));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  const handleSubmit = async () => {
    let updateFields = ["_id", "created_at", "updated_at"];
    const finalFormData = unflatten(
      Object.fromEntries(
        Object.entries(formData).filter(
          ([key]) =>
            !updateFields?.includes(key) &&
            new Set(collectionData?.fields?.map((f) => f?.path)).has(key),
        ),
      ),
    );

    const { error, status } = await JoiValidateData({
      data: finalFormData,
      fields: collectionData?.fields,
    });
    if (!status && error) {
      console.log("error", error);
      setValidationErrors(error);
      return;
    }

    if (path[3] !== "edit") {
      try {
        const resp = await axiosInstance.post(
          Apis.ADD_CONTENT(collectionData?._id),
          {
            contentData: finalFormData,
            collectionName: collectionData?.collection_id,
          },
          {
            headers: {
              "x-tenant-id": store.getState().user.userId,
            },
          },
        );
        if (resp.status === 200) {
          notification.success({ message: "Content Added Successfully" });
          router.navigate(`/content/${params.table}`),
            dispatch(
              setActiveTab({
                page: path[1],
                tab: collectionData?.collection_id,
                redirectionLink: `/content/${params.table}`,
              }),
            );
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      try {
        const resp = await axiosInstance.put(
          Apis.UPDATE_CONTENT(params.table, params.id),
          {
            fields: collectionData?.fields,
            contentData: finalFormData,
            collectionName: collectionData?.collection_id,
          },
          {
            headers: {
              "x-tenant-id": store.getState().user.userId,
            },
          },
        );
        if (resp.status === 200) {
          notification.success({ message: "Content Updated Successfully" });
          router.navigate(`/content/${params.table}`),
            dispatch(
              setActiveTab({
                page: path[1],
                tab: collectionData?.collection_id,
                redirectionLink: `/content/${params.table}`,
              }),
            );
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  return (
    <Flex className="w-full main-content-wrap">
      {isSubMenuActive && isTabletOrSmaller && (
        <Backdrop onClick={() => submenu.toggleActivation()} />
      )}
      <div className="px-4 xl:px-16 w-full left-part ">
        <div className="flex flex-wrap md:items-center justify-between mt-9 mb-11">
          <div
            onClick={() => {
              router.navigate(`/content/${params.table}`),
                dispatch(
                  setActiveTab({
                    page: path[1],
                    tab: collectionData?.collection_id,
                    redirectionLink: `/content/${params.table}`,
                  }),
                );
            }}
          >
            <LeftSideTitlePart
              pageTitle={`Creating items in ${collectionData?.collection_id}`}
              pageSubTitle={collectionData?.collection_id}
              backButton={true}
            />
          </div>
          <RightSIdeTitlePart
            showButton={true}
            handleShowClick={handleSubmit}
          />
        </div>

        <section className="container max-w-4xl">
          {loader ? (
            <Loader align="center" gap="middle">
              <Spin
                indicator={
                  <LoadingOutlined spin style={{ fontSize: "32px" }} />
                }
                size="default"
              />
            </Loader>
          ) : (
            <FormStyling layout="vertical" form={form}>
              <Row gutter={16}>
                <DynamicForm
                  form={collectionData?.fields
                    ?.filter((f) => !f?.meta?.hidden)
                    ?.sort((a, b) => a?.meta?.sort - b?.meta?.sort)}
                  setFormData={setFormData}
                  formData={formData}
                  validationErrors={validationErrors}
                />
              </Row>
            </FormStyling>
          )}
        </section>
      </div>
    </Flex>
  );
};

export default withRouter(AddContent);
