import standaardImg from "./Assets/images/Icon/Standaard-theme.svg";
import noxStandaardImg from "./Assets/images/Icon/NOX-Standaard-theme.svg";
import noxMinimal from "./Assets/images/Icon/NOX-Minimal-theme.svg";
import noxClrMatch from "./Assets/images/Icon/NOX-Color-Match-theme.svg";
import darkStandaardImg from "./Assets/images/Icon/Dark-Standaard-theme.svg";
import darkNoxStandaardImg from "./Assets/images/Icon/Dark-NOX-Standaard-theme.svg";

const themes = {
  standard: {
    // name: "standard",
    name: "standard",
    key: "light_standard_theme",
    type: "light",
    img: standaardImg,
    companyLogoHeight: "16",
    direction: "ltr",
    token: {
      //colors Palette
      themeLayoutBg: "#ffffff",
      headerLogoBg: "#0C2340",

      grayClr: "#88909A",
      toolbarButton: "#ECEDEF",
      toolbarButtonIconColor: "#3B4655",

      //color
      colorSuccessBg: "#ecfff3",
      colorInfoBg: "#dbeff3",
      colorWarningBg: "#fffbe6",
      colorDangerBg: "#fff1f0",
      colorYellowBg: "#fffbe6",
      colorGrayrBg: "#f6f7f8",
      colorPurpleBg: "#e7dffe",
      colorRedBg: "#fff0f0",
      colorLightBlue: "#ecf3ff",

      colorText: "#08182C",
      colorTextDescription: "blue",

      gebruikersTableSortBg: "rgba(255, 255, 255, 0.92)",

      NotHasFieldClr: "#ecf3ff",

      // Spacing and sizes
      controlHeight: 35,

      // Font Family
      fontFamily: "Lexend",
    },
    components: {
      Menu: {
        headerBgColor: "#F5F6F7",
        navBrandBg: "#fff",
        verticalSidebarWidth: 260,
        itemBg: "#08182C",
        itemClr: "#08182C",
        itemBgHover: "#274974",
        itemColor: "#08182C",
        itemActiveBg: "#EBECED",
        itemHoverColor: "#08182C",
        subMenuItemBg: "#ffffff",
        itemSelectedBg: "#EBECED",
        menuBorderColor: "#eff2f7",
        sidebarNavTextClr: "#88909A",
      },
      Table: {
        tableBg: "#FFFFFF",
        headerBg: "#fff",
        tableHeadBorderColor: "#899099",
        bgColorOddRow: "#fbfbfc",
        borderColor: "rgba(225, 227, 230, 1)",
        sortedBg: "#F5F6F7",
        fontSize: "16px",
        fontWeightStrong: "500",
        imgLocationClr: "#394656",
        publishedDotClr: "#69CAA7",
        rowHoverBg: "rgba(0, 0, 0, .05)",
      },
      Pagination: {
        borderRadius: 8,
        itemBg: "#F5F6F7",
        itemActiveBg: "#11243F",
        colorPrimary: "#FFFFFF",
        colorPrimaryHover: "#ffffff",
      },
      Radio: {
        buttonBg: "#ffffff",
        buttonCheckedBg: "#ffffff",
        buttonCheckedBgDisabled: "rgba(0, 0, 0, 0.1)",
        buttonCheckedColorDisabled: "rgba(0, 0, 0, 0.50)",
        buttonColor: "rgba(0, 0, 0, 0.88)",
        buttonPaddingInline: 15,
        buttonSolidCheckedColor: "#fff",
        dotColorDisabled: "rgba(0, 0, 0, 0.25)",
        dotSize: 8,
        radioSize: 16,
        wrapperMarginInlineEnd: 8,
      },
      Select: {
        borderRadius: 12,
        selectorBg: "#ffffff",
        colorIcon: "#E0364E",
        // multipleItemHeight: 36,
        controlHeight: 56,
        colorBgContainer: "#ffffff",
      },
      SideBarDivider: {
        borderColor: "#F5F6F7",
        opacity: 0.4,
      },
      SideBarUserProfile: {
        size: "40px",
      },
      Divider: {
        borderColor: "#E1E3E6",
      },
      Input: {
        colorBgContainer: "#FFF",
        colorBorder: "#E1E3E6",
        colorTextPlaceholder: "#88909A",
      },
      HeaderButtonsStyle: {
        // folder Button Style
        folderButtonBg: "#F5F6F7",
        folderButtonClr: "#0C2340",
        // Hover Style
        folderButtonHoverBg: "#D3E7FB",
        folderButtonHoverClr: "#4096ff",

        // Add Button Style
        addButtonBg: "#0C2340",
        addButtonClr: "#FFFFFF",
        // Hover Style
        addButtonHoverBg: "#D3E7FB",
        addButtonHoverClr: "#4096ff",

        // delete Button Style
        deleteButtonBg: "rgba(224, 54, 78, 0.08)",
        deleteButtonClr: "#E0364E",
        // Hover Style
        deleteButtonHoverBg: "#E0364E",
        deleteButtonHoverClr: "#fff",

        // Submit Button Style
        submitButtonBg: "#69CAA7",
        submitButtonClr: "#08182C",
        // Hover Style
        submitButtonHoverBg: "#D6FFF0",
        submitButtonHoverClr: "#08182C",

        // disabled Submit Button Style
        disabledSubmitButtonBg: "#F5F6F7",
        disabledSubmitButtonClr: "#E1E3E6",
        // Hover Style
        disabledSubmitButtonHoverBg: "#D6FFF0",
        disabledSubmitButtonHoverClr: "#08182C",

        // Archive Button Style
        archiveButtonBg: "#F5F6F7",
        archiveButtonClr: "#0C2340",
        // Hover Style
        archiveButtonHoverBg: "#D6FFF0",
        archiveButtonHoverClr: "#08182C",

        // back button Style
        backButtonBg: "rgba(225, 227, 230, 0.24);",
        backButtonBorder: "rgba(12, 35, 64, 0.24);",
        backButtonClr: "#08182C",
      },
      PersonInfoCard: {
        cardBg: "#F5F6F7",
      },
      SystemCollectionButtonStyle: {
        btnBg: "#f5f6f7",
        borderClr: "#e1e3e6",
        BtnTextClr: "#88909A",
      },
      Upload: {
        colorPrimary: "#f5f6f7",
      },
      Tree: {
        colorBgContainer: "#ffffff",
      },
    },
  },

  /* <============ NOX Standard Color Properties ============> */
  noxStandard: {
    name: "noxStandard",
    key: "light_nox_standard_theme",
    type: "light",
    img: noxStandaardImg,
    companyLogoHeight: "16",
    direction: "ltr",
    token: {
      //colors Palette
      themeLayoutBg: "#ffffff",
      headerLogoBg: "#0C2340",

      grayClr: "#88909A",
      toolbarButton: "#ECEDEF",
      toolbarButtonIconColor: "#3B4655",

      //color
      colorSuccessBg: "#ecfff3",
      colorInfoBg: "#dbeff3",
      colorWarningBg: "#fffbe6",
      colorDangerBg: "#fff1f0",
      colorYellowBg: "#fffbe6",
      colorGrayrBg: "#f6f7f8",
      colorPurpleBg: "#e7dffe",
      colorRedBg: "#fff0f0",
      colorLightBlue: "#ecf3ff",

      colorText: "#08182C",
      colorTextDescription: "blue",

      gebruikersTableSortBg: "rgba(255, 255, 255, 0.92)",

      // Spacing and sizes
      controlHeight: 35,

      // Font Family
      fontFamily: "Lexend",
    },
    components: {
      Menu: {
        headerBgColor: "#F5F6F7",
        navBrandBg: "#F5F6F7",
        verticalSidebarWidth: 260,
        itemBg: "#08182C",
        itemClr: "#08182C",
        itemBgHover: "#274974",
        itemColor: "#08182C",
        itemActiveBg: "#EBECED",
        itemHoverColor: "#08182C",
        subMenuItemBg: "#ffffff",
        itemSelectedBg: "#EBECED",
        menuBorderColor: "#eff2f7",
        sidebarNavTextClr: "#88909A",
      },
      Table: {
        tableBg: "#FFFFFF",
        headerBg: "#fff",
        tableHeadBorderColor: "#899099",
        bgColorOddRow: "#fbfbfc",
        borderColor: "rgba(225, 227, 230, 1)",
        sortedBg: "#F5F6F7",
        fontSize: "16px",
        fontWeightStrong: "500",
        imgLocationClr: "#394656",
        publishedDotClr: "#69CAA7",
        rowHoverBg: "rgba(0, 0, 0, .05)",
      },
      Pagination: {
        borderRadius: 8,
        itemBg: "#F5F6F7",
        itemActiveBg: "#11243F",
        colorPrimary: "#FFFFFF",
        colorPrimaryHover: "#ffffff",
      },
      Radio: {
        buttonBg: "#ffffff",
        buttonCheckedBg: "#ffffff",
        buttonCheckedBgDisabled: "rgba(0, 0, 0, 0.1)",
        buttonCheckedColorDisabled: "rgba(0, 0, 0, 0.50)",
        buttonColor: "rgba(0, 0, 0, 0.88)",
        buttonPaddingInline: 15,
        buttonSolidCheckedColor: "#fff",
        dotColorDisabled: "rgba(0, 0, 0, 0.25)",
        dotSize: 8,
        radioSize: 16,
        wrapperMarginInlineEnd: 8,
      },
      Select: {
        borderRadius: 12,
        selectorBg: "#ffffff",
        colorIcon: "#E0364E",
        // multipleItemHeight: 36,
        controlHeight: 56,
        colorBgContainer: "#ffffff",
      },
      SideBarDivider: {
        borderColor: "#F5F6F7",
        opacity: 0.4,
      },
      SideBarUserProfile: {
        size: "40px",
      },
      Divider: {
        borderColor: "#E1E3E6",
      },
      Input: {
        colorBgContainer: "#FFF",
        colorBorder: "#E1E3E6",
        colorTextPlaceholder: "#88909A",
      },
      HeaderButtonsStyle: {
        // folder Button Style
        folderButtonBg: "#F5F6F7",
        folderButtonClr: "#0C2340",
        // Hover Style
        folderButtonHoverBg: "#D3E7FB",
        folderButtonHoverClr: "#4096ff",

        // Add Button Style
        addButtonBg: "#0C2340",
        addButtonClr: "#FFFFFF",
        // Hover Style
        addButtonHoverBg: "#D3E7FB",
        addButtonHoverClr: "#4096ff",

        // delete Button Style
        deleteButtonBg: "rgba(224, 54, 78, 0.08)",
        deleteButtonClr: "#E0364E",
        // Hover Style
        deleteButtonHoverBg: "#E0364E",
        deleteButtonHoverClr: "#fff",

        // Submit Button Style
        submitButtonBg: "#69CAA7",
        submitButtonClr: "#08182C",
        // Hover Style
        submitButtonHoverBg: "#D6FFF0",
        submitButtonHoverClr: "#08182C",

        // disabled Submit Button Style
        disabledSubmitButtonBg: "#F5F6F7",
        disabledSubmitButtonClr: "#E1E3E6",
        // Hover Style
        disabledSubmitButtonHoverBg: "#D6FFF0",
        disabledSubmitButtonHoverClr: "#08182C",

        // Archive Button Style
        archiveButtonBg: "#F5F6F7",
        archiveButtonClr: "#0C2340",
        // Hover Style
        archiveButtonHoverBg: "#D6FFF0",
        archiveButtonHoverClr: "#08182C",

        // back button Style
        backButtonBg: "rgba(225, 227, 230, 0.24);",
        backButtonBorder: "rgba(12, 35, 64, 0.24);",
        backButtonClr: "#08182C",
      },
      PersonInfoCard: {
        cardBg: "#F5F6F7",
      },
      SystemCollectionButtonStyle: {
        btnBg: "#f5f6f7",
        borderClr: "#e1e3e6",
        BtnTextClr: "#88909A",
      },
      Upload: {
        colorPrimary: "#f5f6f7",
      },
      Tree: {
        colorBgContainer: "#ffffff",
      },
    },
  },

  /* <============ Minimal Color Properties ============> */
  minimal: {
    name: "minimal",
    key: "light_nox_minimal_theme",
    type: "light",
    img: noxMinimal,
    companyLogoHeight: "16",
    direction: "ltr",
    token: {
      //colors Palette
      themeLayoutBg: "#ffffff",
      headerLogoBg: "#0C2340",

      grayClr: "#88909A",
      toolbarButton: "#ECEDEF",
      toolbarButtonIconColor: "#3B4655",

      //color
      colorSuccessBg: "#ecfff3",
      colorInfoBg: "#dbeff3",
      colorWarningBg: "#fffbe6",
      colorDangerBg: "#fff1f0",
      colorYellowBg: "#fffbe6",
      colorGrayrBg: "#f6f7f8",
      colorPurpleBg: "#e7dffe",
      colorRedBg: "#fff0f0",
      colorLightBlue: "#ecf3ff",

      colorText: "#08182C",
      colorTextDescription: "blue",

      gebruikersTableSortBg: "rgba(255, 255, 255, 0.92)",

      // Spacing and sizes
      controlHeight: 35,

      // Font Family
      fontFamily: "Lexend",
    },
    components: {
      Menu: {
        headerBgColor: "#FAFAFA",
        navBrandBg: "#F6F6F6",
        verticalSidebarWidth: 260,
        itemBg: "#fff",
        itemClr: "#08182C",
        itemBgHover: "#274974",
        itemColor: "#08182C",
        itemActiveBg: "#EBECED",
        itemHoverColor: "#08182C",
        subMenuItemBg: "#ffffff",
        itemSelectedBg: "#F5F5F5",
        menuBorderColor: "#eff2f7",
        sidebarNavTextClr: "#88909A",
      },
      Table: {
        tableBg: "#FFFFFF",
        headerBg: "#fff",
        tableHeadBorderColor: "#899099",
        bgColorOddRow: "#fbfbfc",
        borderColor: "rgba(225, 227, 230, 1)",
        sortedBg: "#F5F6F7",
        fontSize: "16px",
        fontWeightStrong: "500",
        imgLocationClr: "#394656",
        publishedDotClr: "#69CAA7",
        rowHoverBg: "rgba(0, 0, 0, .05)",
      },
      Pagination: {
        borderRadius: 8,
        itemBg: "#F5F6F7",
        itemActiveBg: "#11243F",
        colorPrimary: "#FFFFFF",
        colorPrimaryHover: "#ffffff",
      },
      Radio: {
        buttonBg: "#ffffff",
        buttonCheckedBg: "#ffffff",
        buttonCheckedBgDisabled: "rgba(0, 0, 0, 0.1)",
        buttonCheckedColorDisabled: "rgba(0, 0, 0, 0.50)",
        buttonColor: "rgba(0, 0, 0, 0.88)",
        buttonPaddingInline: 15,
        buttonSolidCheckedColor: "#fff",
        dotColorDisabled: "rgba(0, 0, 0, 0.25)",
        dotSize: 8,
        radioSize: 16,
        wrapperMarginInlineEnd: 8,
      },
      Select: {
        borderRadius: 12,
        selectorBg: "#ffffff",
        colorIcon: "#E0364E",
        // multipleItemHeight: 36,
        controlHeight: 56,
        colorBgContainer: "#FFFFFF",
        colorBorder: "#E1E3E6",
        colorPrimary: "#88909A",
        colorPrimaryHover: "#88909A",
      },
      SideBarDivider: {
        borderColor: "#F5F6F7",
        opacity: 0.4,
      },
      SideBarUserProfile: {
        size: "40px",
      },
      Divider: {
        borderColor: "#E1E3E6",
      },
      Input: {
        colorBgContainer: "#FFFFFF",
        colorBorder: "#E1E3E6",
        colorTextPlaceholder: "#88909A",
        activeBorderColor: "#88909A",
        hoverBorderColor: "#88909A",
      },
      HeaderButtonsStyle: {
        // folder Button Style
        folderButtonBg: "#F5F6F7",
        folderButtonClr: "#0C2340",
        // Hover Style
        folderButtonHoverBg: "#D3E7FB",
        folderButtonHoverClr: "#4096ff",

        // Add Button Style
        addButtonBg: "#0C2340",
        addButtonClr: "#FFFFFF",
        // Hover Style
        addButtonHoverBg: "#D3E7FB",
        addButtonHoverClr: "#4096ff",

        // delete Button Style
        deleteButtonBg: "rgba(224, 54, 78, 0.08)",
        deleteButtonClr: "#E0364E",
        // Hover Style
        deleteButtonHoverBg: "#E0364E",
        deleteButtonHoverClr: "#fff",

        // Submit Button Style
        submitButtonBg: "#69CAA7",
        submitButtonClr: "#08182C",
        // Hover Style
        submitButtonHoverBg: "#D6FFF0",
        submitButtonHoverClr: "#08182C",

        // disabled Submit Button Style
        disabledSubmitButtonBg: "#F5F6F7",
        disabledSubmitButtonClr: "#E1E3E6",
        // Hover Style
        disabledSubmitButtonHoverBg: "#D6FFF0",
        disabledSubmitButtonHoverClr: "#08182C",

        // Archive Button Style
        archiveButtonBg: "#F5F6F7",
        archiveButtonClr: "#0C2340",
        // Hover Style
        archiveButtonHoverBg: "#D6FFF0",
        archiveButtonHoverClr: "#08182C",

        // back button Style
        backButtonBg: "rgba(225, 227, 230, 0.24);",
        backButtonBorder: "rgba(12, 35, 64, 0.24);",
        backButtonClr: "#08182C",
      },
      PersonInfoCard: {
        cardBg: "#F5F6F7",
      },
      SystemCollectionButtonStyle: {
        btnBg: "#f5f6f7",
        borderClr: "#e1e3e6",
        BtnTextClr: "#88909A",
      },
      Upload: {
        colorPrimary: "#f5f6f7",
      },
      Tree: {
        colorBgContainer: "#ffffff",
      },
    },
  },

  /* <============ Color Match Color Properties ============> */
  colorMatch: {
    name: "colorMatch",
    key: "light_nox_color_match_theme",
    type: "light",
    img: noxClrMatch,
    companyLogoHeight: "16",
    direction: "ltr",
    token: {
      //colors Palette
      themeLayoutBg: "#D2E9EC",
      headerLogoBg: "#5CB4C0",

      grayClr: "#88909A",
      toolbarButton: "#ECEDEF",
      toolbarButtonIconColor: "#3B4655",

      //color
      colorSuccessBg: "#ecfff3",
      colorInfoBg: "#dbeff3",
      colorWarningBg: "#fffbe6",
      colorDangerBg: "#fff1f0",
      colorYellowBg: "#fffbe6",
      colorGrayrBg: "#f6f7f8",
      colorPurpleBg: "#e7dffe",
      colorRedBg: "#fff0f0",
      colorLightBlue: "#ecf3ff",

      colorText: "#08182C",
      colorTextDescription: "blue",

      gebruikersTableSortBg: "rgba(255, 255, 255, 0.92)",

      // Spacing and sizes
      controlHeight: 35,

      // Font Family
      fontFamily: "Lexend",
    },
    components: {
      Menu: {
        headerBgColor: "#BCE6EB",
        navBrandBg: "#C0ECF1",
        verticalSidebarWidth: 260,
        itemBg: "#5CB4C0",
        itemClr: "#08182C",
        itemBgHover: "#274974",
        itemColor: "#08182C",
        itemActiveBg: "#EBECED",
        itemHoverColor: "#08182C",
        subMenuItemBg: "#ffffff",
        itemSelectedBg: "#DCF2F5",
        menuBorderColor: "#eff2f7",
        sidebarNavTextClr: "#fff",
      },
      Table: {
        tableBg: "#c4e5ea",
        headerBg: "#b3dae0",
        tableHeadBorderColor: "#899099",
        bgColorOddRow: "#b3dae0",
        borderColor: "rgba(0, 0, 0, .05)",
        sortedBg: "#c4e5ea",
        fontSize: "16px",
        fontWeightStrong: "500",
        imgLocationClr: "#394656",
        publishedDotClr: "#69CAA7",
        rowHoverBg: "rgba(1, 1, 1, .05)",
      },
      Pagination: {
        borderRadius: 8,
        itemBg: "#F5F6F7",
        itemActiveBg: "#11243F",
        colorPrimary: "#FFFFFF",
        colorPrimaryHover: "#ffffff",
      },
      Radio: {
        buttonBg: "#ffffff",
        buttonCheckedBg: "#ffffff",
        buttonCheckedBgDisabled: "rgba(0, 0, 0, 0.1)",
        buttonCheckedColorDisabled: "rgba(0, 0, 0, 0.50)",
        buttonColor: "rgba(0, 0, 0, 0.88)",
        buttonPaddingInline: 15,
        buttonSolidCheckedColor: "#fff",
        dotColorDisabled: "rgba(0, 0, 0, 0.25)",
        dotSize: 8,
        radioSize: 16,
        wrapperMarginInlineEnd: 8,
      },
      Select: {
        borderRadius: 12,
        selectorBg: "#ffffff",
        colorIcon: "#E0364E",
        // multipleItemHeight: 36,
        controlHeight: 56,
        colorBgContainer: "#FFFFFF",
        colorBorder: "#E1E3E6",
        colorPrimary: "#88909A",
        colorPrimaryHover: "#88909A",
      },
      SideBarDivider: {
        borderColor: "#F5F6F7",
        opacity: 0.4,
      },
      SideBarUserProfile: {
        size: "40px",
      },
      Divider: {
        borderColor: "#fff",
      },
      Input: {
        colorBgContainer: "#9DCED4",
        colorBorder: "#E1E3E6",
        colorTextPlaceholder: "#08182C",
        activeBorderColor: "#FFFFFF",
        hoverBorderColor: "#FFFFFF",
      },
      HeaderButtonsStyle: {
        // folder Button Style
        folderButtonBg: "#F5F6F7",
        folderButtonClr: "#0C2340",
        // Hover Style
        folderButtonHoverBg: "#D3E7FB",
        folderButtonHoverClr: "#4096ff",

        // Add Button Style
        addButtonBg: "#0C2340",
        addButtonClr: "#FFFFFF",
        // Hover Style
        addButtonHoverBg: "#D3E7FB",
        addButtonHoverClr: "#4096ff",

        // delete Button Style
        deleteButtonBg: "rgba(224, 54, 78, 0.08)",
        deleteButtonClr: "#E0364E",
        // Hover Style
        deleteButtonHoverBg: "#E0364E",
        deleteButtonHoverClr: "#fff",

        // Submit Button Style
        submitButtonBg: "#69CAA7",
        submitButtonClr: "#08182C",
        // Hover Style
        submitButtonHoverBg: "#D6FFF0",
        submitButtonHoverClr: "#08182C",

        // disabled Submit Button Style
        disabledSubmitButtonBg: "#F5F6F7",
        disabledSubmitButtonClr: "#E1E3E6",
        // Hover Style
        disabledSubmitButtonHoverBg: "#D6FFF0",
        disabledSubmitButtonHoverClr: "#08182C",

        // Archive Button Style
        archiveButtonBg: "#F5F6F7",
        archiveButtonClr: "#0C2340",
        // Hover Style
        archiveButtonHoverBg: "#D6FFF0",
        archiveButtonHoverClr: "#08182C",

        // back button Style
        backButtonBg: "rgba(225, 227, 230, 0.24);",
        backButtonBorder: "rgba(12, 35, 64, 0.24);",
        backButtonClr: "#08182C",
      },
      PersonInfoCard: {
        cardBg: "#9DCED4",
      },
      SystemCollectionButtonStyle: {
        btnBg: "#f5f6f7",
        borderClr: "#e1e3e6",
        BtnTextClr: "#88909A",
      },
      Upload: {
        colorPrimary: "#f5f6f7",
      },
      Tree: {
        colorBgContainer: "#9DCED4",
      },
      DatePicker: {
        colorBgContainer: "#D2E9EC",
      },
    },
  },

  // Theme colors specific to dark theme
  darkStandard: {
    name: "darkStandard",
    key: "dark_light_standard_theme",
    type: "dark",
    img: darkStandaardImg,
    companyLogoHeight: "16",
    direction: "ltr",
    token: {
      grayClr: "#c3c3c3",
      toolbarButton: "#3586C7",
      toolbarButtonIconColor: "#F5F6F7",

      // colors Palette
      themeLayoutBg: "#08182C",
      headerLogoBg: "#0C2340",

      // General color
      colorSuccessBg: "#12512e",
      colorInfoBg: "#1e464d",
      colorWarningBg: "#62491d",
      colorDangerBg: "#ee7b734f",
      colorYellowBg: "#fffbe6",
      colorRedBg: "#362b34",
      colorPurpleBg: "#352764",
      colorLightBlue: "#232e43",

      colorText: "#F5F6F7",
      colorTextDescription: "orange",

      gebruikersTableSortBg: "rgba(14, 34, 64, 0.92)",

      // Spacing and sizes
      controlHeight: 35,

      // Font Family
      fontFamily: "Lexend",
    },
    components: {
      Menu: {
        headerBgColor: "#0E2240",
        navBrandBg: "#142b50",
        itemBg: "#08182C",
        itemClr: "#ffff",
        itemBgHover: "#274974",
        itemColor: "#849ec1",
        itemActiveBg: "#0b70ff1a",
        itemHoverColor: "#438eff",
        subMenuItemBg: "#152234",
        itemSelectedBg: "#0b70ff1a",
        menuBorderColor: "#293b55",
        sidebarNavTextClr: "#fff",
      },
      Table: {
        tableBg: "transparent",
        headerBg: "#0E2240",
        tableHeadBorderColor: "#899099",
        bgColorOddRow: "rgba(251, 251, 252, 0.05)",
        borderColor: "rgba(225, 227, 230, 0.25)",
        sortedBg: "#1a3052",
        fontSize: "16px",
        fontWeightStrong: "500",
        imgLocationClr: "#fff",
        publishedDotClr: "#69CAA7",
        rowHoverBg: "#436d97",
      },
      Pagination: {
        itemBg: "#F5F6F7",
        itemActiveBg: "#11243F",
        colorPrimary: "#FFFFFF",
      },
      Radio: {
        buttonBg: "#152234",
        buttonCheckedBg: "#152234",
        buttonCheckedBgDisabled: "#344762",
        buttonCheckedColorDisabled: "#9dacc1",
        buttonColor: "#c6d4e8",
        buttonPaddingInline: 15,
        buttonSolidCheckedColor: "#fff",
        dotColorDisabled: "#9dacc1",
        dotSize: 8,
        radioSize: 16,
        wrapperMarginInlineEnd: 8,
      },
      Select: {
        borderRadius: 12,
        selectorBg: "#0E2240",
        colorIcon: "#ffffff",
        colorPrimaryHover: "#ffffff",
        controlHeight: 56,
        colorBgElevated: "#0E2240",
        optionSelectedBg: "#1d4177",
        colorBorder: "rgba(225, 227, 230, 0.25)",
      },
      SideBarDivider: {
        borderColor: "#F5F6F7",
        opacity: 0.4,
      },
      SideBarUserProfile: {
        size: "40px",
      },
      Divider: {
        borderColor: "rgba(255, 255, 255, 0.25)",
      },
      Input: {
        colorBgContainer: "#0E2240",
        colorBorder: "rgba(225, 227, 230, 0.25)",
        colorTextPlaceholder: "rgba(225, 227, 230, 1)",
        colorBgContainerDisabled: "#12233f",
        colorTextDisabled: "rgba(225,225,225,0.25)",
      },
      HeaderButtonsStyle: {
        // folder Button Style
        folderButtonBg: "#0085ca",
        folderButtonClr: "#0C2340",
        // Hover Style
        folderButtonHoverBg: "#D3E7FB",
        folderButtonHoverClr: "#4096ff",

        // add Button Style
        addButtonBg: "#0C2340",
        addButtonClr: "#FFFFFF",
        // Hover Style
        addButtonHoverBg: "#D3E7FB",
        addButtonHoverClr: "#4096ff",

        // delete Button Style
        deleteButtonBg: "rgba(224, 54, 78, 0.8)",
        deleteButtonClr: "#fff",
        // Hover Style
        deleteButtonHoverBg: "#E0364E",
        deleteButtonHoverClr: "#fff",

        // Submit Button Style
        submitButtonBg: "#69CAA7",
        submitButtonClr: "#08182C",
        // Hover Style
        submitButtonHoverBg: "#D6FFF0",
        submitButtonHoverClr: "#08182C",

        // disabled Submit Button Style
        disabledSubmitButtonBg: "#244a7a",
        disabledSubmitButtonClr: "#E1E3E6",
        // Hover Style
        disabledSubmitButtonHoverBg: "#D6FFF0",
        disabledSubmitButtonHoverClr: "#08182C",

        // Archive Button Style
        archiveButtonBg: "#69CAA7",
        archiveButtonClr: "#08182C",
        // Hover Style
        archiveButtonHoverBg: "#D6FFF0",
        archiveButtonHoverClr: "#08182C",

        // back button Style
        backButtonBg: "rgba(225, 227, 230, 0.24);",
        backButtonBorder: "rgba(12, 35, 64, 0.24);",
        backButtonClr: "#08182C",
      },
      PersonInfoCard: {
        cardBg: "#0E2240",
      },
      SystemCollectionButtonStyle: {
        btnBg: "#243a5b",
        borderClr: "#e1e3e6",
        BtnTextClr: "#fff",
      },
      Upload: {
        colorPrimary: "#0E2240",
      },
      Tree: {
        colorBgContainer: "#0E2240",
      },
      TreeSelect: {
        nodeSelectedBg: "#0b70ff1a",
        colorBgElevated: "#0E2240",
      },
      DatePicker: {
        colorBgContainer: "#0E2240",
      },
    },
  },

  darkNoxStandard: {
    name: "darkNoxStandard",
    key: "dark_light_nox_standard_theme",
    type: "dark",
    img: darkNoxStandaardImg,
    companyLogoHeight: "16",
    direction: "ltr",
    token: {
      grayClr: "#c3c3c3",
      toolbarButton: "#3586C7",
      toolbarButtonIconColor: "#F5F6F7",

      // colors Palette
      themeLayoutBg: "#08182C",
      headerLogoBg: "#0C2340",

      // General color
      colorSuccessBg: "#12512e",
      colorInfoBg: "#1e464d",
      colorWarningBg: "#62491d",
      colorDangerBg: "#ee7b734f",
      colorYellowBg: "#fffbe6",
      colorRedBg: "#362b34",
      colorPurpleBg: "#352764",
      colorLightBlue: "#232e43",

      colorText: "#F5F6F7",
      colorTextDescription: "orange",

      gebruikersTableSortBg: "rgba(14, 34, 64, 0.92)",

      // Spacing and sizes
      controlHeight: 35,

      // Font Family
      fontFamily: "Lexend",
    },
    components: {
      Menu: {
        headerBgColor: "#0E2240",
        navBrandBg: "#0E2240",
        itemBg: "#08182C",
        itemClr: "#ffff",
        itemBgHover: "#274974",
        itemColor: "#849ec1",
        itemActiveBg: "#0b70ff1a",
        itemHoverColor: "#438eff",
        subMenuItemBg: "#152234",
        itemSelectedBg: "#0b70ff1a",
        menuBorderColor: "#293b55",
        sidebarNavTextClr: "#fff",
      },
      Table: {
        tableBg: "transparent",
        headerBg: "#0E2240",
        tableHeadBorderColor: "#899099",
        bgColorOddRow: "rgba(251, 251, 252, 0.05)",
        borderColor: "rgba(225, 227, 230, 0.25)",
        sortedBg: "#1a3052",
        fontSize: "16px",
        fontWeightStrong: "500",
        imgLocationClr: "#fff",
        publishedDotClr: "#69CAA7",
        rowHoverBg: "#436d97",
      },
      Pagination: {
        itemBg: "#F5F6F7",
        itemActiveBg: "#11243F",
        colorPrimary: "#FFFFFF",
      },
      Radio: {
        buttonBg: "#152234",
        buttonCheckedBg: "#152234",
        buttonCheckedBgDisabled: "#344762",
        buttonCheckedColorDisabled: "#9dacc1",
        buttonColor: "#c6d4e8",
        buttonPaddingInline: 15,
        buttonSolidCheckedColor: "#fff",
        dotColorDisabled: "#9dacc1",
        dotSize: 8,
        radioSize: 16,
        wrapperMarginInlineEnd: 8,
      },
      Select: {
        borderRadius: 12,
        selectorBg: "#0E2240",
        colorIcon: "#ffffff",
        colorPrimaryHover: "#ffffff",
        controlHeight: 56,
        colorBgElevated: "#0E2240",
        optionSelectedBg: "#1d4177",
        colorBorder: "rgba(225, 227, 230, 0.25)",
      },
      SideBarDivider: {
        borderColor: "#F5F6F7",
        opacity: 0.4,
      },
      SideBarUserProfile: {
        size: "40px",
      },
      Divider: {
        borderColor: "rgba(255, 255, 255, 0.25)",
      },
      Input: {
        colorBgContainer: "#0E2240",
        colorBorder: "rgba(225, 227, 230, 0.25)",
        colorTextPlaceholder: "rgba(225, 227, 230, 1)",
        colorBgContainerDisabled: "#12233f",
        colorTextDisabled: "rgba(225,225,225,0.25)",
      },
      HeaderButtonsStyle: {
        // folder Button Style
        folderButtonBg: "#0085ca",
        folderButtonClr: "#0C2340",
        // Hover Style
        folderButtonHoverBg: "#D3E7FB",
        folderButtonHoverClr: "#4096ff",

        // add Button Style
        addButtonBg: "#0C2340",
        addButtonClr: "#FFFFFF",
        // Hover Style
        addButtonHoverBg: "#D3E7FB",
        addButtonHoverClr: "#4096ff",

        // delete Button Style
        deleteButtonBg: "rgba(224, 54, 78, 0.8)",
        deleteButtonClr: "#fff",
        // Hover Style
        deleteButtonHoverBg: "#E0364E",
        deleteButtonHoverClr: "#fff",

        // Submit Button Style
        submitButtonBg: "#69CAA7",
        submitButtonClr: "#08182C",
        // Hover Style
        submitButtonHoverBg: "#D6FFF0",
        submitButtonHoverClr: "#08182C",

        // disabled Submit Button Style
        disabledSubmitButtonBg: "#244a7a",
        disabledSubmitButtonClr: "#E1E3E6",
        // Hover Style
        disabledSubmitButtonHoverBg: "#D6FFF0",
        disabledSubmitButtonHoverClr: "#08182C",

        // Archive Button Style
        archiveButtonBg: "#69CAA7",
        archiveButtonClr: "#08182C",
        // Hover Style
        archiveButtonHoverBg: "#D6FFF0",
        archiveButtonHoverClr: "#08182C",

        // back button Style
        backButtonBg: "rgba(225, 227, 230, 0.24);",
        backButtonBorder: "rgba(12, 35, 64, 0.24);",
        backButtonClr: "#08182C",
      },
      PersonInfoCard: {
        cardBg: "#0E2240",
      },
      SystemCollectionButtonStyle: {
        btnBg: "#243a5b",
        borderClr: "#e1e3e6",
        BtnTextClr: "#fff",
      },
      Upload: {
        colorPrimary: "#0E2240",
      },
      Tree: {
        colorBgContainer: "#0E2240",
      },
      TreeSelect: {
        nodeSelectedBg: "#0b70ff1a",
        colorBgElevated: "#0E2240",
      },
      DatePicker: {
        colorBgContainer: "#0E2240",
      },
    },
  },
};

export { themes };
