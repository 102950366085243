import { combineReducers } from "@reduxjs/toolkit";
import themeReducer from "./slices/theme.reducer";
import activeTabReducer from "./slices/activeTab.reducer";
import languageOptionsReducer from "./slices/language.reducer";
import userDetailsReducer from "./slices/userDetails.reducer";

const rootReducer = combineReducers({
  theme: themeReducer,
  activeTab: activeTabReducer,
  languageOptions: languageOptionsReducer,
  user: userDetailsReducer,
});

export default rootReducer;
