import React, { useContext, useEffect } from "react";
import { Flex } from "antd";
import SubMenu from "../../Components/SubMenu";
import { SidebarContext } from "../../Context/submenuContext";
import { tabletBreakpoint } from "../../helpers/utils";
import useScreenWidth from "../../Common/Hooks/ScreenWidth";
import { useTranslation } from "react-i18next";
import SuperAdmin from "./SuperAdmin";
import Administrator from "./Administrator";
import Auteur from "./Auteur";
import Redacteur from "./Redacteur";
import FotoVideograaf from "./FotoVideograaf";
import IconsAll from "../../Components/IconsAll";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import TenantList from "../Tenant/TenantList";

const Gebruikers = () => {
  const { t } = useTranslation();
  const submenu = useContext(SidebarContext);
  const screenWidth = useScreenWidth();
  const isTabletOrSmaller = screenWidth <= tabletBreakpoint;
  const isSubMenuActive = submenu?.activatedItem === "Gebruikers";
  const path = location.pathname.split("/");
  const activeTab = useSelector((state) => state.activeTab[path[1]]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const subMenus = [
    {
      name: t("all") + " " + t("users"),
      link: "/gebruikers/alle-gebruikers",
      icon: <IconsAll.AllUsersIcon />,
      divider: true,
    },
    // {
    //   name: t("super") + " " + t("admin"),
    //   link: "/gebruikers/user-details",
    //   icon: <IconsAll.SuperAdminIcon />,
    // },
    // {
    //   name: t("administrator"),
    //   link: "/gebruikers/administrator",
    //   icon: <IconsAll.AdministratorIcon />,
    // },
    // {
    //   name: t("editor"),
    //   link: "/gebruikers/redacteur",
    //   icon: <IconsAll.RedacteurIcon />,
    // },
    // {
    //   name: t("author"),
    //   link: "/gebruikers/auteur",
    //   icon: <IconsAll.AuteurIcon />,
    // },
    // {
    //   name: t("photographer") + "-/" + t("videographer"),
    //   link: "/gebruikers/foto-videograaf",
    //   icon: <IconsAll.FotoVideograafIcon />,
    // },
    // {
    //   name: t("tenant"),
    //   link: "/gebruikers/tenant",
    //   icon: <IconsAll.FotoVideograafIcon />,
    // },
  ];

  useEffect(() => {
    if (activeTab && activeTab?.currentTab) {
      navigate(activeTab?.redirectionLink);
    } else {
      dispatch(
        setActiveTab({
          page: path[1],
          tab: subMenus[0].name,
          redirectionLink: subMenus[0].link,
        }),
      );
    }
  }, [path[2], activeTab]);

  const renderChild = () => {
    switch (path[2]) {
      case "user-details":
        return <SuperAdmin />;
      case "administrator":
        return <Administrator />;
      case "redacteur ":
        return <Redacteur />;
      case "auteur":
        return <Auteur />;
      case "foto-videograaf":
        return <FotoVideograaf />;
      // case "tenant":
      //   return <TenantList />;
      default:
        return <TenantList />;
    }
  };

  return (
    <Flex>
      {(isSubMenuActive || !isTabletOrSmaller) && (
        <SubMenu
          isSubMenuLogo
          isSubMenuVersion
          items={subMenus}
          currentPage={path[1]}
        />
      )}
      {renderChild()}
    </Flex>
  );
};

export default Gebruikers;
