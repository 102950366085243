import React, { useContext, useEffect, useState } from "react";
import {
  Flex,
  Table,
  Button,
  Drawer,
  notification,
  Modal,
  Col,
  Dropdown,
  Skeleton,
} from "antd";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import IconsAll from "../../Components/IconsAll";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import styled from "styled-components";
import AppInfo from "../../Components/AppInfo";
import { SidebarContext } from "../../Context/submenuContext";
import { convertToTreeData, tabletBreakpoint } from "../../helpers/utils";
import Backdrop from "../../Layouts/Backdrop";
import { useTranslation } from "react-i18next";
import { themes } from "../../config";
import { useSelector } from "react-redux";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Apis from "../../helpers/Apis";
import axiosInstance from "../../helpers/axios";
import { store } from "../../Redux/store";
import CreateNewFields from "../../Components/Create-new-fields/CreateNewFields";
import EditFields from "../../Components/Create-new-fields/EditFields";
import Paragraph from "antd/es/typography/Paragraph";

const DrawerStyleNewField = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: white;
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    .ant-tabs-nav-wrap {
      padding-top: 1rem;
    }
    .ant-collapse {
      display: grid;
      grid-template-columns: repete(4, 1fr);
      position: relative;

      .ant-collapse-item {
        .ant-collapse-content {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: auto;
          transform: translateY(100%);
        }
      }
    }
  }
`;
const DrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: white;
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    padding: 0;
    .ant-tabs-nav-wrap {
      padding-top: 1rem;
    }
    .ant-collapse {
      display: grid;
      grid-template-columns: repete(4, 1fr);
      position: relative;

      .ant-collapse-item {
        .ant-collapse-content {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: auto;
          transform: translateY(100%);
        }
      }
    }
    .ant-tabs-left {
      min-height: 100dvh;
      min-height: 100vh;
      .ant-tabs-content-holder {
        padding: 24px;
        .ant-tabs-tabpane {
          padding-left: 0 !important;
        }
      }
    }
  }
`;

export const TableSort = styled(Table)`
.ant-table{
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Table.tableBg};
}
.ant-table {
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Table.tableBg};
  table{
    background-color:transparent;
    border-spacing: 0 1rem;
    .ant-table-row{
      .ant-table-cell{
        &:first-child{
          border-radius: 8px 0 0 8px;
          border-left: 1px solid ${() =>
            themes[useSelector((state) => state.theme.name)].components.Table
              .borderColor} !important;
        }
        &:last-child{
          border-radius: 0 8px 8px 0;
          border-right: 1px solid ${() =>
            themes[useSelector((state) => state.theme.name)].components.Table
              .borderColor} !important;
        }
      }
    }
  }
  .gray-clr {
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].token.grayClr};
  }
  .ant-table-thead{
    display: none;
  }
  .ant-table-cell,
  .ant-table-cell.ant-table-cell-fix-left{
    background-color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Table
        .sortedBg} !important;
      }
  }
  .ant-table-cell {
    border-bottom: 1px solid
        ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .borderColor} !important;
      border-top: 1px solid
        ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .borderColor} !important;
    &.ant-table-column-has-sorters {
      &:hover {
        background-color: ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .sortedBg} !important;
      }
    }
    &.ant-table-column-sort {
      background-color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Table
          .sortedBg};
    }
  }

  .imgLocationClr {
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Table
        .imgLocationClr};
    margin-left: 10px;
  }

}
`;

const TreeItem = ({
  title,
  item,
  depth = 0,
  handleMenuClick,
  handleContentVisibility,
  handleClick,
}) => {
  const { attributes, listeners, setNodeRef } = useSortable({
    id: item?._id,
  });

  const items = [
    !["object"]?.includes(item?.meta?.interface) && {
      label: (
        <button type="button" onClick={() => handleMenuClick("half", item)}>
          Half Width
        </button>
      ),
      key: "0",
    },
    !["object"]?.includes(item?.meta?.interface) && {
      label: (
        <button type="button" onClick={() => handleMenuClick("full", item)}>
          Full Width
        </button>
      ),
      key: "1",
    },
    {
      label: (
        <button type="button" onClick={() => handleMenuClick("delete", item)}>
          Delete Field
        </button>
      ),
      key: "2",
    },
  ];

  return (
    <Col
      xs={24}
      sm={24}
      md={item?.meta?.width === "half" ? 12 : 24}
      style={{ paddingLeft: depth * 20 }}
    >
      <Paragraph
        className={`flex justify-between border border-[--input-field-border] bg-[--input-field-bg] p-3 my-3 rounded-xl items-center ${item?.meta?.interface === "none" ? "cursor-not-allowed " : ""} `}
        {...attributes}
        {...listeners}
        ref={setNodeRef}
      >
        <ul className="flex gap-4 flex-1 items-center !mb-0 !list-none">
          <li
            className={
              item?.meta?.interface === "none"
                ? "cursor-pointer | list-none pl-0 flex !ml-0"
                : "list-none pl-0 flex !ml-0"
            }
          >
            <IconsAll.DragDropIcon />
          </li>
          <li
            className={
              item?.meta?.interface === "none"
                ? "cursor-not-allowed pointer-events-none | list-none pl-0 flex !ml-0"
                : "list-none pl-0 flex !ml-0"
            }
          >
            <button type="button" onClick={(e) => handleClick(e, item)}>
              {title}
            </button>
          </li>
          <li
            className={` ${item?.meta?.interface === "none" ? "cursor-not-allowed pointer-events-none | list-none pl-0 flex !ml-0" : "list-none pl-0 flex !ml-0"}`}
          >
            <button
              type="button"
              className="w-full text-start"
              onClick={(e) => handleClick(e, item)}
            >
              {item?.meta?.interface}
            </button>
          </li>
        </ul>
        <ul className="flex gap-4 items-center !mb-0 !list-none">
          {item?.schema_definition?.is_primary ? (
            <li
              title="Primary Key"
              className="cursor-pointer | list-none pl-0 flex !ml-0"
            >
              <button type="button" title="Primary Key">
                <IconsAll.KeyIcon />
              </button>
            </li>
          ) : (
            <></>
          )}
          <li
            className={
              item?.meta?.interface === "none"
                ? "cursor-not-allowed pointer-events-none | list-none pl-0 flex !ml-0"
                : "list-none pl-0 flex !ml-0"
            }
          >
            <button
              onClick={() =>
                handleContentVisibility(!item?.meta?.hidden, item?._id)
              }
            >
              {item?.meta?.hidden ? (
                <IconsAll.EyeCloseIcon />
              ) : (
                <IconsAll.OpenEyeIcon strokeColor={"#88909A"} />
              )}
            </button>
          </li>
          <li
            className={
              item?.meta?.interface === "none"
                ? "cursor-not-allowed pointer-events-none | list-none pl-0 flex !ml-0"
                : "list-none pl-0 flex !ml-0"
            }
          >
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
              // onClick={() => setActiveItem(item)}
            >
              <a onClick={(e) => e.preventDefault()}>
                <IconsAll.MoreIcon />
              </a>
            </Dropdown>
          </li>
        </ul>
      </Paragraph>
    </Col>
  );
};

const Tree = ({
  items,
  depth = 0,
  handleMenuClick,
  handleContentVisibility,
  handleClick,
}) => {
  return (
    <SortableContext
      items={items?.map((f) => f?._id) || []}
      strategy={verticalListSortingStrategy}
    >
      {items?.map((item) => (
        <React.Fragment key={item?._id}>
          <TreeItem
            id={item?._id}
            title={item?.title}
            depth={depth || 0}
            item={item}
            handleMenuClick={handleMenuClick}
            handleContentVisibility={handleContentVisibility}
            handleClick={handleClick}
          />
          {item?.children && (
            <Tree
              items={item?.children}
              depth={depth + 1}
              handleMenuClick={handleMenuClick}
              handleContentVisibility={handleContentVisibility}
              handleClick={handleClick}
            />
          )}
        </React.Fragment>
      ))}
    </SortableContext>
  );
};

const ChangeDataModalCollection = () => {
  const { t } = useTranslation();
  const submenu = useContext(SidebarContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const path = location.pathname.split("/");
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 20,
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);

  const handleContentVisibility = async (record, id) => {
    try {
      const resp = await axiosInstance.patch(
        Apis.UPDATE_FIELDS_VISIBILITY(dataSource?.collection_id),
        { hidden: record, field: id, schema_id: path[3] },
        {
          headers: {
            "x-tenant-id": store.getState().user.userId,
          },
        },
      );
      if (resp.status === 200) {
        notification.success({ message: "Visibility updated successfully." });
        setDataSource((prev) => {
          return {
            ...prev,
            fields: prev.fields.map((field) => {
              if (field._id === id) {
                return {
                  ...field,
                  meta: {
                    ...field?.meta,
                    hidden: record,
                  },
                };
              }
              return field;
            }),
          };
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const handleClick = (e, item) => {
    item?.children && delete item?.children;
    item?.title && delete item?.title;
    e.stopPropagation();
    setOpenEdit(true);
    setActiveItem(item);
  };

  const handleMenuClick = async (type, item) => {
    if (type === "delete") {
      Modal.confirm({
        title: `Delete ${item?.field}`,
        content: `Are you sure you want to delete the field ${item?.field} permanently?This action is permanent.
          `,
        icon: null,
        okText: "Delete Field",
        okType: "primary",
        cancelText: "Cancel",
        maskClosable: true,
        className: "modal-delete",
        onOk: async () => {
          try {
            const resp = await axiosInstance.delete(
              Apis.DELETE_A_FIELD(item?._id),
              {
                headers: {
                  "x-tenant-id": store.getState().user.userId,
                },
              },
            );
            if (resp.status === 200) {
              notification.success({
                message: `${item?.field} deleted successfully`,
              });
              init();
            }
            setActiveItem(null);
          } catch (error) {
            console.log("error", error);
          }
        },
      });
    }
    if (item?.meta?.width !== type && (type === "half" || type === "full")) {
      try {
        let selectedField = dataSource?.fields?.find(
          (f) => f.field === item?.field,
        );
        const { _id, ...restOfSelectedField } = selectedField;
        delete restOfSelectedField.__v;
        const payload = {
          ...restOfSelectedField,
          meta: {
            ...restOfSelectedField.meta,
            width: type,
          },
        };

        const resp = await axiosInstance.put(
          Apis.UPDATE_A_FIELD(_id),
          payload,
          {
            headers: {
              "x-tenant-id": store.getState().user.userId,
            },
          },
        );
        if (resp.status === 200) init();
        setActiveItem(null);
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const init = async () => {
    try {
      setLoading(true);
      const resp1 = await axiosInstance.get(Apis.GET_A_SCHEMA(params.field), {
        headers: {
          "x-tenant-id": store.getState().user.userId,
        },
      });
      if (resp1.status === 200) setDataSource(resp1?.data?.payload?.data);
      const resp2 = await axiosInstance.get(Apis.GET_ALL_FIELDS(params.field), {
        headers: {
          "x-tenant-id": store.getState().user.userId,
        },
      });
      if (resp2.status === 200)
        setDataSource((prev) => {
          return {
            ...prev,
            fields: resp2.data.payload.data?.sort(
              (a, b) => a?.meta?.sort - b?.meta?.sort,
            ),
          };
        });
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous?.fields.findIndex(
          (i) => i._id === active.id,
        );
        const overIndex = previous?.fields.findIndex((i) => i._id === over?.id);
        return {
          ...previous,
          fields: arrayMove(previous?.fields, activeIndex, overIndex),
        };
      });
    }
  };

  const handleAddClick = async () => {
    try {
      const resp = await axiosInstance.put(
        Apis.UPDATE_FIELD_SORT(params.field),
        {
          fields: dataSource?.fields?.map((item, i) => {
            delete item?.__v;
            return { ...item, meta: { ...item?.meta, sort: i + 1 } };
          }),
          isSortUpdated: true,
        },
        {
          headers: {
            "x-tenant-id": store.getState().user.userId,
          },
        },
      );
      if (resp.status === 200) {
        notification.success({ message: "Schema Updated Successfully" });
        navigate("/instellingen/data-model");
        dispatch(
          setActiveTab({
            page: "instellingen",
            tab: dataSource?.collection_id,
            redirectionLink: "/instellingen/data-model",
          }),
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleDeleteClick = async () => {
    setOpen(false);
    try {
      const resp = await axiosInstance.delete(
        Apis.DELETE_A_SCHEMA(params.field),
        {
          headers: {
            "x-tenant-id": store.getState().user.userId,
          },
        },
      );
      if (resp.status === 200) {
        notification.success({ message: "Collection deleted successfiully" });
        navigate("/instellingen/data-model");
        dispatch(
          setActiveTab({
            page: "instellingen",
            tab: "Data model",
            redirectionLink: "/instellingen/data-model",
          }),
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Flex className="w-full">
      {(submenu?.activatedItem == "toegangscontrole" ||
        window.screen.width >= tabletBreakpoint) && (
        <>
          {window.screen.width <= tabletBreakpoint && (
            <Backdrop onClick={() => submenu.toggleActivation()} />
          )}
        </>
      )}
      <section className="w-full">
        <div className="px-4 xl:px-16 w-full">
          <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
            <div
              onClick={() => {
                navigate("/instellingen/data-model");
                dispatch(
                  setActiveTab({
                    page: "instellingen",
                    tab: "Data model",
                    redirectionLink: "/instellingen/data-model",
                  }),
                );
              }}
            >
              <LeftSideTitlePart
                pageTitle={dataSource?.collection_id}
                pageSubTitle={t("setting")}
                backButton={true}
                // backLink="/instellingen/data-modal"
              />
            </div>
            <RightSIdeTitlePart
              deleteButton={true}
              showButton={true}
              handleShowClick={handleAddClick}
              showDisabled={!dataSource?.fields?.length}
              handleDeleteClick={() => setOpen(true)}
            />
            <DrawerStyleNewField
              width={870}
              open={isDrawerOpen}
              onClose={() => {
                setIsDrawerOpen(false);
              }}
              closeIcon={<IconsAll.CloseIconCustom />}
            >
              <CreateNewFields
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                init={init}
                dataSource={dataSource}
              />
            </DrawerStyleNewField>
            <DrawerStyle
              width={870}
              open={openEdit}
              onClose={() => {
                setOpenEdit(false);
                setActiveItem(null);
                init();
              }}
              closeIcon={<IconsAll.CloseIconCustom />}
            >
              <EditFields
                isDrawerOpen={openEdit}
                setIsDrawerOpen={setOpenEdit}
                init={init}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                dataSource={dataSource}
              />
            </DrawerStyle>
          </div>
          <section className="mt-9 max-w-4xl container">
            <h2 className="text-base text-[--notication-status-svg] font-bold mb-5">
              {t("fields")} & {t("layout")}
              <span className="font-normal text-[--notication-status-active] opacity-60 text-sm ml-2">
                {t("saves_automatically")}
              </span>
            </h2>
            {loading ? (
              <Skeleton size="large" active />
            ) : (
              <>
                <DndContext
                  modifiers={[restrictToVerticalAxis]}
                  collisionDetection={closestCenter}
                  onDragEnd={onDragEnd}
                  sensors={sensors}
                >
                  <Tree
                    items={convertToTreeData(dataSource?.fields, "tree-fields")}
                    handleMenuClick={handleMenuClick}
                    handleContentVisibility={handleContentVisibility}
                    handleClick={handleClick}
                  />
                </DndContext>
                <Button
                  className="rounded-3xl bg-btn-dark text-white px-5 font-medium h-auto py-3 w-full"
                  onClick={() => setIsDrawerOpen(true)}
                >
                  {t("add_field")}
                </Button>
              </>
            )}
          </section>
        </div>
      </section>
      <Modal
        open={open}
        title="Delete Collection"
        onOk={handleDeleteClick}
        onCancel={() => setOpen(false)}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <p className="my-6">
          Are you sure you want to delete this collection permanently?
        </p>
      </Modal>
      <AppInfo />
    </Flex>
  );
};

export default ChangeDataModalCollection;
