import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const languageOptions = createSlice({
  name: "languageOptions",
  initialState,
  reducers: {
    setLanguageOptions: (state, action) => {
      return action.payload;
    },
  },
});

export const { setLanguageOptions } = languageOptions.actions;
export default languageOptions.reducer;
