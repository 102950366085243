import React from "react";
import withRouter from "../Common/withRouter";
import { ThemeProvider } from "styled-components";
import { ConfigProvider } from "antd";
import { useSelector } from "react-redux";

const NonAuthLayout = ({ children }) => {
  const currentTheme = useSelector((state) => state.theme);
  return (
    <ThemeProvider theme={currentTheme}>
      <ConfigProvider theme={currentTheme}>{children}</ConfigProvider>
    </ThemeProvider>
  );
};

export default withRouter(NonAuthLayout);
