import { Divider, Flex, Typography, notification } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import IconsAll from "../IconsAll";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import RightSIdeTitlePart from "../HeaderParts/RightSIdeTitlePart";
import InputFormField from "./formFieldsComponents/InputFormField";
import AutocompleteInput from "./formFieldsComponents/AutocompleteInput";
import { useSelector } from "react-redux";
import { themes } from "../../config";
// import BlockEditor from "./formFieldsComponents/BlockEditor";
// import Code from "./formFieldsComponents/Code";
import TextArea from "./formFieldsComponents/TextArea";
import WYSIWYG from "./formFieldsComponents/WYSIWYG";
// import Markdown from "./formFieldsComponents/Markdown";
import Tags from "./formFieldsComponents/Tags";
import Toggle from "./formFieldsComponents/Toggle";
import DateTime from "./formFieldsComponents/DateTime";
// import Repeater from "./formFieldsComponents/Repeater";
// import Map from "./formFieldsComponents/Map";
// import Color from "./formFieldsComponents/Color";
import Dropdown from "./formFieldsComponents/Dropdown";
// import Icon from "./formFieldsComponents/Icon";
import Checkboxes from "./formFieldsComponents/Checkboxes";
// import CheckboxesTree from "./formFieldsComponents/CheckboxesTree";
import DropdownMultiple from "./formFieldsComponents/DropdownMultiple";
import RadioButtons from "./formFieldsComponents/RadioButtons";
import File from "./formFieldsComponents/File";
import Image from "./formFieldsComponents/Image";
import Files from "./formFieldsComponents/Files";
// import Builder from "./formFieldsComponents/Builder";
import ManyToMany from "./formFieldsComponents/ManyToMany";
import OneToMany from "./formFieldsComponents/OneToMany";
// import TreeView from "./formFieldsComponents/TreeView";
import ManyToOne from "./formFieldsComponents/ManyToOne";
// import Translations from "./formFieldsComponents/Translations";
// import DividerTab from "./formFieldsComponents/DividerTab";
// import ButtonLinks from "./formFieldsComponents/ButtonLinks";
// import Notice from "./formFieldsComponents/Notice";
// import AccordionTab from "./formFieldsComponents/AccordionTab";
// import DetailGroup from "./formFieldsComponents/DetailGroup";
// import RawGroup from "./formFieldsComponents/RawGroup";
// import CollectionItemDropdown from "./formFieldsComponents/CollectionItemDropdown";
// import Hash from "./formFieldsComponents/Hash";
// import SliderTab from "./formFieldsComponents/SliderTab";
import axiosInstance from "../../helpers/axios";
import { store } from "../../Redux/store";
import Apis from "../../helpers/Apis";
import GroupObjectField from "./formFieldsComponents/groupObjectField";

const TItleIconStyle = styled(Typography)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TabHeaders = styled.div`
  display: grid;
  cursor: pointer;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  > div {
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].token.grayClr};
    > .custom-tabs {
      background-color: ${() =>
        themes[useSelector((state) => state.theme.name)].token.colorGrayrBg};
      border: 1px solid rgba(231, 231, 231, 0.92);
    }
  }
`;

const TabHeader = styled.div`
  border-radius: 5px 5px 0 0;
`;

const TabContent = styled.div`
  width: 100%;
  border-radius: 0 5px 5px 5px;
  opacity: ${(props) => (props.visible === "YES" ? 1 : 0)};
  max-height: ${(props) => (props.visible === "YES" ? "100%" : "0")};
  transition: all 0.5s linear 0.5;
`;

const CreateNewFields = ({ setIsDrawerOpen, init, dataSource }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(null);

  const handleTabClick = (id) => {
    setActiveTab((prevTab) => (prevTab === id ? null : id));
  };

  const addField = async (field) => {
    try {
      const resp = await axiosInstance.post(
        Apis.ADD_A_FIELD(),
        { ...field, totalFields: dataSource?.fields?.length },
        {
          headers: {
            "x-tenant-id": store.getState().user.userId,
          },
        },
      );
      if (resp.status === 200)
        notification.success({ message: "Field added successfully" });
      init();
    } catch (error) {
      notification.error({ message: "Something went wrong" });
    }
  };
  //Group Fields
  const groupField = [
    {
      id: "object",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldInput />
          </div>
          Object
        </>
      ),
      children: (
        <GroupObjectField
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
  ];

  // Text & Numbers Array
  const textNumbersRow = [
    {
      id: "input",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldInput />
          </div>
          Input
        </>
      ),
      children: (
        <InputFormField
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
    {
      id: "autocomplete",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldInput />
          </div>
          Autocomplete Input (API)
        </>
      ),
      children: (
        <AutocompleteInput
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
    // {
    //   id: "block_editor",
    //   label: (
    //     <>
    //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
    //         <IconsAll.FieldBlockEditor />
    //       </div>
    //       Block Editor
    //     </>
    //   ),
    //   children: (
    //     <BlockEditor
    //       activeTab={activeTab}
    // setActiveTab={setActiveTab}
    //       addField={addField}
    //       setIsDrawerOpen={setIsDrawerOpen}
    // dataSource={dataSource}
    //     />
    //   ),
    //   showArrow: false,
    // },
    // {
    //   id: "code",
    //   label: (
    //     <>
    //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
    //         <IconsAll.FieldBlockEditor />
    //       </div>
    //       Code
    //     </>
    //   ),
    //   children: (
    //     <Code
    //       activeTab={activeTab}
    // setActiveTab={setActiveTab}
    //       addField={addField}
    //       setIsDrawerOpen={setIsDrawerOpen}
    // dataSource={dataSource}
    //     />
    //   ),
    //   showArrow: false,
    // },
  ];

  const textNumbersRow02 = [
    {
      id: "textarea",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldTextArea />
          </div>
          Textarea
        </>
      ),
      children: (
        <TextArea
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
    {
      id: "wysiwyg",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldMarkDown />
          </div>
          WYSIWYG
        </>
      ),
      children: (
        <WYSIWYG
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
    // {
    //   id: "markdown",
    //   label: (
    //     <>
    //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
    //         <IconsAll.FieldMarkDown />
    //       </div>
    //       Markdown
    //     </>
    //   ),
    //   children: (
    //     <Markdown
    //       activeTab={activeTab}
    // setActiveTab={setActiveTab}
    //       addField={addField}
    //       setIsDrawerOpen={setIsDrawerOpen}
    // dataSource={dataSource}
    //     />
    //   ),
    //   showArrow: false,
    // },
    {
      id: "tags",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldTags />
          </div>
          Tags
        </>
      ),
      children: (
        <Tags
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
  ];

  // Selection Array
  const selectionRow = [
    {
      id: "toggle",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldToggle />
          </div>
          Toggle
        </>
      ),
      children: (
        <Toggle
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
    {
      id: "datetime",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldDateTime />
          </div>
          Datetime
        </>
      ),
      children: (
        <DateTime
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
    // {
    //   id: "repeater",
    //   label: (
    //     <>
    //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
    //         <IconsAll.FieldRepeater />
    //       </div>
    //       Repeater
    //     </>
    //   ),
    //   children: (
    //     <Repeater
    //       activeTab={activeTab}
    // setActiveTab={setActiveTab}
    //       addField={addField}
    //       setIsDrawerOpen={setIsDrawerOpen}
    // dataSource={dataSource}
    //     />
    //   ),
    //   showArrow: false,
    // },
    // {
    //   id: "fieldmap",
    //   label: (
    //     <>
    //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
    //         <IconsAll.FieldMap />
    //       </div>
    //       Map
    //     </>
    //   ),
    //   children: (
    //     <Map
    //       activeTab={activeTab}
    // setActiveTab={setActiveTab}
    //       addField={addField}
    //       setIsDrawerOpen={setIsDrawerOpen}
    // dataSource={dataSource}
    //     />
    //   ),
    //   showArrow: false,
    // },
  ];

  const selectionRow02 = [
    // {
    //   id: "color",
    //   label: (
    //     <>
    //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
    //         <IconsAll.FieldColor />
    //       </div>
    //       Color
    //     </>
    //   ),
    //   children: (
    //     <Color
    //       activeTab={activeTab}
    // setActiveTab={setActiveTab}
    //       addField={addField}
    //       setIsDrawerOpen={setIsDrawerOpen}
    // dataSource={dataSource}
    //     />
    //   ),
    //   showArrow: false,
    // },
    {
      id: "dropdown",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldDropdown />
          </div>
          Dropdown
        </>
      ),
      children: (
        <Dropdown
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
    // {
    //   id: "icon",
    //   label: (
    //     <>
    //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
    //         <IconsAll.FieldIcon />
    //       </div>
    //       Icon
    //     </>
    //   ),
    //   children: (
    //     <Icon
    //       activeTab={activeTab}
    //       setActiveTab={setActiveTab}
    //       addField={addField}
    //       setIsDrawerOpen={setIsDrawerOpen}
    //       dataSource={dataSource}
    //     />
    //   ),
    //   showArrow: false,
    // },
    {
      id: "checkboxes",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldCheckboxes />
          </div>
          Checkboxes
        </>
      ),
      children: (
        <Checkboxes
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
  ];

  const selectionRow03 = [
    // {
    //   id: "checkboxtree",
    //   label: (
    //     <>
    //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
    //         <IconsAll.FieldCheckboxTree />
    //       </div>
    //       Checkboxes (Tree)
    //     </>
    //   ),
    //   children: (
    //     <CheckboxesTree
    //       activeTab={activeTab}
    // setActiveTab={setActiveTab}
    //       addField={addField}
    //       setIsDrawerOpen={setIsDrawerOpen}
    // dataSource={dataSource}
    //     />
    //   ),
    //   showArrow: false,
    // },
    {
      id: "dropdown_multiple",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldDropdownMultiple />
          </div>
          Dropdown (Multiple)
        </>
      ),
      children: (
        <DropdownMultiple
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
    {
      id: "radio",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldRadioButtons />
          </div>
          Radio Buttons
        </>
      ),
      children: (
        <RadioButtons
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
  ];

  // Relational Array
  const Relational = [
    {
      id: "file",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldFile />
          </div>
          File
        </>
      ),
      children: (
        <File
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
    {
      id: "image",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldImage />
          </div>
          Image
        </>
      ),
      children: (
        <Image
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
    {
      id: "files",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldFiles />
          </div>
          Files
        </>
      ),
      children: (
        <Files
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
    // {
    //   id: "builder",
    //   label: (
    //     <>
    //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
    //         <IconsAll.FieldBuilder />
    //       </div>
    //       Builder (M2A)
    //     </>
    //   ),
    //   children: (
    //     <Builder
    //       activeTab={activeTab}
    // setActiveTab={setActiveTab}
    //       addField={addField}
    //       setIsDrawerOpen={setIsDrawerOpen}
    // dataSource={dataSource}
    //     />
    //   ),
    //   showArrow: false,
    // },
  ];

  const RelationalRow01 = [
    {
      id: "list-m2m",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldBuilder />
          </div>
          Many to Many
        </>
      ),
      children: (
        <ManyToMany
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
    {
      id: "list-o2m",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldBuilder />
          </div>
          One to Many
        </>
      ),
      children: (
        <OneToMany
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
    // {
    //   id: "tree_view",
    //   label: (
    //     <>
    //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
    //         <IconsAll.FieldTreeview />
    //       </div>
    //       Tree View
    //     </>
    //   ),
    //   children: (
    //     <TreeView
    //       activeTab={activeTab}
    // setActiveTab={setActiveTab}
    //       addField={addField}
    //       setIsDrawerOpen={setIsDrawerOpen}
    // dataSource={dataSource}
    //     />
    //   ),
    //   showArrow: false,
    // },
    {
      id: "list-m2o",
      label: (
        <>
          <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
            <IconsAll.FieldManyToOne />
          </div>
          Many to One
        </>
      ),
      children: (
        <ManyToOne
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addField={addField}
          setIsDrawerOpen={setIsDrawerOpen}
          dataSource={dataSource}
        />
      ),
      showArrow: false,
    },
  ];

  const RelationalRow02 = [
    // {
    //   id: "translations",
    //   label: (
    //     <>
    //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
    //         <IconsAll.FieldTranslations />
    //       </div>
    //       Translations
    //     </>
    //   ),
    //   children: (
    //     <Translations
    //       activeTab={activeTab}
    // setActiveTab={setActiveTab}
    //       addField={addField}
    //       setIsDrawerOpen={setIsDrawerOpen}
    // dataSource={dataSource}
    //     />
    //   ),
    //   showArrow: false,
    // },
  ];

  // Presentation Array
  // const presentationRow = [
  //   // {
  //   //   id: "divider",
  //   //   label: (
  //   //     <>
  //   //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
  //   //         <IconsAll.FieldDivider />
  //   //       </div>
  //   //       Divider
  //   //     </>
  //   //   ),
  //   //   children: (
  //   //     <DividerTab
  //   //       activeTab={activeTab}
  //   // setActiveTab={setActiveTab}
  //   //       addField={addField}
  //   //       setIsDrawerOpen={setIsDrawerOpen}
  //   // dataSource={dataSource}
  //   //     />
  //   //   ),
  //   //   showArrow: false,
  //   // },
  //   // {
  //   //   id: "button_links",
  //   //   label: (
  //   //     <>
  //   //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
  //   //         <IconsAll.FieldButtonLinks />
  //   //       </div>
  //   //       Button Links
  //   //     </>
  //   //   ),
  //   //   children: (
  //   //     <ButtonLinks
  //   //       activeTab={activeTab}
  //   // setActiveTab={setActiveTab}
  //   //       addField={addField}
  //   //       setIsDrawerOpen={setIsDrawerOpen}
  //   // dataSource={dataSource}
  //   //     />
  //   //   ),
  //   //   showArrow: false,
  //   // },
  //   // {
  //   //   id: "notice",
  //   //   label: (
  //   //     <>
  //   //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
  //   //         <IconsAll.FieldNotice />
  //   //       </div>
  //   //       Notice
  //   //     </>
  //   //   ),
  //   //   children: (
  //   //     <Notice
  //   //       activeTab={activeTab}
  //   // setActiveTab={setActiveTab}
  //   //       addField={addField}
  //   //       setIsDrawerOpen={setIsDrawerOpen}
  //   // dataSource={dataSource}
  //   //     />
  //   //   ),
  //   //   showArrow: false,
  //   // },
  // ];

  // Groups Array
  // const GroupRow = [
  //   // {
  //   //   id: "accordion",
  //   //   label: (
  //   //     <>
  //   //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
  //   //         <IconsAll.FieldAccordion />
  //   //       </div>
  //   //       Accordion
  //   //     </>
  //   //   ),
  //   //   children: (
  //   //     <AccordionTab
  //   //       activeTab={activeTab}
  //   // setActiveTab={setActiveTab}
  //   //       addField={addField}
  //   //       setIsDrawerOpen={setIsDrawerOpen}
  //   // dataSource={dataSource}
  //   //     />
  //   //   ),
  //   //   showArrow: false,
  //   // },
  //   // {
  //   //   id: "detail_group",
  //   //   label: (
  //   //     <>
  //   //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
  //   //         <IconsAll.FieldDetailGroup />
  //   //       </div>
  //   //       Detail Group
  //   //     </>
  //   //   ),
  //   //   children: (
  //   //     <DetailGroup
  //   //       activeTab={activeTab}
  //   // setActiveTab={setActiveTab}
  //   //       addField={addField}
  //   //       setIsDrawerOpen={setIsDrawerOpen}
  //   // dataSource={dataSource}
  //   //     />
  //   //   ),
  //   //   showArrow: false,
  //   // },
  //   // {
  //   //   id: "raw_group",
  //   //   label: (
  //   //     <>
  //   //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
  //   //         <IconsAll.FieldRawGroup />
  //   //       </div>
  //   //       Raw Group
  //   //     </>
  //   //   ),
  //   //   children: (
  //   //     <RawGroup
  //   //       activeTab={activeTab}
  //   // setActiveTab={setActiveTab}
  //   //       addField={addField}
  //   //       setIsDrawerOpen={setIsDrawerOpen}
  //   // dataSource={dataSource}
  //   //     />
  //   //   ),
  //   //   showArrow: false,
  //   // },
  // ];

  // Other Array Start
  // const OtherRow = [
  //   // {
  //   //   id: "collction_item",
  //   //   label: (
  //   //     <>
  //   //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
  //   //         <IconsAll.FieldDropdown />
  //   //       </div>
  //   //       Collection Item Dropdown
  //   //     </>
  //   //   ),
  //   //   children: (
  //   //     <CollectionItemDropdown
  //   //       activeTab={activeTab}
  //   // setActiveTab={setActiveTab}
  //   //       addField={addField}
  //   //       setIsDrawerOpen={setIsDrawerOpen}
  //   // dataSource={dataSource}
  //   //     />
  //   //   ),
  //   //   showArrow: false,
  //   // },
  //   // {
  //   //   id: "hash",
  //   //   label: (
  //   //     <>
  //   //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
  //   //         <IconsAll.FieldHash />
  //   //       </div>
  //   //       Hash
  //   //     </>
  //   //   ),
  //   //   children: (
  //   //     <Hash
  //   //       activeTab={activeTab}
  //   // setActiveTab={setActiveTab}
  //   //       addField={addField}
  //   //       setIsDrawerOpen={setIsDrawerOpen}
  //   // dataSource={dataSource}
  //   //     />
  //   //   ),
  //   //   showArrow: false,
  //   // },
  //   // {
  //   //   id: "slider",
  //   //   label: (
  //   //     <>
  //   //       <div className="custom-tabs | min-h-24 min-w-36 flex items-center justify-center mb-1 rounded-md">
  //   //         <IconsAll.FieldSlider />
  //   //       </div>
  //   //       Slider
  //   //     </>
  //   //   ),
  //   //   children: (
  //   //     <SliderTab
  //   //       activeTab={activeTab}
  //   // setActiveTab={setActiveTab}
  //   //       addField={addField}
  //   //       setIsDrawerOpen={setIsDrawerOpen}
  //   // dataSource={dataSource}
  //   //     />
  //   //   ),
  //   //   showArrow: false,
  //   // },
  // ];

  return (
    <>
      <Flex wrap="wrap" align="center" justify="space-between">
        <Title
          className="flex items-center gap-3 !text-2xl !font-extrabold"
          level={3}
        >
          <TItleIconStyle>
            <IconsAll.ContentIcon strokeColor="#0085CA" />
          </TItleIconStyle>
          {t("New Field (users)")}
        </Title>
        <RightSIdeTitlePart isFilterShow={true} />
      </Flex>

      <article className="my-12">
        <Typography
          className="flex items-center gap-3 text-base font-semibold"
          level={3}
        >
          Group Fields
        </Typography>
        <Divider className="mt-2 mb-4" />
        <TabContainer>
          <TabHeaders>
            {groupField.map((tab) => (
              <TabHeader
                key={tab.id}
                className={`${tab.id === activeTab ? "active-tab" : ""}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </TabHeader>
            ))}
          </TabHeaders>
          {groupField.map((tab) => (
            <TabContent
              key={tab.id}
              visible={tab.id === activeTab ? "YES" : "NO"}
            >
              {tab.children}
            </TabContent>
          ))}
        </TabContainer>
      </article>

      <article className="my-12">
        <Typography
          className="flex items-center gap-3 text-base font-semibold"
          level={3}
        >
          Text & Numbers
        </Typography>
        <Divider className="mt-2 mb-4" />

        <TabContainer>
          <TabHeaders>
            {textNumbersRow.map((tab) => (
              <TabHeader
                key={tab.id}
                className={`${tab.id === activeTab ? "active-tab" : ""}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </TabHeader>
            ))}
          </TabHeaders>
          {textNumbersRow.map((tab) => (
            <TabContent
              key={tab.id}
              visible={tab.id === activeTab ? "YES" : "NO"}
            >
              {tab.children}
            </TabContent>
          ))}
        </TabContainer>

        <TabContainer className="mt-6">
          <TabHeaders>
            {textNumbersRow02.map((tab) => (
              <TabHeader
                key={tab.id}
                className={`${tab.id === activeTab ? "active-tab" : ""}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </TabHeader>
            ))}
          </TabHeaders>
          {textNumbersRow02.map((tab) => (
            <TabContent
              key={tab.id}
              visible={tab.id === activeTab ? "YES" : "NO"}
            >
              {tab.children}
            </TabContent>
          ))}
        </TabContainer>
      </article>

      <article className="my-12">
        <Typography
          className="flex items-center gap-3 text-base font-semibold"
          level={3}
        >
          Selection
        </Typography>
        <Divider className="mt-2 mb-4" />

        <TabContainer className="mt-6">
          <TabHeaders>
            {selectionRow.map((tab) => (
              <TabHeader
                key={tab.id}
                className={`${tab.id === activeTab ? "active-tab" : ""}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </TabHeader>
            ))}
          </TabHeaders>
          {selectionRow.map((tab) => (
            <TabContent
              key={tab.id}
              visible={tab.id === activeTab ? "YES" : "NO"}
            >
              {tab.children}
            </TabContent>
          ))}
        </TabContainer>

        <TabContainer className="mt-6">
          <TabHeaders>
            {selectionRow02.map((tab) => (
              <TabHeader
                key={tab.id}
                className={`${tab.id === activeTab ? "active-tab" : ""}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </TabHeader>
            ))}
          </TabHeaders>
          {selectionRow02.map((tab) => (
            <TabContent
              key={tab.id}
              visible={tab.id === activeTab ? "YES" : "NO"}
            >
              {tab.children}
            </TabContent>
          ))}
        </TabContainer>

        <TabContainer className="mt-6">
          <TabHeaders>
            {selectionRow03.map((tab) => (
              <TabHeader
                key={tab.id}
                className={`${tab.id === activeTab ? "active-tab" : ""}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </TabHeader>
            ))}
          </TabHeaders>
          {selectionRow03.map((tab) => (
            <TabContent
              key={tab.id}
              visible={tab.id === activeTab ? "YES" : "NO"}
            >
              {tab.children}
            </TabContent>
          ))}
        </TabContainer>
      </article>

      <article className="my-12">
        <Typography
          className="flex items-center gap-3 text-base font-semibold"
          level={3}
        >
          Relational
        </Typography>
        <Divider className="mt-2 mb-4" />

        <TabContainer className="mt-8">
          <TabHeaders>
            {Relational.map((tab) => (
              <TabHeader
                key={tab.id}
                className={`${tab.id === activeTab ? "active-tab" : ""}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </TabHeader>
            ))}
          </TabHeaders>
          {Relational.map((tab) => (
            <TabContent
              key={tab.id}
              visible={tab.id === activeTab ? "YES" : "NO"}
            >
              {tab.children}
            </TabContent>
          ))}
        </TabContainer>

        <TabContainer className="mt-8">
          <TabHeaders>
            {RelationalRow01.map((tab) => (
              <TabHeader
                key={tab.id}
                className={`${tab.id === activeTab ? "active-tab" : ""}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </TabHeader>
            ))}
          </TabHeaders>
          {RelationalRow01.map((tab) => (
            <TabContent
              key={tab.id}
              visible={tab.id === activeTab ? "YES" : "NO"}
            >
              {tab.children}
            </TabContent>
          ))}
        </TabContainer>

        <TabContainer className="mt-8">
          <TabHeaders>
            {RelationalRow02.map((tab) => (
              <TabHeader
                key={tab.id}
                className={`${tab.id === activeTab ? "active-tab" : ""}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </TabHeader>
            ))}
          </TabHeaders>
          {RelationalRow02.map((tab) => (
            <TabContent
              key={tab.id}
              visible={tab.id === activeTab ? "YES" : "NO"}
            >
              {tab.children}
            </TabContent>
          ))}
        </TabContainer>
      </article>

      {/* <article className="my-12">
        <Typography
          className="flex items-center gap-3 text-base font-semibold"
          level={3}
        >
          Presentation
        </Typography>
        <Divider className="mt-2 mb-4" />

        <TabContainer className="mt-6">
          <TabHeaders>
            {presentationRow.map((tab) => (
              <TabHeader
                key={tab.id}
                className={`${tab.id === activeTab ? "active-tab" : ""}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </TabHeader>
            ))}
          </TabHeaders>
          {presentationRow.map((tab) => (
            <TabContent key={tab.id} visible={tab.id === activeTab ? "YES" : "NO"}>
              {tab.children}
            </TabContent>
          ))}
        </TabContainer>
      </article>

      <article className="my-12">
        <Typography
          className="flex items-center gap-3 text-base font-semibold"
          level={3}
        >
          Groups
        </Typography>
        <Divider className="mt-2 mb-4" />

        <TabContainer className="mt-6">
          <TabHeaders>
            {GroupRow.map((tab) => (
              <TabHeader
                key={tab.id}
                className={`${tab.id === activeTab ? "active-tab" : ""}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </TabHeader>
            ))}
          </TabHeaders>
          {GroupRow.map((tab) => (
            <TabContent key={tab.id} visible={tab.id === activeTab ? "YES" : "NO"}>
              {tab.children}
            </TabContent>
          ))}
        </TabContainer>
      </article>

      <article className="my-12">
        <Typography
          className="flex items-center gap-3 text-base font-semibold"
          level={3}
        >
          Other
        </Typography>
        <Divider className="mt-2 mb-4" />

        <TabContainer className="mt-6">
          <TabHeaders>
            {OtherRow.map((tab) => (
              <TabHeader
                key={tab.id}
                className={`${tab.id === activeTab ? "active-tab" : ""}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </TabHeader>
            ))}
          </TabHeaders>
          {OtherRow.map((tab) => (
            <TabContent key={tab.id} visible={tab.id === activeTab ? "YES" : "NO"}>
              {tab.children}
            </TabContent>
          ))}
        </TabContainer>
      </article> */}
    </>
  );
};

export default CreateNewFields;
