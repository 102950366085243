import { Checkbox } from "antd";
import React, { useEffect } from "react";

const CustomCheckbox = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData({
        key: item.path,
        value: item.schema_definition?.default || [],
      });
    }
  }, [formData]);
  const onChange = (checkedValues) => {
    updateFormData({ key: item.path, value: checkedValues });
  };
  return (
    <Checkbox.Group
      onChange={onChange}
      value={formData[item?.path]}
      options={item?.meta?.options?.choices}
      className={`${isValidationError ? "border-[red]" : ""}`}
      disabled={!!item?.meta?.readonly}
    ></Checkbox.Group>
  );
};

export default CustomCheckbox;
