import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../Common/Breadcrumb";
import { Card, Divider } from "antd";
import Apis from "../../../helpers/Apis";
import axios from "axios";
import { useParams } from "react-router-dom";
import styles from "../index.module.css";
import withRouter from "../../../Common/withRouter";
import {
  simpleSchemaToTreeSchema,
  toDotNotation,
} from "../../../helpers/utils";
import CustomTree from "../../../Components/Tree";
import { toast } from "react-toastify";

const Mapping = () => {
  const { code } = useParams();
  document.title = `Collection | ${code.toLocaleUpperCase().replaceAll("_", " ")} | ${process.env.REACT_APP_PAGE_TITLE}`;
  const [schema, setSchema] = useState({});
  const [treeData, setTreeData] = useState([]);
  const [defaultCheckedKeys, setDefaultCheckedKeys] = useState({
    listingProjection: [],
    nestedLevel1: [],
  });

  const getProjectionSchema = async (modelCode) => {
    try {
      let response = await axios.get(
        Apis.GET_PROJECTION_SCHEMA_BY_CODE({
          schema: modelCode,
          isOnlySchema: true,
        }),
      );
      response = response.data.payload.data;
      const schemaDotNotation = toDotNotation({ ...response?.schema });
      setSchema(schemaDotNotation);

      const getTreeData = simpleSchemaToTreeSchema(
        response?.schema,
        "",
        ".",
        {},
      );
      setTreeData(getTreeData);

      const listingDotedSchema = toDotNotation({
        ...response?.listing_projection,
      });
      const listingCheckedKey = Object.keys(listingDotedSchema).map(
        (res) => res,
      );

      const nested1DotedSchema = toDotNotation({ ...response?.nested_level_1 });
      const nested1CheckedKey = Object.keys(nested1DotedSchema).map(
        (res) => res,
      );

      setDefaultCheckedKeys({
        listingProjection: listingCheckedKey,
        nestedLevel1: nested1CheckedKey,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async (keys, id) => {
    const dotedSchema = toDotNotation({ ...schema });
    const formData = new FormData();
    keys.map((k) => {
      formData.append(`${id}.${k}`, dotedSchema[k]);
      return k;
    });

    try {
      await axios.patch(
        Apis.UPDATE_LISTING_PROJECTION_SCHEMA({ code }),
        formData,
      );
      toast.success("Record updated successfully !!");
      return true;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return false;
    }
  };

  useEffect(() => {
    getProjectionSchema(code);
  }, [code]);

  return (
    <>
      <Breadcrumb
        mainTitle="NOX"
        pageTitle={code.toLocaleUpperCase().replaceAll("_", " ")}
        items={[
          {
            route: "/collections",
            displayName: "Collections",
          },
          {
            route: "#",
            displayName: "Collection Field Mappings",
          },
        ]}
      />
      <Card className={styles.mapping_card}>
        <div className={styles.main_container}>
          <CustomTree
            treeData={treeData}
            defaultCheckedKeys={defaultCheckedKeys?.listingProjection}
            onSave={handleSave}
            title="Table harder"
            id={"listing_projection"}
          />
          <Divider type="vertical" className={styles.mapping_divider} />
          <CustomTree
            treeData={treeData}
            defaultCheckedKeys={defaultCheckedKeys?.nestedLevel1}
            onSave={handleSave}
            title="Nested Field Projection"
            id={"nested_level_1"}
            maxSelection={1}
          />
        </div>
      </Card>
    </>
  );
};

export default withRouter(Mapping);
