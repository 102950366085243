import React from "react";
import { Flex } from "antd";
import SubMenu from "../../Components/SubMenu";
import { BsDatabase } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Media = () => {
  const { t } = useTranslation();

  const subMenus = [
    {
      name: t("test") + " " + t("media"),
      link: "/test",
      icon: <BsDatabase size={20} />,
    },
  ];

  return (
    <>
      <Flex>
        <SubMenu
          isSubMenuLogo={true}
          isSubMenuVersion={true}
          items={subMenus}
        />
        <h1>Media Library</h1>
      </Flex>
    </>
  );
};

export default Media;
