import { Card, Table } from "antd";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Common/Breadcrumb";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import axios from "axios";
import Apis from "../../helpers/Apis";

const index = () => {
  const [roles, setRoles] = useState([]);

  const columns = [
    {
      title: "Role Code",
      dataIndex: "code",
    },
    {
      title: "Operation",
      dataIndex: "operation",
      align: "center",
      render: (_, record) => {
        return (
          <span>
            <Link to={`/role/${record._id}`}>
              <EyeOutlined size={18} />
            </Link>
          </span>
        );
      },
    },
  ];

  const getRoles = async () => {
    axios.get(Apis.GET_ROLES({})).then((response) => {
      setRoles(response.data.payload.result);
    });
  };

  useEffect(() => {
    getRoles();

    return () => {};
  }, []);

  return (
    <>
      <Breadcrumb mainTitle="NOX" pageTitle="Role & Permissions" />
      <Card>
        <Table columns={columns} dataSource={roles} />
      </Card>
    </>
  );
};

export default index;
