import { Select } from "antd";
import React, { useEffect } from "react";
import { getTranslatedLabel } from "../../../helpers/utils";

const CustomDropdownMultiple = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData({
        key: item.path,
        value: item?.schema_definition?.default || [],
      });
    }
  }, [formData]);
  return (
    <>
      <Select
        mode="multiple"
        options={item?.meta?.options?.choices}
        placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
        maxTagCount={`responsive`}
        value={formData?.[item.path]}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        fieldNames={{
          label: "label",
          value: "value",
        }}
        onChange={(value) => {
          updateFormData({ key: item.path, value: value });
        }}
        disabled={!!item?.meta?.readonly}
        className={`${isValidationError ? "border-[red]" : ""}`}
      />
    </>
  );
};

export default CustomDropdownMultiple;
