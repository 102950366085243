import { Radio } from "antd";
import React, { useEffect } from "react";

const CustomRadio = ({ item, updateFormData, formData, isValidationError }) => {
  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData({
        key: item.path,
        value: item.schema_definition?.default || "",
      });
    }
  }, [formData]);
  return (
    <Radio.Group
      onChange={(e) => {
        updateFormData({ key: item.path, value: e.target.value || "" });
      }}
      options={item?.meta?.options?.choices}
      value={formData?.[item?.path]}
      className={`font-normal ${isValidationError ? "border-[red]" : ""}`}
      disabled={!!item?.meta?.readonly}
    ></Radio.Group>
  );
};

export default CustomRadio;
