import React from "react";
import { Flex } from "antd";
import SubMenu from "../../Components/SubMenu";

const Help = () => {
  return (
    <>
      <Flex>
        <SubMenu isSubMenuLogo={true} isSubMenuVersion={true} />
        <h1>Help</h1>
      </Flex>
    </>
  );
};

export default Help;
