import React, { useEffect, useState } from "react";
import { Drawer, Dropdown, Flex, Modal, notification, Table, Tabs } from "antd";
import IconsAll from "../../Components/IconsAll";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import { MenuOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import CollectieSetup from "../../Components/Drawers/CollectieSetup";
import AppInfo from "../../Components/AppInfo";
import { useTranslation } from "react-i18next";
import { themes } from "../../config";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import axiosInstance from "../../helpers/axios";
import Apis from "../../helpers/Apis";
import { store } from "../../Redux/store";

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

export const TableSort = styled(Table)`
.ant-table {
  background: none;
  table{
    background-color:transparent;
    border-spacing: 0 1rem;
    .ant-table-row{
      .ant-table-cell{
        &:first-child{
          border-radius: 8px 0 0 8px;
          border-left: 1px solid ${() =>
            themes[useSelector((state) => state.theme.name)].components.Table
              .borderColor} !important;
        }
        &:last-child{
          border-radius: 0 8px 8px 0;
          border-right: 1px solid ${() =>
            themes[useSelector((state) => state.theme.name)].components.Table
              .borderColor} !important;
        }
      }
    }
  }
  .gray-clr {
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].token.grayClr};
  }
  .ant-table-thead{
    display: none;
  }
  .ant-table-cell,
  .ant-table-cell.ant-table-cell-fix-left{
    background-color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Table
        .sortedBg} !important;
      }
  }
  .ant-table-cell {
    border-bottom: 1px solid
        ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .borderColor} !important;
      border-top: 1px solid
        ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .borderColor} !important;
    &.ant-table-column-has-sorters {
      &:hover {
        background-color: ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .sortedBg} !important;
      }
    }
    &.ant-table-column-sort {
      background-color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Table
          .sortedBg};
    }
  }

  .imgLocationClr {
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Table
        .imgLocationClr};
    margin-left: 10px;
  }

}
`;

const DrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: white;
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    padding: 0;
    .ant-tabs {
      @media screen and (max-width: 991px) {
        flex-flow: wrap;
      }
      .ant-tabs-nav {
        padding-top: 40px;
        width: min(100%, 200px);
        min-height: 100vh;
        background-color: #f5f6f7;
        @media screen and (max-width: 991px) {
          width: 100%;
          min-height: auto;
        }
        .ant-tabs-nav-wrap {
          &:before,
          &:after,
          .ant-tabs-ink-bar {
            display: none;
          }
          .ant-tabs-nav-list {
            padding-inline: 10px;
            @media screen and (max-width: 991px) {
              flex-direction: row;
              margin-bottom: 1rem;
            }
            .ant-tabs-tab {
              border-radius: 8px;
              margin-top: 5px;
              > div {
                color: #000 !important;
                font-size: 16px;
                font-style: normal;
              }
              &.ant-tabs-tab-active {
                background-color: #ebeced;
              }
              @media screen and (max-width: 991px) {
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .ant-tabs-content-holder {
      .ant-tabs-tabpane {
        padding-left: 0;
      }
    }
  }
`;

const DataModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    {
      key: "sort",
      width: 40,
      fixed: "left",
    },
    {
      dataIndex: "icon",
      width: 40,
      fixed: "left",
    },
    {
      dataIndex: "collection_name",
      render: (record, rowData) => (
        <Link
          className={rowData?.visible ? "" : "opacity-25"}
          to={`/instellingen/data-model-change-collection/${rowData?._id}`}
          // onClick={() => {
          //   dispatch(
          //     setActiveTab({
          //       page: "instellingen",
          //       tab: "Data Model Change Collection",
          //       redirectionLink: `/instellingen/data-model-change-collection/${rowData?._id}`,
          //     }),
          //   );
          // }}
        >
          {record}
        </Link>
      ),
    },
    {
      dataIndex: "expand",
      width: 40,
      fixed: "right",
    },
    {
      dataIndex: "_id",
      width: 40,
      fixed: "right",
      render: (record, rowData) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: "view",
                  label: (
                    <Flex gap={"8px"}>
                      <IconsAll.ContentIcon strokeColor="#88909A" />
                      View Content
                    </Flex>
                  ),
                },
                {
                  key: "visible",
                  label: (
                    <Flex gap={"8px"}>
                      {rowData?.visible ? (
                        <IconsAll.EyeCloseIcon className="text-xs" />
                      ) : (
                        <IconsAll.OpenEyeIcon
                          strokeColor="#88909A"
                          className="text-xs"
                        />
                      )}
                      Make Collection {rowData?.visible ? "Hidden" : "Visible"}
                    </Flex>
                  ),
                },
                {
                  key: "delete",
                  label: (
                    <Flex gap={"8px"}>
                      <IconsAll.TrashIcon className="text-xs" />
                      Delete Collection
                    </Flex>
                  ),
                },
              ],
              onClick: (e) => {
                if (e.key === "delete") {
                  Modal.confirm({
                    title: `Delete Collection`,
                    content: `Are you sure you want to delete this collection permanently? This will delete the collection and all items along with relational fields in it. This action is permanent.
                      `,
                    icon: null,
                    okText: "Delete Collection",
                    okType: "primary",
                    cancelText: "Cancel",
                    maskClosable: true,
                    className: "modal-delete",
                    onOk() {
                      handleCollectionVisibility(record, e.key);
                    },
                  });
                } else if (e.key === "view") {
                  navigate(`/content/${record}`);
                  dispatch(
                    setActiveTab({
                      page: "content",
                      tab: record,
                      redirectionLink: `/content/${record}`,
                    }),
                  );
                } else if (e.key === "visible") {
                  handleCollectionVisibility(record, e.key, rowData?.visible);
                }
              },
            }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <IconsAll.MoreIcon />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  const handleCollectionVisibility = async (id, type, visible) => {
    if (type === "delete") {
      try {
        const resp = await axiosInstance.delete(Apis.DELETE_A_SCHEMA(id), {
          headers: {
            "x-tenant-id": store.getState().user.userId,
          },
        });
        if (resp.status === 200) {
          notification.success({ message: "Collection deleted successfully" });
          init();
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      try {
        const resp = await axiosInstance.patch(
          Apis.UPDATE_SCHEMA_VISIBILITY(id),
          {
            visible: !visible,
          },
          {
            headers: {
              "x-tenant-id": store.getState().user.userId,
            },
          },
        );
        if (resp.status === 200) {
          notification.success({ message: "Visibility updated successfully" });
          setDataSource((prev) => {
            return prev.map((item) =>
              item._id === id ? { ...item, visible: !visible } : item,
            );
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const resp = await axiosInstance.get(Apis.GET_ALL_SCHEMAS({}), {
        headers: {
          "x-tenant-id": store.getState().user.userId,
        },
      });
      if (resp.status === 200) setDataSource(resp.data.payload.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  // const text = `it can be found as a welcome guest in many households across the world.`;

  // const items = [
  //   {
  //     key: "1",
  //     label: t("system") + " " + t("collections"),
  //     children: <p>{text}</p>,
  //   },
  // ];

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const drawerItems = [
    {
      key: "1",
      label: t("collection") + " " + t("setup"),
      children: (
        <CollectieSetup setIsDrawerOpen={setIsDrawerOpen} init={init} />
      ),
      closable: true,
      closeIcon: <IconsAll.BlogIcon />,
    },
    // {
    //   key: "2",
    //   label: t("optional") + " " + t("fields").toLowerCase(),
    //   children: <OptioneleVelden />,
    // },
  ];

  return (
    <Flex className="w-full main-content-wrap">
      <div className="px-4 xl:px-16 w-full left-part">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle={t("data") + " " + t("model")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.DataModalIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart
            isFilterShow={true}
            // folderButton={true}
            addButton={true}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />
          <DrawerStyle
            width={870}
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            closeIcon={<IconsAll.CloseIconCustom />}
          >
            <Tabs
              defaultActiveKey="1"
              items={drawerItems}
              tabPosition={"left"}
            />
          </DrawerStyle>
        </div>

        <section className="container max-w-4xl mt-9">
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              // rowKey array
              items={dataSource.map((i) => i.key)}
              strategy={verticalListSortingStrategy}
            >
              <TableSort
                pagination={false}
                components={{
                  body: {
                    row: Row,
                  },
                }}
                columns={columns}
                rowKey={(record) => record.collection_id}
                dataSource={dataSource}
              />
            </SortableContext>
          </DndContext>
          {/* <Collapse defaultActiveKey={["1"]} ghost items={items} /> */}
        </section>
      </div>
      <AppInfo />
    </Flex>
  );
};

export default DataModal;
