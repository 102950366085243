import { useEffect } from "react";
import _ from "lodash";

const useDebouncedEffect = (effect, dependencies, delay) => {
  useEffect(() => {
    const handler = _.debounce(() => {
      effect();
    }, delay);

    handler();

    return () => {
      handler.cancel();
    };
  }, [...dependencies, delay]);
};

export default useDebouncedEffect;
