import TextArea from "antd/es/input/TextArea";
import React, { useEffect } from "react";
import { getTranslatedLabel } from "../../../helpers/utils";

const CustomTextArea = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData({
        key: item.path,
        value: item.schema_definition?.default || "",
      });
    }
  }, [formData]);
  return (
    <TextArea
      placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
      onChange={(e) =>
        updateFormData({ key: item?.path, value: e.target.value || "" })
      }
      value={formData?.[item?.path] || ""}
      defaultValue={item?.schema_definition?.default || ""}
      className={`font-normal rounded-xl ${isValidationError ? "border-[red]" : ""}`}
      disabled={!!item?.meta?.readonly}
    />
  );
};

export default CustomTextArea;
