import { Checkbox } from "antd";
import React, { useEffect } from "react";

const CustomToggle = ({
  item,
  updateFormData,
  formData,
  isValidationError,
}) => {
  const onChange = (e) => {
    updateFormData({ key: item.path, value: e.target.checked });
  };

  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData({
        key: item.path,
        value: !!item.schema_definition?.default,
      });
    }
  }, [formData]);

  return (
    <Checkbox
      onChange={onChange}
      checked={!!formData?.[item.path]}
      defaultChecked={!!item?.schema_definition?.default}
      className={`font-normal ${isValidationError ? "border-[red]" : ""}`}
      disabled={!!item?.meta?.readonly}
    >
      Enable
    </Checkbox>
  );
};

export default CustomToggle;
