import { Input, InputNumber } from "antd";
import React, { useEffect } from "react";
import { getTranslatedLabel } from "../../../helpers/utils";

const CustomInput = ({ item, updateFormData, formData, isValidationError }) => {
  useEffect(() => {
    if (formData?.[item.path] === undefined) {
      updateFormData({
        key: item.path,
        value: item.schema_definition?.default
          ? item.schema_definition?.default
          : item?.type === "String"
            ? ""
            : item?.type === "Number"
              ? 0
              : null,
      });
    }
  }, [formData]);

  return item?.type === "Number" ? (
    <InputNumber
      defaultValue={
        item?.schema_definition?.default &&
        item?.schema_definition?.default !== ""
          ? item?.schema_definition?.default
          : 0
      }
      placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
      id={item?._id}
      value={formData?.[item.path]}
      onChange={(e) => updateFormData({ key: item.path, value: e })}
      disabled={!!item?.meta?.readonly}
      className={`min-h-14 flex items-center rounded-lg placeholder:font-normal px-3 w-full leading-10 overflow-hidden ${isValidationError ? "border-[red]" : ""}`}
    />
  ) : (
    <Input
      defaultValue={item?.schema_definition?.default || ""}
      placeholder={getTranslatedLabel(item?.meta?.options?.placeholder)}
      id={item?._id}
      disabled={!!item?.meta?.readonly}
      value={formData?.[item.path]}
      onChange={(e) =>
        updateFormData({ key: item.path, value: e.target.value })
      }
      className={`min-h-14 rounded-xl placeholder:font-normal px-3 font-normal ${isValidationError ? "border-[red]" : ""}`}
    />
  );
};

export default CustomInput;
