import React, { useContext } from "react";
import { Flex, Form, Row, Col } from "antd";
import IconsAll from "../../Components/IconsAll";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import StandardLightTheme from "../../Components/StandardLightTheme";
import StandardDarkTheme from "../../Components/StandardDarkTheme";
import AppInfo from "../../Components/AppInfo";
import { SidebarContext } from "../../Context/submenuContext";
import Backdrop from "../../Layouts/Backdrop";
import { tabletBreakpoint } from "../../helpers/utils";
import useScreenWidth from "../../Common/Hooks/ScreenWidth";
import { useTranslation } from "react-i18next";

// const { TextArea } = Input;

// const SelectFileStyle = styled(Upload)`
//   width: 100%;
//   .ant-upload {
//     width: 100%;
//     .ant-btn {
//       border-radius: 12px;
//       border: 1px solid var(--Platinum, #e1e3e6);
//       background: ${() =>
//         themes[useSelector((state) => state.theme.name)].components.Upload
//           .colorPrimary};
//       display: flex;
//       align-items: center;
//       min-height: 56px;
//       width: 100%;
//       font-size: 16px;
//       font-weight: normal;
//       color: #88909a;
//       padding-left: 10px;
//       svg {
//         width: 35px;
//         height: 35px;
//         padding: 8px;
//         background: #e1e3e6;
//         border-radius: 6px;
//         fill: #88909a;
//       }
//     }
//   }
// `;

// const props = {
//   name: "file",
//   action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
//   headers: {
//     authorization: "authorization-text",
//   },
//   onChange(info) {
//     if (info.file.status !== "uploading") {
//       console.log(info.file, info.fileList);
//     }
//     if (info.file.status === "done") {
//       message.success(`${info.file.name} file uploaded successfully`);
//     } else if (info.file.status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
// };

const Uiterlijk = () => {
  const { t } = useTranslation();
  const submenu = useContext(SidebarContext);
  const screenWidth = useScreenWidth();
  const isTabletOrSmaller = screenWidth <= tabletBreakpoint;
  const isSubMenuActive = submenu?.activatedItem === "uiterlijk";

  return (
    <Flex className="w-full main-content-wrap">
      {isSubMenuActive && isTabletOrSmaller && (
        <Backdrop onClick={() => submenu.toggleActivation()} />
      )}
      <div className="px-4 xl:px-16 w-full left-part">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle={t("appearance")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.UiterlijkIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart disabledSubmitButton={true} />
        </div>

        <section className="container max-w-4xl mt-9">
          <Form layout="vertical">
            <Row gutter={28} className="pb-4">
              {/* <Col xs={24} md={12}>
                <Form.Item
                  label={t("project") + " " + t("color")}
                  className="font-bold"
                >
                  <ColorPicker
                    showText
                    className="px-[10px] py-[10px] w-full min-h-14 rounded-lg font-normal justify-start border-gray-300"
                  />
                  <span className="font-normal text-sm opacity-40 mt-2 block">
                    {t("primary_project_color")}
                  </span>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("project") + " " + t("color")}
                  className="font-bold"
                >
                  <ColorPicker
                    showText
                    className="px-[10px] py-[10px] w-full min-h-14 rounded-lg font-normal justify-start border-gray-300"
                  />
                  <span className="font-normal text-sm opacity-40 mt-2 block">
                    {t("used_icons_msg")}
                  </span>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={t("logo") + " " + t("company")}
                  className="font-bold"
                >
                  <SelectFileStyle {...props}>
                    <Button
                      className="dark:bg-inputField dark:!border-border-theme"
                      icon={<BsFolder2Open size={20} />}
                    >
                      {t("select") + " " + t("file")}
                    </Button>
                  </SelectFileStyle>
                  <span className="font-normal text-sm opacity-40 mt-2 block">
                    {t("logo_msg")}
                  </span>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label={t("favicon")} className="font-bold">
                  <SelectFileStyle {...props}>
                    <Button
                      className="dark:bg-inputField dark:!border-border-theme"
                      icon={<BsFolder2Open size={20} />}
                    >
                      {t("select") + " " + t("file")}
                    </Button>
                  </SelectFileStyle>
                  <span className="font-normal text-sm opacity-40 mt-2 block">
                    {t("logo_msg")}
                  </span>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item className="font-bold">
                  <h3 className="flex items-center gap-3 text-2xl font-extrabold">
                    <IconsAll.ThemeIcon />
                    {t("theme") + " " + t("setting").toLowerCase()}
                  </h3>
                  <Divider className="border-1 w-full my-2" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item
                  label={t("standard") + " " + t("appearance")}
                  className="font-bold"
                >
                  <Select
                    placeholder="jack"
                    options={[
                      { value: "jack", label: "Jack" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "yiminghe" },
                    ]}
                  />
                </Form.Item>
              </Col> */}
              <Col className="gutter-row" span={24}>
                <StandardLightTheme />
              </Col>
              {/* <Col className="gutter-row" span={24}>
                <span className="flex items-center gap-1 text-base font-extrabold">
                  {t("light") + " " + t("theme") + " " + t("customization")}
                </span>
              </Col>
              <Col className="gutter-row" span={24}>
                <LightThemeCustomization />
              </Col> */}
              <Col className="gutter-row mt-5" span={24}>
                <StandardDarkTheme />
              </Col>
              {/* <Col className="gutter-row" span={24}>
                <span className="flex items-center gap-1 text-base font-extrabold">
                  {t("dark") + " " + t("theme") + " " + t("customization")}
                </span>
              </Col>
              <Col className="gutter-row" span={24}>
                <LightThemeCustomization />
              </Col>
              <Col className="gutter-row mt-5" span={24}>
                <span className="flex items-center gap-1 text-base font-extrabold">
                  {t("custom") + " " + t("css")}
                </span>
              </Col>
              <Col className="gutter-row mt-2" span={24}>
                <TextArea
                  rows={4}
                  placeholder="Design is Pending"
                  maxLength={6}
                />
              </Col> */}
            </Row>
          </Form>
        </section>
      </div>
      <AppInfo />
    </Flex>
  );
};

export default Uiterlijk;
