import React, { useId, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Checkbox,
  Divider,
  Button,
  notification,
  TreeSelect,
} from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { themes } from "../../../config";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import { convertToTreeData } from "../../../helpers/utils";

const FormStyling = styled(Form)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.colorGrayrBg};
  border-top: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  border-bottom: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  padding: 34px;
`;

const options = [
  "Undo",
  "Redo",
  "Bold",
  "Italic",
  "Underline",
  "Strikethrough",
  "Subscript",
  "Superscript",
  "Select Font",
  "Select Font Size",
  "Heading 1",
  "Heading 2",
  "Heading 3",
  "Heading 4",
  "Heading 5",
  "Heading 6",
  "Align Left",
  "Align Center",
  "Align Right",
  "Align Justify",
  "Align None",
  "Indent",
  "Outdent",
  "Numbered List",
  "Bullet List",
  "Foreground Color",
  "Background Color",
  "Remove Format",
  "Cut",
  "Copy",
  "Paste",
  "Remove",
  "Select All",
  "Blockquote",
  "Add/Edit Link",
  "Remove Link",
  "Add/Edit Image",
  "Add/Edit Media",
  "Table",
  "Horizontal Rule",
  "Edit Source Code",
  "Full Screen",
  "View invisible elements",
  "Directionality",
].map((s) => {
  return { value: s, label: s };
});

const sharedProps = {
  mode: "multiple",
  style: {
    width: "100%",
  },
  options,
  placeholder: "Select Item...",
  maxTagCount: "responsive",
};

const InvertedCheckBox = styled(Checkbox)`
  border-radius: 12px;
  border: 1px solid
    ${() =>
      themes[useSelector((state) => state.theme.name)].components.Input
        .colorBorder};
  background: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Input
      .colorBgContainer};
  padding: 1rem;
  width: 100%;
  font-weight: 400;
`;

const WYSIWYG = ({
  activeTab,
  addField,
  setIsDrawerOpen,
  dataSource,
  setActiveTab,
}) => {
  const { t } = useTranslation();
  const id = useId();
  const [value, setValue] = useState([]);
  const selectProps = {
    value,
    onChange: setValue,
  };
  const [form] = Form.useForm();
  const params = useParams();
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleSubmit = async () => {
    let isError;
    try {
      const values = await form.validateFields();
      isError = dataSource?.fields?.some((f) => f?.field === values.name);
      if (isError) {
        notification.error({ message: "Please enter unique key" });
        return;
      }
      let field = {
        schema_id: params.field,
        field: values.name,
        type: values.type,
        path: selectedGroup?.value
          ? selectedGroup?.value + "." + values?.name
          : values?.name,
        field_type: selectedGroup ? "Object" : "Single",
        meta: {
          field: values.name,
          interface: activeTab,
          width: "full",
          sort: dataSource?.fields?.length + 1,
          nullable: true,
          options: {
            font: values.font,
            static_access_token: values.static_access_token,
            toolbar: values.toolbar,
            folder: values.folder,
          },
          hidden: false,
          required: !!values.required,
        },
        schema_definition: {
          name: values.name,
          type: values.type,
          default: values.default,
        },
      };
      addField(field);
      await form.resetFields();
      setActiveTab(null);
    } catch (error) {
      console.log("error", error);
      notification.error({ message: "Something went wrong" });
      isError = true;
    } finally {
      !isError && setIsDrawerOpen(false);
    }
  };

  return (
    <FormStyling
      layout="vertical"
      className="mt-12"
      form={form}
      initialValues={{
        type: "String",
        font: "Serif",
        folder: "Root Folder",
      }}
    >
      <Row gutter={28}>
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Key")}
            className="font-bold"
            name={`name`}
            htmlFor={`${id}-key`}
            rules={[
              {
                required: true,

                max: 50,
              },
            ]}
          >
            <Input
              placeholder={t("A unique common name")}
              onChange={(e) => {
                form.setFieldValue(
                  "name",
                  e.target.value
                    .replace(/[^a-zA-Z0-9_ ]/g, "")
                    .replace(/ /g, "_")
                    ?.toLowerCase(),
                );
              }}
              id={`${id}-key`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Group")}
            className="font-bold"
            htmlFor={`${id}-group`}
          >
            <TreeSelect
              id={`${id}-group`}
              showSearch
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="Select Group"
              allowClear
              treeDefaultExpandAll
              onSelect={(_, e) => setSelectedGroup(e)}
              treeData={convertToTreeData(
                dataSource?.fields?.filter((f) => {
                  return ["Object"]?.includes(f?.type);
                }),
                "tree-select",
              )}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("type")}
            htmlFor={`${id}-type`}
            className="font-bold"
            rules={[{ required: true }]}
            name={`type`}
          >
            <Select
              size="large"
              placeholder="Text"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-type`}
              disabled={true}
              options={[
                {
                  value: "Text",
                  label: "Text",
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Required")}
            htmlFor={`${id}-requireValue`}
            className="font-bold"
            name={`required`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-requireValue`}>
              Require value to be set on creation
            </InvertedCheckBox>
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={24}>
          <Form.Item
            label={t("Default Value")}
            htmlFor={`${id}-null`}
            className="font-bold"
            name={`default`}
          >
            {/* <Input
              placeholder={t("NULL")}
              id={`${id}-null`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
            /> */}

            <ReactQuill theme="snow" />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24}>
          <Divider className="mt-3 mb-6" />
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Toolbar")}
            htmlFor={`${id}-toolbar`}
            className="font-bold"
            name={`toolbar`}
          >
            <Select {...sharedProps} {...selectProps} id={`${id}-toolbar`} />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Font")}
            htmlFor={`${id}-font`}
            className="font-bold"
            name={`font`}
          >
            <Select
              size="large"
              placeholder="font"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-font`}
              options={[
                {
                  value: "Serif",
                  label: "Serif",
                },
                {
                  value: "Sans Serif",
                  label: "Sans Serif",
                },
                {
                  value: "Monospace",
                  label: "Monospace",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Folder")}
            htmlFor={`${id}-footer`}
            className="font-bold"
            name={`folder`}
            help={
              <span className="text-sm text-black font-light italic">
                Folder for uploaded files. Does not affect existing files.
              </span>
            }
          >
            <Select
              size="large"
              placeholder="Root Folder"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-footer`}
              options={[
                {
                  value: "Root Folder",
                  label: "Root Folder",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Static Access Token")}
            htmlFor={`${id}-static_access_token`}
            className="font-bold"
            name={`static_access_token`}
          >
            <div>
              <Input
                placeholder={t("")}
                id={`${id}-static_access_token`}
                className="min-h-14 rounded-xl placeholder:font-normal px-6"
              />
              <span className="text-sm text-black font-light italic">
                {"Static access token is appended to the assets' URL"}
              </span>
            </div>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24}>
          <Button
            onClick={handleSubmit}
            className="rounded-3xl bg-btn-dark text-white px-5 py-3 mt-4 font-medium h-auto w-full"
          >
            Save
          </Button>
          <Button type="link" className="mt-4 font-medium h-auto w-full">
            Continue in Advanced Field Creation Mode
          </Button>
        </Col>
      </Row>
    </FormStyling>
  );
};

export default WYSIWYG;
