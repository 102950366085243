import React, { useContext, useEffect } from "react";
import { Flex } from "antd";
import SubMenu from "../../Components/SubMenu";
import { SidebarContext } from "../../Context/submenuContext";
import { tabletBreakpoint } from "../../helpers/utils";
import useScreenWidth from "../../Common/Hooks/ScreenWidth";
import { useTranslation } from "react-i18next";
import IconsAll from "../../Components/IconsAll";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import DataModal from "./DataModal";
import Toegangscontrole from "./Toegangscontrole";
import Webhooks from "./Webhooks";
import Flows from "./Flows";
import Extenties from "./Extenties";
import Uiterlijk from "./Uiterlijk";
import Bookmarks from "./Bookmarks";
import Vertalingen from "./Vertalingen";
import MeldeenBug from "./MeldeenBug";
import RequestFeature from "./RequestFeature";
import ChangeDataModalCollection from "./ChangeDataModalCollection";
import Permissions from "./Permissions";
import AddVertalingen from "./AddVertalingen";

const Gebruikers = () => {
  const { t } = useTranslation();
  const submenu = useContext(SidebarContext);
  const screenWidth = useScreenWidth();
  const isTabletOrSmaller = screenWidth <= tabletBreakpoint;
  const isSubMenuActive = submenu?.activatedItem === "Gebruikers";
  const path = location.pathname.split("/");
  const activeTab = useSelector((state) => state.activeTab[path[1]]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const subMenus = [
    {
      name: t("data") + " " + t("model"),
      link: "/instellingen/data-model",
      icon: <IconsAll.DataModalIcon />,
    },
    {
      name: t("access_control"),
      link: "/instellingen/toegangscontrole",
      icon: <IconsAll.ToegangsControleIcon />,
    },
    // {
    //   name: t("webhooks"),
    //   link: "/instellingen/webhooks",
    //   icon: <IconsAll.WebhooksIcon />,
    //   disabled: true
    // },
    // {
    //   name: t("flows"),
    //   link: "/instellingen/flows",
    //   icon: <IconsAll.FlowsIcon />,
    // },
    // {
    //   name: t("extentions"),
    //   link: "/instellingen/extenties",
    //   icon: <IconsAll.ExtentiesIcon />,
    //   divider: true,
    // },
    // {
    //   name: t("setting"),
    //   link: "/instellingen",
    //   icon: <IconsAll.InstellingenIcon />,
    // },
    {
      name: t("appearance"),
      link: "/instellingen/uiterlijk",
      icon: <IconsAll.UiterlijkIcon />,
    },
    // {
    //   name: t("bookmarks"),
    //   link: "/instellingen/bookmarks",
    //   icon: <IconsAll.BookmarksIcon />,
    // },
    {
      name: t("translations"),
      link: "/instellingen/vertalingen",
      icon: <IconsAll.VertalingenIcon />,
      divider: true,
    },
    // {
    //   name: t("report_a_bug"),
    //   link: "/instellingen/meld-een-bug",
    //   icon: <IconsAll.MeldeenBugIcon />,
    // },
    // {
    //   name: t("request") + " " + t("feature"),
    //   link: "/instellingen/request-feature",
    //   icon: <IconsAll.RequestFeatureIcon />,
    // },
  ];

  useEffect(() => {
    if (activeTab && activeTab?.currentTab) {
      navigate(activeTab?.redirectionLink);
    } else {
      dispatch(
        setActiveTab({
          page: path[1],
          tab: "Instellingen",
          redirectionLink: "/instellingen",
        }),
      );
    }
  }, [path[2], activeTab]);

  const renderChild = () => {
    switch (path[2]) {
      case "data-model":
        return <DataModal />;
      case "data-model-change-collection":
        return <ChangeDataModalCollection />;
      case "toegangscontrole":
        return <Toegangscontrole />;
      case "add-user":
        return <Permissions />;
      case "webhooks":
        return <Webhooks />;
      case "flows":
        return <Flows />;
      case "extenties":
        return <Extenties />;
      case "uiterlijk":
        return <Uiterlijk />;
      case "bookmarks":
        return <Bookmarks />;
      case "vertalingen":
        return path[3] ? <AddVertalingen /> : <Vertalingen />;
      case "meld-een-bug":
        return <MeldeenBug />;
      case "request-feature":
        return <RequestFeature />;
      default:
        return <DataModal />;
    }
  };

  return (
    <Flex className="main-content-wrap">
      {(isSubMenuActive || !isTabletOrSmaller) && (
        <SubMenu
          isSubMenuLogo
          isSubMenuVersion
          items={subMenus}
          currentPage={path[1]}
        />
      )}
      {renderChild()}
    </Flex>
  );
};

export default Gebruikers;
