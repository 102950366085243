import React, { useId, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Checkbox,
  Divider,
  Button,
  notification,
  Tag,
  TreeSelect,
} from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { themes } from "../../../config";
import { BsTags } from "react-icons/bs";
import { IoLanguage } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { convertToTreeData } from "../../../helpers/utils";

const FormStyling = styled(Form)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.colorGrayrBg};
  border-top: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  border-bottom: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  padding: 34px;
`;

const options = [];
for (let i = 10; i < 36; i++) {
  const value = i.toString(36) + i;
  options.push({
    label: `Long Label: ${value}`,
    value,
  });
}

const InvertedCheckBox = styled(Checkbox)`
  border-radius: 12px;
  border: 1px solid
    ${() =>
      themes[useSelector((state) => state.theme.name)].components.Input
        .colorBorder};
  background: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Input
      .colorBgContainer};
  padding: 1rem;
  width: 100%;
  font-weight: 400;
`;

const Tags = ({
  activeTab,
  addField,
  setIsDrawerOpen,
  dataSource,
  setActiveTab,
}) => {
  const { t } = useTranslation();
  const id = useId();

  const [form] = Form.useForm();
  const params = useParams();
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
    }
    setInputValue("");
  };
  const handleSubmit = async () => {
    let isError;
    try {
      const values = await form.validateFields();
      isError = dataSource?.fields?.some((f) => f?.field === values.name);
      if (isError) {
        notification.error({ message: "Please enter unique key" });
        return;
      }

      let field = {
        schema_id: params.field,
        field: values.name,
        type: values.type,
        path: selectedGroup?.value
          ? selectedGroup?.value + "." + values?.name
          : values?.name,
        field_type: selectedGroup ? "Object" : "Array",
        arrayType: "String",
        meta: {
          field: values.name,
          interface: activeTab,
          width: "full",
          sort: dataSource?.fields?.length + 1,
          nullable: true,
          options: {
            icon_left: values.icon_left,
            icon_right: values.icon_right,
            placeholder: values.placeholder,
            alphabetize: values.alphabetize,
            allow_other: values.allow_other,
            whitespace: values.whitespace,
            capitalization: values.capitalization,
          },
          hidden: false,
          required: !!values.required,
        },
        schema_definition: {
          name: values.name,
          type: values.type,
          // default: values.default?.split(",").map((item) => item.trim())
          default: tags,
        },
      };
      addField(field);
      await form.resetFields();
      setActiveTab(null);
    } catch (error) {
      console.log("error", error);
      notification.error({ message: "Something went wrong" });
      isError = true;
    } finally {
      !isError && setIsDrawerOpen(false);
    }
  };

  return (
    <FormStyling
      layout="vertical"
      className="mt-12"
      form={form}
      initialValues={{
        type: "String",
        whitespace: "Replace with hyphen",
        capitalization: "Convert Uppercase",
        icon_left: "Icon 1",
        icon_right: "Icon 1",
      }}
    >
      <Row gutter={28}>
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Key")}
            className="font-bold"
            htmlFor={`${id}-key`}
            name={`name`}
            rules={[
              {
                required: true,
                max: 50,
              },
            ]}
          >
            <Input
              placeholder={t("A unique common name")}
              onChange={(e) => {
                form.setFieldValue(
                  "name",
                  e.target.value
                    .replace(/[^a-zA-Z0-9_ ]/g, "")
                    .replace(/ /g, "_")
                    ?.toLowerCase(),
                );
              }}
              id={`${id}-key`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Group")}
            className="font-bold"
            htmlFor={`${id}-group`}
          >
            <TreeSelect
              id={`${id}-group`}
              showSearch
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="Select Group"
              allowClear
              treeDefaultExpandAll
              onSelect={(_, e) => setSelectedGroup(e)}
              treeData={convertToTreeData(
                dataSource?.fields?.filter((f) => {
                  return ["Object"]?.includes(f?.type);
                }),
                "tree-select",
              )}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("type")}
            htmlFor={`${id}-type`}
            className="font-bold"
            rules={[{ required: true }]}
            name={`type`}
          >
            <Select
              size="large"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-type`}
              disabled
              options={[
                {
                  value: "String",
                  label: "String",
                },
              ]}
            />
          </Form.Item>
        </Col>

        {/* <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Default Value")}
            htmlFor={`${id}-null`}
            name={`default`}
            className="font-bold"
          >
            <Input
              placeholder={t("NULL")}
              id={`${id}-null`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
            />
          </Form.Item>
        </Col> */}
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Placeholder")}
            className="font-bold"
            htmlFor={`${id}-placeholder`}
            name={`placeholder`}
          >
            <Input
              placeholder={t("Enter a Placeholder")}
              id={`${id}-placeholder`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
              suffix={<IoLanguage size={20} color="gray" />}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Required")}
            htmlFor={`${id}-requireValue`}
            className="font-bold"
            name={`required`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-requireValue`}>
              Require value to be set on creation
            </InvertedCheckBox>
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={24}>
          <Divider className="mt-3 mb-6" />
        </Col>

        <Col className="gutter-row" xs={24}>
          <Form.Item
            label={t("Default")}
            className="font-bold"
            htmlFor={`${id}-presets`}
            // name={`default`}
          >
            {/* <Input
              placeholder={t("Add a tag and press Enter...")}
              id={`${id}-presets`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
              suffix={<BsTags size={20} color="gray" />}
            /> */}
            <div>
              <Input
                type="text"
                placeholder={t("Add a tag and press Enter...")}
                id={`${id}-presets`}
                className="min-h-14 rounded-xl placeholder:font-normal px-6"
                suffix={<BsTags size={20} color="gray" />}
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                onPressEnter={handleInputConfirm}
              />
              <div style={{ marginTop: "16px" }}>
                {tags?.map((tag, index) => (
                  <Tag
                    color="blue"
                    key={index}
                    onClick={() =>
                      setTags((prev) => prev?.filter((t) => t !== tag))
                    }
                    style={{
                      display: "inline-block",
                      padding: "5px",
                      background: "#f3f3f3",
                      margin: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    {tag}
                  </Tag>
                ))}
              </div>
            </div>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Alphabetize")}
            htmlFor={`${id}-Alphabetize`}
            className="font-bold"
            name={`alphabetize`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-Alphabetize`}>
              Force Alphabetical Order
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Allow Other ")}
            htmlFor={`${id}-allowOther`}
            className="font-bold"
            name={`allow_other`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-allowOther`}>
              Allow Other Values
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Whitespace")}
            htmlFor={`${id}-whitespace`}
            className="font-bold"
            name={`whitespace`}
          >
            <Select
              size="large"
              placeholder="whitespace"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-whitespace`}
              options={[
                {
                  value: "Replace with hyphen",
                  label: "Replace with hyphen",
                },
                {
                  value: "Replace with underscore",
                  label: "Replace with underscore",
                },
                {
                  value: "Remove whitespace",
                  label: "Remove whitespace",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Capitalization")}
            htmlFor={`${id}-capitalization`}
            className="font-bold"
            name={`capitalization`}
          >
            <Select
              size="large"
              placeholder="Capitalization"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-capitalization`}
              options={[
                {
                  value: "Convert Lowercase",
                  label: "Convert Lowercase",
                },
                {
                  value: "Use Title Auto-Formatter",
                  label: "Use Title Auto-Formatter",
                },
                {
                  value: "Convert Uppercase",
                  label: "Convert Uppercase",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Icon Left")}
            htmlFor={`${id}-iconLeft`}
            className="font-bold"
            name={`icon_left`}
          >
            <Select
              size="large"
              placeholder="Icon 1"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-iconLeft`}
              options={[
                {
                  value: "Icon 1",
                  label: "Icon 1",
                },
                {
                  value: "Icon 2",
                  label: "Icon 2",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Icon Right")}
            htmlFor={`${id}-iconRight`}
            className="font-bold"
            name={`icon_right`}
          >
            <Select
              size="large"
              placeholder="Icon 1"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-iconRight`}
              options={[
                {
                  value: "Icon 1",
                  label: "Icon 1",
                },
                {
                  value: "Icon 2",
                  label: "Icon 2",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24}>
          <Button
            onClick={handleSubmit}
            className="rounded-3xl bg-btn-dark text-white px-5 py-3 mt-4 font-medium h-auto w-full"
          >
            Save
          </Button>
          <Button type="link" className="mt-4 font-medium h-auto w-full">
            Continue in Advanced Field Creation Mode
          </Button>
        </Col>
      </Row>
    </FormStyling>
  );
};

export default Tags;
