import React, { useEffect } from "react";
import { Badge, Button, Card } from "antd";
import { BsInfoCircle } from "react-icons/bs";
import { RxClipboardCopy } from "react-icons/rx";
import styled from "styled-components";
import { useTheme } from "../Common/ThemeContext";
import { IoTriangleOutline } from "react-icons/io5";
import { HiOutlineChatBubbleOvalLeft } from "react-icons/hi2";
import { LuShare2 } from "react-icons/lu";
import { useSelector } from "react-redux";
import { themes } from "../config";

export const OtherAppInfo = styled(Card)`
  width: min(100%, 64px);
  min-height: calc(100vh - 22px);
  background: ${() =>
    themes[useSelector((state) => state.theme.name)].token.themeLayoutBg};
  border: 0;
  border-radius: 0;

  .ant-card-body {
    height: 100vh;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].headerLogoBg};
    position: fixed;
    right: 0;
    top: 0;

    &::after,
    &::before {
      display: none;
    }

    button,
    a,
    div {
      width: 64px;
      min-height: 64px;
      border: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].token.toolbarButton};
      svg {
        color: ${() =>
          themes[useSelector((state) => state.theme.name)].token
            .toolbarButtonIconColor};
      }
      &:is(:hover, :focus, :active) {
        background: ${() =>
          themes[useSelector((state) => state.theme.name)].token
            .toolbarButtonHover} !important;
        svg {
          color: ${() =>
            themes[useSelector((state) => state.theme.name)].token
              .toolbarButtonIconColor};
        }
      }
    }
  }
`;

const AppInfo = () => {
  const { theme } = useTheme();

  useEffect(() => {
    if (theme === "dark") {
      document.querySelector("html")?.classList?.add("dark");
    } else {
      document.querySelector("html")?.classList?.remove("dark");
    }
  }, [theme]);

  return (
    <OtherAppInfo className="hidden xl:block">
      <Button
        disabled
        className="rounded-none"
        icon={<BsInfoCircle size={24} />}
      ></Button>

      <Button
        disabled
        className="rounded-none"
        icon={
          <Badge
            count={5}
            status="success"
            style={{
              color: "#0B192B",
              backgroundColor: "#80C6A8",
              fontWeight: "500",
            }}
          >
            <IoTriangleOutline size={24} />
          </Badge>
        }
      ></Button>
      <Button
        disabled
        className="rounded-none"
        icon={<HiOutlineChatBubbleOvalLeft size={24} />}
      ></Button>
      <Button
        disabled
        className="rounded-none"
        icon={<LuShare2 size={24} />}
      ></Button>
      <Button
        disabled
        className="rounded-none mt-auto"
        icon={<RxClipboardCopy size={24} />}
      ></Button>
    </OtherAppInfo>
  );
};

export default AppInfo;
