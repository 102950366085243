import { useQuery } from "@tanstack/react-query";
import { store } from "../Redux/store";
import Apis from "./Apis";
import axiosInstance from "./axios";

const useGroupedTranslations = (lng) => {
  const fetchGroupedTranslations = async () => {
    const { data } = await axiosInstance.get(
      Apis.GET_TRANSLATIONS_BY_GROUP(lng),
      {
        headers: {
          "x-tenant-id": store.getState().user.userId,
        },
      },
    );
    return data?.payload?.data[0] || {};
  };

  const { data } = useQuery({
    queryKey: ["groupedTranslations", lng],
    queryFn: fetchGroupedTranslations,
    enabled: !!lng,
    refetchOnWindowFocus: false,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
  });
  return data || {};
};

export default useGroupedTranslations;
