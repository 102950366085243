import React, { useId, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Checkbox,
  Divider,
  Button,
  notification,
  InputNumber,
  TreeSelect,
} from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { themes } from "../../../config";
import { IoLanguage } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { convertToTreeData } from "../../../helpers/utils";

const FormStyling = styled(Form)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.colorGrayrBg};
  border-top: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  border-bottom: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  padding: 34px;
`;

const InvertedCheckBox = styled(Checkbox)`
  border-radius: 12px;
  border: 1px solid
    ${() =>
      themes[useSelector((state) => state.theme.name)].components.Input
        .colorBorder};
  background: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Input
      .colorBgContainer};
  padding: 1rem;
  width: 100%;
  font-weight: 400;
`;

const InputFormField = ({
  activeTab,
  addField,
  setIsDrawerOpen,
  dataSource,
  setActiveTab,
}) => {
  const { t } = useTranslation();
  const id = useId();
  const [form] = Form.useForm();
  const params = useParams();
  const [selectedField, setSelectedField] = useState("String");
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleSubmit = async () => {
    let isError;
    try {
      const values = await form.validateFields();
      isError = dataSource?.fields?.some((f) => f?.field === values.name);
      if (isError) {
        notification.error({ message: "Please enter unique key" });
        return;
      }

      let field = {
        schema_id: params.field,
        field: values.name,
        type: values.type,
        path: selectedGroup?.value
          ? selectedGroup?.value + "." + values?.name
          : values?.name,
        field_type: selectedGroup ? "Object" : "Single",
        meta: {
          field: values.name,
          interface: activeTab,
          width: "full",
          sort: dataSource?.fields?.length + 1,
          nullable: true,
          options: {
            icon_left: values.icon_left,
            icon_right: values.icon_right,
            placeholder: values.placeholder,
          },
          readonly: !!values.readonly,
          hidden: false,
          required: !!values.required,
        },
        schema_definition: {
          name: values.name,
          type: values.type,
          default: values.default,
        },
      };
      addField(field);
      await form.resetFields();
      setActiveTab(null);
    } catch (error) {
      console.log("error", error);
      notification.error({ message: "Something went wrong" });
      isError = true;
    } finally {
      !isError && setIsDrawerOpen(false);
    }
  };

  return (
    <FormStyling
      form={form}
      layout="vertical"
      className="mt-12"
      initialValues={{
        type: "String",
        icon_left: "Icon 1",
        icon_right: "Icon 1",
      }}
    >
      <Row gutter={28}>
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Key")}
            className="font-bold"
            name={`name`}
            htmlFor={`${id}-key`}
            rules={[
              {
                required: true,
                max: 50,
              },
            ]}
          >
            <Input
              placeholder={t("A unique common name")}
              onChange={(e) => {
                form.setFieldValue(
                  "name",
                  e.target.value
                    .replace(/[^a-zA-Z0-9_ ]/g, "")
                    .replace(/ /g, "_")
                    ?.toLowerCase(),
                );
              }}
              id={`${id}-key`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Group")}
            className="font-bold"
            htmlFor={`${id}-group`}
          >
            <TreeSelect
              id={`${id}-group`}
              showSearch
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="Select Group"
              allowClear
              treeDefaultExpandAll
              onSelect={(_, e) => setSelectedGroup(e)}
              treeData={convertToTreeData(
                dataSource?.fields?.filter((f) => {
                  return ["Object"]?.includes(f?.type);
                }),
                "tree-select",
              )}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Type")}
            htmlFor={`${id}-type`}
            name={`type`}
            className="font-bold"
            rules={[{ required: true }]}
          >
            <Select
              size="large"
              placeholder="String"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-type`}
              onChange={(e) => setSelectedField(e)}
              options={[
                { value: "String", label: "String" },
                { value: "ObjectId", label: "ObjectId" },
                { value: "Number", label: "Number" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Default Value")}
            htmlFor={`${id}-null`}
            className="font-bold"
            name={`default`}
          >
            {selectedField === "Number" ? (
              <InputNumber
                id={`${id}-null`}
                className="min-h-14 flex items-center rounded-lg placeholder:font-normal px-3 w-full leading-10 overflow-hidden"
              />
            ) : (
              <Input
                placeholder={t("NULL")}
                id={`${id}-null`}
                className="min-h-14 rounded-xl placeholder:font-normal px-6"
              />
            )}
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Required")}
            htmlFor={`${id}-requireValue`}
            className="font-bold"
            name={`required`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-requireValue`}>
              Require value to be set on creation
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24}>
          <Divider className="mt-3 mb-6" />
        </Col>
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Placeholder")}
            htmlFor={`${id}-placeholder`}
            className="font-bold"
            name={`placeholder`}
          >
            <Input
              placeholder={t("Enter a placeholder...")}
              id={`${id}-placeholder`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
              suffix={<IoLanguage size={20} color="gray" />}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("readonly")}
            htmlFor={`${id}-readonly`}
            className="font-bold"
            name={`readonly`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-readonly`}>Readonly</InvertedCheckBox>
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Icon Left")}
            htmlFor={`${id}-iconLeft`}
            className="font-bold"
            name={`icon_left`}
          >
            <Select
              size="large"
              placeholder="Icon 1"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-iconLeft`}
              options={[
                {
                  value: "Icon 1",
                  label: "Icon 1",
                },
                {
                  value: "Icon 2",
                  label: "Icon 2",
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Icon Right")}
            htmlFor={`${id}-iconright`}
            className="font-bold"
            name={`icon_right`}
          >
            <Select
              size="large"
              placeholder="Icon 1"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-iconright`}
              options={[
                {
                  value: "Icon 1",
                  label: "Icon 1",
                },
                {
                  value: "Icon 2",
                  label: "Icon 2",
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={24}>
          <Button
            onClick={handleSubmit}
            className="rounded-3xl bg-btn-dark text-white px-5 py-3 mt-4 font-medium h-auto w-full"
          >
            Save
          </Button>
          <Button type="link" className="mt-4 font-medium h-auto w-full">
            Continue in Advanced Field Creation Mode
          </Button>
        </Col>
      </Row>
    </FormStyling>
  );
};

export default InputFormField;
