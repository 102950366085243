import React, { useId } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Checkbox,
  Divider,
  Button,
  InputNumber,
  notification,
} from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { themes } from "../../../config";
import { FaRegPlusSquare } from "react-icons/fa";
import { useParams } from "react-router-dom";

const FormStyling = styled(Form)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.colorGrayrBg};
  border-top: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  border-bottom: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  padding: 34px;
`;

// const NotHasField = styled(Flex)`
//   background-color: ${() =>
//     themes[useSelector((state) => state.theme.name)].token.NotHasFieldClr};
//   border-left: 6px solid
//     ${() => themes[useSelector((state) => state.theme.name)].token.brandColor};
//   padding-inline: 15px;
//   border-radius: 6px;
//   min-height: 56px;
// `;

// const DrawerStyle = styled(Drawer)`
//   .ant-drawer-header {
//     padding: 0;
//     border: unset !important;
//     .ant-drawer-header-title {
//       .ant-drawer-close {
//         width: 56px;
//         height: 56px;
//         position: absolute;
//         left: -65px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         top: 30px;
//         background: white;
//         border-radius: 100vh;
//         box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
//         @media screen and (max-width: 991px) {
//           left: calc(50% - 28px);
//           bottom: 1rem;
//           top: auto;
//         }
//       }
//     }
//   }
//   .ant-drawer-body {
//     padding: 0;
//     .ant-tabs-nav {
//       padding-top: 40px;
//       width: min(100%, 200px);
//       min-height: 100vh;
//       background-color: #f5f6f7;
//       .ant-tabs-nav-wrap {
//         &:before,
//         &:after,
//         .ant-tabs-ink-bar {
//           display: none;
//         }
//         .ant-tabs-nav-list {
//           padding-inline: 10px;
//           .ant-tabs-tab {
//             border-radius: 8px;
//             margin-top: 5px;
//             > div {
//               color: #000 !important;
//               font-size: 16px;
//               font-style: normal;
//             }
//             &.ant-tabs-tab-active {
//               background-color: #ebeced;
//             }
//           }
//         }
//       }
//     }

//     .ant-tabs-content-holder {
//       padding-top: 40px;
//       .ant-tabs-tabpane {
//         padding-right: 24px;
//       }
//     }
//   }
// `;

const InvertedCheckBox = styled(Checkbox)`
  border-radius: 12px;
  border: 1px solid
    ${() =>
      themes[useSelector((state) => state.theme.name)].components.Input
        .colorBorder};
  background: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Input
      .colorBgContainer};
  padding: 1rem;
  width: 100%;
  font-weight: 400;
`;

const Files = ({
  activeTab,
  addField,
  setIsDrawerOpen,
  dataSource,
  setActiveTab,
}) => {
  const { t } = useTranslation();
  const id = useId();
  const [form] = Form.useForm();
  const params = useParams();

  const handleSubmit = async () => {
    let isError;
    try {
      const values = await form.validateFields();
      isError = dataSource?.fields?.some((f) => f?.field === values.name);
      if (isError) {
        notification.error({ message: "Please enter unique key" });
        return;
      }

      let field = {
        schema_id: params.field,
        field: values.name,
        type: values.type,
        path: values?.group ? values?.group + "." + values?.name : values?.name,
        field_type: "Single",
        meta: {
          field: values.name,
          interface: activeTab,
          width: "full",
          sort: dataSource?.fields?.length + 1,
          nullable: true,
          options: {
            root_folder: values.root_folder,
            creating_items: values.creating_items,
            selecting_items: values.selecting_items,
            display_template: values.display_template,
            per_page: values.per_page,
          },
          hidden: false,
          required: !!values.required,
        },
        schema_definition: {
          name: values.name,
          type: values.type,
          default: values.default,
        },
      };
      addField(field);
      await form.resetFields();
      setActiveTab(null);
    } catch (error) {
      console.log("error", error);
      notification.error({ message: "Something went wrong" });
      isError = true;
    } finally {
      !isError && setIsDrawerOpen(false);
    }
  };

  return (
    <FormStyling
      layout="vertical"
      className="mt-12"
      form={form}
      initialValues={{
        type: "ObjectId",
        root_folder: "File library root",
        creating_items: true,
        selecting_items: true,
        per_page: 15,
      }}
    >
      <Row gutter={28}>
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Key")}
            className="font-bold"
            htmlFor={`${id}-key`}
            name={`name`}
            rules={[
              {
                required: true,
                max: 50,
              },
            ]}
          >
            <Input
              placeholder={t("A unique common name")}
              onChange={(e) => {
                form.setFieldValue(
                  "name",
                  e.target.value
                    .replace(/[^a-zA-Z0-9_ ]/g, "")
                    .replace(/ /g, "_")
                    ?.toLowerCase(),
                );
              }}
              id={`${id}-key`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("type")}
            htmlFor={`${id}-type`}
            className="font-bold"
            rules={[{ required: true }]}
            name={`type`}
          >
            <Select
              size="large"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-type`}
              disabled={true}
              options={[
                {
                  value: "ObjectId",
                  label: "ObjectId",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Default Value")}
            htmlFor={`${id}-null`}
            className="font-bold"
            name={`default`}
          >
            <Input
              placeholder={t("NULL")}
              id={`${id}-null`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Required")}
            htmlFor={`${id}-requireValue`}
            className="font-bold"
            name={`required`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-requireValue`} defaultChecked={false}>
              Require value to be set on creation
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24}>
          <Divider className="mt-3 mb-6" />
        </Col>

        <Col className="gutter-row" xs={24} md={24}>
          <Form.Item
            label={t("Root Folder")}
            htmlFor={`${id}-root-folder`}
            className="font-bold"
            name={`root_folder`}
            help={
              <p className="font-normal mt-2 opacity-40">
                Default folder for uploaded files without a folder specified.
                Does not affect existing files.
              </p>
            }
          >
            <Select
              showSearch
              size="large"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-root-folder`}
              options={[
                {
                  value: "File library root",
                  label: "File library root",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={24}>
          <Form.Item
            label={t("Display Template")}
            className="font-bold"
            htmlFor={`${id}-display-template`}
            name={`display_template`}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              id={`${id}-display-template`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
              suffix={<FaRegPlusSquare size={24} className="opacity-40" />}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Creating Items ")}
            htmlFor={`${id}-create-item`}
            className="font-bold"
            name={`creating_items`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-create-item`}>
              Enable Create Button
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Selecting Items ")}
            htmlFor={`${id}-select-value`}
            className="font-bold"
            name={`selecting_items`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-select-value`}>
              Enable Select Button
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Per Page")}
            htmlFor={`${id}-per-page`}
            className="font-bold"
            name={`per_page`}
          >
            <InputNumber
              placeholder={"Items Shown"}
              id={`${id}-per-page`}
              min={1}
              max={10}
              className="min-h-14 flex items-center rounded-xl placeholder:font-normal px-3 w-full leading-10 overflow-hidden"
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24}>
          <Button
            onClick={handleSubmit}
            className="rounded-3xl bg-btn-dark text-white px-5 py-3 mt-4 font-medium h-auto w-full"
          >
            Save
          </Button>
          <Button type="link" className="mt-4 font-medium h-auto w-full">
            Continue in Advanced Field Creation Mode
          </Button>
        </Col>
      </Row>
    </FormStyling>
  );
};

export default Files;
