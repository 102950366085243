import React, { useContext } from "react";
import { Flex } from "antd";
import IconsAll from "../../Components/IconsAll";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import Backdrop from "../../Layouts/Backdrop";
import { SidebarContext } from "../../Context/submenuContext";
import { tabletBreakpoint } from "../../helpers/utils";
import useScreenWidth from "../../Common/Hooks/ScreenWidth";
import { useTranslation } from "react-i18next";

const RequestFeature = () => {
  const submenu = useContext(SidebarContext);
  const screenWidth = useScreenWidth();
  const isTabletOrSmaller = screenWidth <= tabletBreakpoint;
  const isSubMenuActive = submenu?.activatedItem === "request-feature";
  const { t } = useTranslation();

  return (
    <Flex className="w-full">
      {isSubMenuActive && isTabletOrSmaller && (
        <Backdrop onClick={() => submenu.toggleActivation()} />
      )}
      <div className="px-4 xl:px-16 w-full">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle={t("request") + " " + t("feature")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.RequestFeatureIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart isFilterShow={true} addButton={true} />
        </div>

        <section className="mt-9"></section>
      </div>
    </Flex>
  );
};

export default RequestFeature;
