import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Flex, Modal, Table } from "antd";
import IconsAll from "../../Components/IconsAll";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import Backdrop from "../../Layouts/Backdrop";
import { SidebarContext } from "../../Context/submenuContext";
import { CustomEmpty, tabletBreakpoint } from "../../helpers/utils";
import useScreenWidth from "../../Common/Hooks/ScreenWidth";
import { useTranslation } from "react-i18next";
import AppInfo from "../../Components/AppInfo";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { themes } from "../../config";
import useDebouncedEffect from "../../helpers/useDebounceEffect";
import axiosInstance from "../../helpers/axios";
import Apis from "../../helpers/Apis";
import withRouter from "../../Common/withRouter";
import { store } from "../../Redux/store";
import { queryClient } from "../..";

const TableStriped = styled(Table)`
.ant-table {
background-color: ${() =>
  themes[useSelector((state) => state.theme.name)].components.Table.tableBg};
&.table{
  background-color:transparent;
}
.gray-clr {
  color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.grayClr};
}

thead .ant-table-cell,
.ant-table-cell.ant-table-cell-fix-left{
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Table
      .sortedBg} !important;
    }
}
.ant-table-cell {
  border-bottom: 1px solid
      ${() =>
        themes[useSelector((state) => state.theme.name)].components.Table
          .borderColor} !important;
  &.ant-table-column-has-sorters {
    &:hover {
      background-color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Table
          .sortedBg} !important;
    }
  }
  &.ant-table-column-sort {
    background-color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Table
        .sortedBg};
  }
}

.imgLocationClr {
  color: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Table
      .imgLocationClr};
  margin-left: 10px;
}

}
  thead>tr:first-child >*:last-child {
    padding-right: 60px;
  }
    
`;

const Vertalingen = ({ router }) => {
  const submenu = useContext(SidebarContext);
  const screenWidth = useScreenWidth();
  const isTabletOrSmaller = screenWidth <= tabletBreakpoint;
  const isSubMenuActive = submenu?.activatedItem === "vertalingen";
  const { t } = useTranslation();
  const currentLng = useSelector((state) => state.user.userProfile.language);
  const path = location.pathname.split("/");
  const activeTab = useSelector((state) => state.activeTab[path[1]]);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [translationsList, setTranslationsList] = useState([]);
  const [columnWidths, setColumnWidths] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  useDebouncedEffect(
    () => {
      init();
    },
    [searchTerm, path[2]],
    1000,
  );

  useEffect(() => {
    setSearchTerm("");
  }, [activeTab]);

  const handleResizeStart = (key) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const startX = e.clientX;
    const startWidth = columnWidths[key] || 150;

    const handleMouseMove = (moveEvent) => {
      setColumnWidths((prev) => ({
        ...prev,
        [key]: Math.max(startWidth + (moveEvent.clientX - startX), 50),
      }));
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleDeleteClick = async () => {
    setOpen(false);
    try {
      const resp = await axiosInstance.put(
        Apis.DELETE_TRANSLATIONS(path[2]),
        {
          deleteTranslations: selectedRows,
        },
        {
          headers: {
            "x-tenant-id": store.getState().user.userId,
          },
        },
      );
      if (resp.status === 200) {
        queryClient.invalidateQueries({
          queryKey: ["groupedTranslations", currentLng],
          exact: true,
        });
        setSelectedRows([]);
        init();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const init = async () => {
    try {
      const resp = await axiosInstance.get(
        Apis.GET_TRANSLATIONS(searchTerm, false),
        {
          headers: {
            "x-tenant-id": store.getState().user.userId,
          },
        },
      );
      if (resp.status === 200) setTranslationsList(resp.data.payload.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleRowSelect = (checked, record) => {
    setSelectedRows((prevSelectedRows) => {
      if (checked) {
        return [...prevSelectedRows, record._id];
      } else {
        return prevSelectedRows.filter((id) => id !== record._id);
      }
    });
  };

  const columns = [
    {
      title: (
        <div
          className="resizable-header"
          style={{ width: columnWidths["select"] }}
        >
          Select
          <div
            className="resize-handle"
            onMouseDown={handleResizeStart("select")}
          />
        </div>
      ),
      dataIndex: "select",
      key: "select",
      width: columnWidths["select"],
      render: (text, record) => (
        <Checkbox
          onChange={(e) => handleRowSelect(e.target.checked, record)}
          checked={selectedRows?.includes(record?._id)}
        />
      ),
    },
    ...[
      { key: "key", label: "Key" },
      { key: "language", label: "Language" },
      { key: "value", label: "Value" },
      { key: "_id", label: "_id" },
    ].map(({ key, label }) => ({
      title: (
        <div className="resizable-header" style={{ width: columnWidths[key] }}>
          {label}
          <div className="resize-handle" onMouseDown={handleResizeStart(key)} />
        </div>
      ),
      dataIndex: key,
      key,
      width: columnWidths[key],
      render: (record, rowData) => (
        <div
          onClick={() =>
            router.navigate(`/instellingen/vertalingen/${rowData?._id}`)
          }
        >
          {record}
        </div>
      ),
    })),
  ];

  return (
    <Flex className="w-full main-content-wrap">
      {isSubMenuActive && isTabletOrSmaller && (
        <Backdrop onClick={() => submenu.toggleActivation()} />
      )}
      <div className="px-4 xl:px-16 w-full left-part">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle={t("Translations")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.PaginasIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart
            isFilterShow={true}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            addButton={true}
            addDisabled={!path[2]}
            deleteButton
            deleteDisabled={!selectedRows?.length}
            handleDeleteClick={() => setOpen(true)}
            handleAddClick={() => {
              router.navigate(`/instellingen/vertalingen/add`);
            }}
          />
        </div>

        <section className="container mt-9">
          <section className="mt-9">
            <div className="relative">
              <TableStriped
                columns={columns}
                dataSource={translationsList}
                rowKey={(record) => record?._id}
                locale={{ emptyText: <CustomEmpty /> }}
              />
            </div>
          </section>
        </section>
      </div>
      <Modal
        open={open}
        title="Delete Content"
        okText="Delete Content"
        onOk={handleDeleteClick}
        onCancel={() => setOpen(false)}
        className="modal-delete"
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <p className="my-6">
          Are you sure you want to delete selected translations permanently?
        </p>
      </Modal>
      <AppInfo />
    </Flex>
  );
};

export default withRouter(Vertalingen);
