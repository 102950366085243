import React, { useContext, useEffect, useState } from "react";
import { Col, Flex } from "antd";
import SubMenu from "../../Components/SubMenu";
import { SidebarContext } from "../../Context/submenuContext";
import Backdrop from "../../Layouts/Backdrop";
import { tabletBreakpoint } from "../../helpers/utils";
import useScreenWidth from "../../Common/Hooks/ScreenWidth";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axiosInstance from "../../helpers/axios";
import Apis from "../../helpers/Apis";
import { store } from "../../Redux/store";
import withRouter from "../../Common/withRouter";
import ContentTable from "./ContentTable";
import AddContent from "./AddContent";
import AppInfo from "../../Components/AppInfo";

const Content = () => {
  const path = location.pathname.split("/");
  const submenu = useContext(SidebarContext);
  const screenWidth = useScreenWidth();
  const isTabletOrSmaller = screenWidth <= tabletBreakpoint;
  const isSubMenuActive = submenu?.activatedItem === "Content";
  const [tablesList, setTablesList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const activeTab = useSelector((state) => state.activeTab[path[1]]);
  const navigate = useNavigate();

  const init = async () => {
    const resp1 = await axiosInstance.get(Apis.GET_ALL_SCHEMAS({}), {
      headers: {
        "x-tenant-id": store.getState().user.userId,
      },
    });
    if (resp1.status === 200) setTablesList(resp1.data.payload.data);

    if (activeTab && activeTab?.currentTab) {
      navigate(activeTab?.redirectionLink);
    }
  };

  useEffect(() => {
    init();
  }, [path[2]]);

  return (
    <Flex className="w-full">
      {(isSubMenuActive || !isTabletOrSmaller) && (
        <SubMenu
          isSubMenuLogo
          isSubMenuVersion
          items={tablesList
            ?.filter((item) => item?.visible)
            ?.map((t) => {
              return {
                ...t,
                link: "/content/" + t._id,
                name: t.collection_id,
              };
            })}
          currentPage={path[1]}
        />
      )}
      {isSubMenuActive && isTabletOrSmaller && (
        <Backdrop onClick={() => submenu.toggleActivation()} />
      )}
      {path.includes("add") || path.includes("edit") ? (
        <AddContent />
      ) : (
        <ContentTable
          tableType={"content"}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      )}
      <Col className="right-part">
        <AppInfo />
      </Col>
    </Flex>
  );
};

export default withRouter(Content);
