import { Button, Divider, Tree } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./tree.module.css";
import isEqual from "lodash/isEqual";
import classNames from "classnames";

const CustomTree = (props) => {
  let {
    treeData = [],
    defaultCheckedKeys = [],
    onSave,
    title = "",
    buttonLabel = "Save",
    id = "",
    maxSelection = 0,
  } = props;
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const getLeafNodeKeys = (nodes) => {
    const tempChecks = [];
    nodes.map((cn) => {
      if (cn.children) {
        getLeafNodeKeys(cn.children);
      } else if (cn.isLeaf) {
        tempChecks.push(cn.key);
      }
    });

    return tempChecks;
  };

  const onCheck = (_, e) => {
    const leafNodes = getLeafNodeKeys(e.checkedNodes);
    if (!maxSelection) {
      setCheckedKeys(leafNodes);
      const checkChanges = isEqual(leafNodes, defaultCheckedKeys);
      setButtonDisabled(checkChanges);
      // eslint-disable-next-line no-dupe-else-if
    } else if (Boolean(maxSelection) && leafNodes.length <= maxSelection) {
      setCheckedKeys(leafNodes);
      const checkChanges = isEqual(leafNodes, defaultCheckedKeys);
      setButtonDisabled(checkChanges);
    }
  };

  const handleSave = async (e) => {
    const checkStatus = onSave(checkedKeys, id, e);
    setButtonDisabled(checkStatus);
  };

  useEffect(() => {
    setCheckedKeys(defaultCheckedKeys);
    setExpandedKeys(defaultCheckedKeys);
  }, [defaultCheckedKeys]);

  return (
    <div className={styles.tree_container}>
      <div
        className={
          maxSelection
            ? styles.tree_header_container
            : classNames(
                styles.tree_header_container,
                styles.tree_margin_bottom,
              )
        }
      >
        <span>
          <h3>{title}</h3>
          <span className={!maxSelection ? styles.header_note_text_span : ""}>
            {maxSelection
              ? `(You can choose up to ${maxSelection} item${maxSelection > 1 ? "s" : ""} to add.)`
              : ""}
          </span>
        </span>
        <Button disabled={buttonDisabled} type="primary" onClick={handleSave}>
          {buttonLabel}
        </Button>
      </div>
      <Divider />
      <Tree
        checkable
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onCheck={onCheck}
        checkedKeys={checkedKeys}
        treeData={treeData}
      />
    </div>
  );
};

export default CustomTree;
